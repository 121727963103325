import {useQuery} from '@tanstack/react-query';
import {get} from '../utils'
import {QUERY_KEYS} from "../../config/constants";

async function getInboxFilters({queryKey}) {
  // eslint-disable-next-line no-unused-vars
  const [_key, {module, checkClientModules, clientModules, skip}] = queryKey;
  if (skip || !module || (checkClientModules && !clientModules.includes(module))) return;
  return await get(`/api/module/${module}/filters`);
}

export default function useInboxFilters(module, checkClientModules = false, clientModules = [], skip = false) {
  return useQuery([`${module}:${QUERY_KEYS.FILTERS}`, {module, checkClientModules, clientModules, skip}], getInboxFilters)
}