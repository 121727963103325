import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router'
import { Grid, Divider, Box, TextField, Typography, Tooltip } from '@material-ui/core'
import { Autocomplete, Skeleton } from '@material-ui/lab'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import moment from 'moment'

import NorFound from '../not-found/view'

import useVacancyRequest from '../../../api/queries/useVacancyRequest'

import { IconCancel, IconDoneAll, BackButton, InputField, SpinnerIcon, IconGroupAdd } from '../../atoms'
import { TemplateRequestTableManager } from '../../organism'
import { SingleDate } from '../../molecules'

import * as api from '../../../api/index'
import i18n from '../../../assets/i18n'
import queryClient from '../../../api/queryClient'

import { TemplateRequestManagerStyled } from './styled'
import styles from '../not-found/styles'
import { useRequirements } from '../../../api/queries/useRequirements'
import { useAppContext } from '../../../context/appContext'
import { ALERT_LEVEL, QUERY_KEYS, STATES_TEMPLATE_REQUEST, USER_AUTHORIZED_SECTION_KEYS } from '../../../config/constants'

const VACANCY_STATES = {
  CANCELLED: 'cancelled',
  REJECTED: 'rejected',
  ACTIVE: 'active',
  CLOSED: 'closed',
  PENDING: 'pending',
  EMPTY: 'empty',
  INACTIVE: 'inactive'
}

const closedProcess = [
  VACANCY_STATES.CANCELLED,
  VACANCY_STATES.REJECTED,
  VACANCY_STATES.CLOSED,
  VACANCY_STATES.INACTIVE,
]

const ProcessShowTemplateRequest = ({ user, client }) => {
  const { createAlert, isEnabledGuardSection } = useAppContext()

  const useStyles = makeStyles(styles)
  const history = useHistory()

  const theme = useTheme()
  const classes = useStyles(theme)

  const { vacancyRequestId } = useParams()

  const { data: vacancies, isFetched, isError, isLoading, isFetching } = useVacancyRequest(vacancyRequestId)
  const { data: requirementsData, isLoading: requirementsLoading } = useRequirements(
    vacancies?.vacancy_request?.candidature_settings?.id,
    vacancies?.vacancy_request?.candidature_settings !== ''
  )

  const [defaultValue, setDefaultValue] = React.useState({})
  const [requirementConstraints, setRequirementConstraints] = React.useState([])
  const [requirementScoringFields, setRequirementScoringFields] = React.useState([])

  let statusOptions = [
    { id: VACANCY_STATES.CANCELLED, value: i18n.templateRequestManager.vacancyStates[VACANCY_STATES.CANCELLED] },
    { id: VACANCY_STATES.REJECTED, value: i18n.templateRequestManager.vacancyStates[VACANCY_STATES.REJECTED] },
    { id: VACANCY_STATES.ACTIVE, value: i18n.templateRequestManager.vacancyStates[VACANCY_STATES.ACTIVE] },
    { id: VACANCY_STATES.CLOSED, value: i18n.templateRequestManager.vacancyStates[VACANCY_STATES.CLOSED] },
    { id: VACANCY_STATES.PENDING, value: i18n.templateRequestManager.vacancyStates[VACANCY_STATES.PENDING] },
    { id: VACANCY_STATES.EMPTY, value: i18n.templateRequestManager.vacancyStates[VACANCY_STATES.EMPTY] },
    { id: VACANCY_STATES.INACTIVE, value: i18n.templateRequestManager.vacancyStates[VACANCY_STATES.INACTIVE] }
  ]
  
  let typeContractOptions = [
    { id: 1, name: i18n.templateRequestManager.typeContractIndefinite },
    { id: 2, name: i18n.templateRequestManager.typeContractTemporary },
    { id: 3, name: i18n.templateRequestManager.typeContractInterim }
  ]

  const WORKING_DAY = {
    FULL_TIME: 'full_time',
    PART_TIME: 'part_time',
    WEEKEND: 'weekend',
  }

  let workingDayOptions = [
    { id: WORKING_DAY.FULL_TIME, name: i18n.templateRequestManager.workingDay[WORKING_DAY.FULL_TIME] },
    { id: WORKING_DAY.PART_TIME, name: i18n.templateRequestManager.workingDay[WORKING_DAY.PART_TIME] },
    { id: WORKING_DAY.WEEKEND, name: i18n.templateRequestManager.workingDay[WORKING_DAY.WEEKEND] },
  ]

  const WORK_SHIFT = {
    MORNING: 'morning_shift',
    NIGHT: 'night_shift',
    SWING: 'swing_shift',
    ROTATING: 'rotating_shift',
    SPLIT: 'split_shift',
    FULL: 'full_shift',
  }

  let workShiftOptions = [
    { id: WORK_SHIFT.MORNING, name: i18n.templateRequestManager.workShift[WORK_SHIFT.MORNING] },
    { id: WORK_SHIFT.SWING, name: i18n.templateRequestManager.workShift[WORK_SHIFT.SWING] },
    { id: WORK_SHIFT.NIGHT, name: i18n.templateRequestManager.workShift[WORK_SHIFT.NIGHT] },
    { id: WORK_SHIFT.ROTATING, name: i18n.templateRequestManager.workShift[WORK_SHIFT.ROTATING] },
    { id: WORK_SHIFT.SPLIT, name: i18n.templateRequestManager.workShift[WORK_SHIFT.SPLIT] },
    { id: WORK_SHIFT.FULL, name: i18n.templateRequestManager.workShift[WORK_SHIFT.FULL] },
  ]

  let contractHoursOptions = [
    { id: '40', name: i18n.templateRequestManager.contractHours['40'] },
    { id: '37', name: i18n.templateRequestManager.contractHours['37'] },
    { id: '35', name: i18n.templateRequestManager.contractHours['35'] },
    { id: '30', name: i18n.templateRequestManager.contractHours['30'] },
    { id: '20', name: i18n.templateRequestManager.contractHours['20'] },
    { id: '16', name: i18n.templateRequestManager.contractHours['16'] },
    { id: '15', name: i18n.templateRequestManager.contractHours['15'] },
    { id: '12', name: i18n.templateRequestManager.contractHours['12'] },
    { id: '10', name: i18n.templateRequestManager.contractHours['10'] },
    { id: '8', name: i18n.templateRequestManager.contractHours['8'] },
  ]

  const filtrarOptionsRequirements = obj => {
    let resultado = []
    for (let propiedad in obj) {
      if (typeof obj[propiedad] === 'string' && propiedad !== 'unidad' && propiedad !== 'candidatura_id') {
        resultado.push({ id: propiedad, name: obj[propiedad] })
      }
    }
    return resultado
  }

  const [duplicityChangeStateEventPrevent, setDuplicityChangeStateEventPrevent] = React.useState(false)

  const handleChangeState = async state => {
    setDuplicityChangeStateEventPrevent(true)
    await api
      .updateNewStatesSelectionCandidate(state, vacancyRequestId)
      .then(() => {
        createAlert(i18n.phases_states.updatedStatus, ALERT_LEVEL.SUCCESS)
        queryClient.invalidateQueries([QUERY_KEYS.VACANCY_REQUEST])
        setDuplicityChangeStateEventPrevent(false)
      })
      .catch(err => {
        createAlert(err.message, ALERT_LEVEL.ERROR)
        setDuplicityChangeStateEventPrevent(false)
      })
  }

  const handleRepushVacancyRequestCandidates = async () => {
    setDuplicityChangeStateEventPrevent(true)
    await api
      .repushCandidatesOfVacancyRequest(vacancyRequestId)
      .then(() => {
        createAlert(i18n.templateRequestManager.repushCandidatesSuccess, ALERT_LEVEL.SUCCESS)
        queryClient.invalidateQueries([QUERY_KEYS.VACANCY_REQUEST])
        setDuplicityChangeStateEventPrevent(false)
      })
      .catch(err => {
        if (err.code === 409 && err.msg.message.includes('Operation aborted')) {
          createAlert(i18n.templateRequestManager.repushCandidatesFullfilled, ALERT_LEVEL.INFO)
        } else {
          createAlert(err.msg.message, ALERT_LEVEL.ERROR)
        }
        
        setDuplicityChangeStateEventPrevent(false)
      })
  }

  const processIsOpen = () => {
    return vacancies?.vacancy_request?.state && ! closedProcess.includes(vacancies?.vacancy_request?.state)
  }

  const handleChange = (name, _, value) => {
    setDefaultValue(prevStates => ({
      ...prevStates,
      [name]: value
    }))
  }

  const staff = user.staffs

  let resultOptionsWorkplace = []
  staff.map(place =>
    place.client_places.map(clientPlace => {
      resultOptionsWorkplace.push({
        id: clientPlace.id,
        name: clientPlace.name
      })
    })
  )

  useEffect(() => {
    if (requirementsData && vacancies) {
      const dataRequirementsConstraints = filtrarOptionsRequirements(vacancies?.vacancy_request?.filters?.constraints)
      const filteredResults = requirementsData?.filter(item2 =>
        dataRequirementsConstraints.some(item1 => item2.question_remote_name === item1.id && item2.answer_value === item1.name)
      )
      setRequirementConstraints(filteredResults)

      const dataRequirementsScoringFields = filtrarOptionsRequirements(vacancies?.vacancy_request?.filters?.scoring_fields)
      const filteredResultsScoringFields = requirementsData?.filter(item2 =>
        dataRequirementsScoringFields.some(item1 => item2.question_remote_name === item1.id && item2.answer_value === item1.name)
      )
      setRequirementScoringFields(filteredResultsScoringFields)
    }
  }, [vacancies, requirementsData])

  if (isError) {
    return <NorFound i18n={i18n} classes={classes} />
  }

  return (
    <TemplateRequestManagerStyled>
      <Grid container className="title-container">
        <Grid item sm={6}>
          <BackButton
            title={i18n.templateRequestManager.backToTemplateRequestManager}
            onClick={() => {
              history.goBack()
            }}
          />
        </Grid>
        {isEnabledGuardSection(USER_AUTHORIZED_SECTION_KEYS.TEMPLATE_REQUESTOR) && processIsOpen() && ! isFetching && (
          <Grid item sm={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Tooltip title={i18n.templateRequestManager.cancelProcessTooltip}>
              <Button disabled={duplicityChangeStateEventPrevent} variant="contained" onClick={() => handleChangeState({ id: STATES_TEMPLATE_REQUEST.CANCELLED_STATE })} style={{ marginRight: '10px' }}>
                <IconCancel />
                <span>&#8205;</span>
                {i18n.templateRequestManager.cancelProcess}
              </Button>
            </Tooltip>
            <Tooltip title={i18n.templateRequestManager.closeProcessTooltip}>
              <Button disabled={duplicityChangeStateEventPrevent} variant="contained" onClick={() => handleChangeState({ id: STATES_TEMPLATE_REQUEST.CLOSED_STATE })} style={{ marginRight: '10px' }}>
                <IconDoneAll /> 
                <span>&#8205;</span>
                {i18n.templateRequestManager.closeProcess}
              </Button>
            </Tooltip>
            {vacancies.total_candidates < vacancies.vacancy_request.limit_candidates && (
              <Tooltip title={i18n.templateRequestManager.repushCandidatesTooltip}>
                <Button disabled={duplicityChangeStateEventPrevent} variant="contained" onClick={() => handleRepushVacancyRequestCandidates()}>
                  <IconGroupAdd /> 
                  <span>&#8205;</span>
                  {i18n.templateRequestManager.repushCandidates}
                </Button>
              </Tooltip>
            )}
          </Grid>
        )}
      </Grid>

      <Divider variant="inset" />

      <Grid container className="create-process-container">
        <Box mb={2}>
          <Typography
            variant="h1"
            style={{ color: '#000000', display: 'inline', fontSize: '28px', fontFamily: 'Inter', fontWeight: '600', lineHeight: '1.167' }}
          >
            {i18n.templateRequestManager.showProcess}
          </Typography>
        </Box>

        <Grid item className="sectionSecond" alignItems="center">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Box display="flex" alignItems="center" flexWrap="wrap" gridGap={10}>
                <Box flexGrow={1}></Box>
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={3}>
              <Box>
                <label>{i18n.templateRequestManager.requestor} </label>
                {isFetched ? (
                  <>
                    <Typography variant="subtitle2" sx={{ fontSize: '14px' }}>
                      {vacancies?.vacancy_request?.user?.name + ' ' + vacancies?.vacancy_request?.user?.surname}
                    </Typography>
                    <Typography variant="body1" sx={{ fontWeight: '400', fontSize: '12px' }}>
                      {vacancies?.vacancy_request?.user?.email}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Skeleton variant="rect" width="100%" height={10} />
                    <Skeleton variant="rect" width="100%" height={10} style={{ marginTop: 2 }} />
                  </>
                )}
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} lg={3}>
              <Box>
                <label className="required">{i18n.templateRequestManager.reason}</label>
                {isFetched ? (
                  <InputField
                    value={i18n.templateRequestManager.vacancyReasons[vacancies?.vacancy_request?.reason]}
                    disabled={true}
                  />
                ) : (
                  <Skeleton variant="rect" width="100%" height={10} />
                )}
              </Box>
            </Grid>
            
            <Grid item xs={12} sm={6} lg={3}>
              <Box>
                <label className="required">{i18n.templateRequestManager.workplace}</label>
                {isFetched ? (
                  <InputField
                    placeholder={i18n.templateRequestManager.placeholderWorkplace}
                    value={vacancies?.vacancy_request?.client_place?.name}
                    disabled={true}
                  />
                ) : (
                  <Skeleton variant="rect" width="100%" height={10} />
                )}
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} lg={3}>
              <Box>
                <label className="required">{i18n.templateRequestManager.position}</label>
                {isFetched ? (
                  <InputField
                    placeholder={i18n.templateRequestManager.placeholderPosition}
                    value={vacancies?.vacancy_request?.candidature_settings?.name}
                    disabled={true}
                  />
                ) : (
                  <Skeleton variant="rect" width="100%" height={10} />
                )}
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} lg={3}>
              <Box>
                <label className="required">{i18n.templateRequestManager.numberVacanciesRequest}</label>
                {isFetched ? (
                  <InputField
                    placeholder={i18n.templateRequestManager.numberVacanciesRequest}
                    value={vacancies?.vacancy_request?.vacancies + '/' + vacancies?.vacancy_request?.requested_vacancies}
                    disabled={true}
                  />
                ) : (
                  <Skeleton variant="rect" width="100%" height={10} />
                )}
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} lg={3}>
              <Box className="container-item-text">
                <label className="required">{i18n.templateRequestManager.dateCreated} </label>
                {isFetched ? (
                  <SingleDate date={moment(vacancies?.vacancy_request?.created_at)} readOnly={true} />
                ) : (
                  <Skeleton variant="rect" width="100%" height={10} />
                )}
              </Box>
            </Grid>

            {vacancies?.vacancy_request?.validation_date_at ? (
              <Grid item xs={12} sm={6} lg={3}>
                <Box className="container-item-text">
                  <label className="required">{i18n.templateRequestManager.dateValidation} </label>
                  {isFetched ? (
                    <SingleDate date={moment(vacancies?.vacancy_request?.validation_date_at)} readOnly={true} />
                  ) : (
                    <Skeleton variant="rect" width="100%" height={10} />
                  )}
                </Box>
              </Grid>
            ) : (
              <Grid item xs={12} sm={6} lg={3}>
                <Box className="container-item-text">
                  <label className="required">{i18n.templateRequestManager.dateValidation} </label>
                  {isFetched ? (
                    <Typography variant="subtitle2" sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                      -
                    </Typography>
                  ) : (
                    <Skeleton variant="rect" width="100%" height={10} />
                  )}
                </Box>
              </Grid>
            )}

            <Grid item xs={12} sm={6} lg={3}>
              <Box className="container-item-text">
                <label className="required">{i18n.templateRequestManager.dateIncorporation} </label>
                {isFetched ? (
                  <SingleDate date={moment(vacancies?.vacancy_request?.start_date_at)} readOnly={true} />
                ) : (
                  <Skeleton variant="rect" width="100%" height={10} />
                )}
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} lg={3}>
              <Box>
                <label className="required">{i18n.templateRequestManager.typeContract}</label>
                {isFetched ? (
                  <InputField
                    placeholder={i18n.templateRequestManager.placeholderPosition}
                    value={typeContractOptions.find(option => option.id === parseInt(vacancies?.vacancy_request?.contract_type))?.name}
                    disabled={true}
                  />
                ) : (
                  <Skeleton variant="rect" width="100%" height={10} />
                )}
              </Box>
            </Grid>

            {vacancies?.vacancy_request?.working_day &&<Grid item xs={12} sm={6} lg={3}>
              <Box>
                <label className="required">{i18n.templateRequestManager.workingDayLabel}</label>
                {isFetched ? (
                  <InputField
                    value={workingDayOptions.find(option => option.id === vacancies?.vacancy_request?.working_day)?.name}
                    disabled={true}
                  />
                ) : (
                  <Skeleton variant="rect" width="100%" height={10} />
                )}
              </Box>
            </Grid>}

            {vacancies?.vacancy_request?.work_shift && <Grid item xs={12} sm={6} lg={3}>
              <Box>
                <label className="required">{i18n.templateRequestManager.workShiftLabel}</label>
                {isFetched ? (
                  <InputField
                    value={workShiftOptions.find(option => option.id === vacancies?.vacancy_request?.work_shift)?.name}
                    disabled={true}
                  />
                ) : (
                  <Skeleton variant="rect" width="100%" height={10} />
                )}
              </Box>
            </Grid>}

            {vacancies?.vacancy_request?.contract_hours && <Grid item xs={12} sm={6} lg={3}>
              <Box>
                <label className="required">{i18n.templateRequestManager.contractHoursLabel}</label>
                {isFetched ? (
                  <InputField
                    value={contractHoursOptions.find(option => option.id === vacancies?.vacancy_request?.contract_hours)?.name}
                    disabled={true}
                  />
                ) : (
                  <Skeleton variant="rect" width="100%" height={10} />
                )}
              </Box>
            </Grid>}
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={4}>
              <Box>
                <label className="required">{i18n.templateRequestManager.newStatusProcess} </label>
                {isFetched ? (
                  <Autocomplete
                    size="small"
                    className="autocomplete-custom"
                    value={defaultValue.status || statusOptions.find(option => option.id === vacancies?.vacancy_request?.state)}
                    options={statusOptions}
                    getOptionLabel={option => option.value}
                    onChange={(_, value) => handleChange('status', _, value)}
                    renderInput={params => <TextField {...params} placeholder={i18n.templateRequestManager.placeholderStatusProcess} variant="outlined" />}
                    disableClearable={true}
                    disabled={true}
                  />
                ) : (
                  <Skeleton variant="rect" width="100%" height={10} />
                )}
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <Box>
                <label className="required">{i18n.templateRequestManager.mandatoryRequirements}</label>
                {!requirementsLoading && isFetched ? (
                  <Autocomplete
                    multiple
                    limitTags={2}
                    size="small"
                    className="autocomplete-custom"
                    value={requirementConstraints}
                    options={requirementConstraints}
                    getOptionLabel={option => option.question_name + ' - ' + option.answer_value}
                    renderInput={params => (
                      <TextField {...params} placeholder={i18n.templateRequestManager.placeholderRequirementsRequired} variant="outlined" />
                    )}
                    disableClearable={true}
                    disabled={true}
                  />
                ) : (
                  <Skeleton variant="rect" width="100%" height={10} />
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Box>
                <label>{i18n.templateRequestManager.desirableRequirements}</label>
                {!requirementsLoading && isFetched ? (
                  <Autocomplete
                    multiple
                    limitTags={2}
                    size="small"
                    className="autocomplete-custom"
                    value={requirementScoringFields}
                    options={requirementScoringFields}
                    getOptionLabel={option => option.question_name + ' - ' + option.answer_value}
                    renderInput={params => (
                      <TextField {...params} placeholder={i18n.templateRequestManager.placeholderRequirementsDesirable} variant="outlined" />
                    )}
                    disableClearable={true}
                    disabled={true}
                  />
                ) : (
                  <Skeleton variant="rect" width="100%" height={10} />
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <label>{i18n.templateRequestManager.observations}</label>
                {isFetched ? (
                  <textarea
                    className="textarea-question"
                    rows={2}
                    name="observations"
                    placeholder={i18n.templateRequestManager.placeholderObservations}
                    value={vacancies?.vacancy_request?.information?.observations}
                    disabled={true}
                  />
                ) : (
                  <Skeleton variant="rect" width="100%" height={10} />
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Box mb={2}>
          <Typography
            variant="h1"
            style={{ color: '#000000', display: 'inline', fontSize: '28px', fontFamily: 'Inter', fontWeight: '600', lineHeight: '1.167' }}
          >
            {i18n.templateRequestManager.candidates}
          </Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            {!isLoading ? (
              <TemplateRequestTableManager data={vacancies?.candidates} i18n={i18n} vacancy={vacancyRequestId} client={client} vacancies={vacancies} />
            ) : (
              <SpinnerIcon />
            )}
          </Grid>
        </Grid>
      </Grid>
    </TemplateRequestManagerStyled>
  )
}

export default ProcessShowTemplateRequest
