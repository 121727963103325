export default {
    generalError: 'Houve um erro',
    update: 'Atualizar',
    showMore: 'Mostrar mais +',
    showLess: 'Mostrar menos -',
    markAs: 'Marcar como',

    genericText: {
        "Trabajo en equipo":"Trabalho em equipe",
        "Capacidad de aprendizaje": "Capacidade de aprendizagem",
        "Resolución de Problemas": "Resolução de problemas"
    },

    modalGeneric: {
        cancel: "Cancelar",
        send: "Enviar"
    },

    //Atoms
    avatarMatch: {
        match: 'Match'
    },

    regularButton: {
        apply: 'Aplicar'
    },

    regularSelect: {
        select: 'Selecionar'
    },

    modalHeader: {
        planning: 'Planejamento'
    },

    //Molecules
    modalDeleteUser: {
        removeUser: 'Eliminar',
        enterCandidateName: 'Inserir o nome do candidato para confirmar a ação',
        writeCandidateName: 'Escrever o nome do candidato',
        filterProfiles: 'Filtrar perfis',
        remove: 'Eliminar'
    },

    modalUpdateBlacklist: {
        addToBlacklist: 'Acrescentar à lista negra: ',
        removeFromBlacklist: 'Colocação na lista negra: ',
        enterCandidateName: 'Inserir o nome do candidato para confirmar a ação',
        writeCandidateName: 'Escrever o nome do candidato',
        filterProfiles: 'Filtrar perfis',
        add: 'Acrescentar',
        remove: 'Eliminar'
    },

    modalChangeStatus: {
        changeStatus: 'Modificar estado',
        selectStatus: 'Selecionar estado',
        selectOffer: 'Selecione uma oferta',
        incomplete_form: "Você precisa selecionar o estado e a oferta para poder realizar esta ação",
        select: 'Selecionar',
        suitable: 'Best match',
        not_suitable: 'Disponível',
        not_evaluated: "Not evaluated",
        to_be_confirmed: 'Por confirmar',
        confirmed: 'Confirmado',
        not_interested: 'Não interessado',
        reschedule: 'Reprogramar',
        hired: 'Contratado',
        booking: 'Reserva',
        not_attend: 'Não participa',
        cited_phase2: 'Citado fase 2',
        discarded: 'Descartado',
        descarded_phase2: 'Descartado fase 2',
        not_interested_phase2: 'Não interessado fase 2',
        not_attend_phase2: 'Não participa fase 2',
        pending_update: 'Actualização pendente',
        in_process: 'En proceso',
        hiredDate: 'Data cadastro',
        in_progress: 'Em progresso',
        doc_required: 'Doc solicitada',
        doc_received: 'Doc recebida',
    },


    modalChangeTags: {
        title: 'Modificar etiquetas',
    },

    favoritesTable: {
        date: 'Data',
        markAsRead: 'Marcar como lido',
        changeState: 'Modificar estado',
        markFavorite: 'Marcar favorito',
        removeFavorites: 'Eliminar favoritos',
        fav: 'Favorito',
        export: 'Exportar',
        profile: 'Scoring profile',
        skills: 'Scoring skills',
        matching: 'Matching total',
        status: 'Estado',
        evaluation: 'Avaliação',
        personalData: 'Dados pessoais',
        candidature: 'Candidatura'
    },

    favoritesProcessTable: {
        title: 'Processos favoritos'
    },

    favoritesOnboardingTable: {
        title: 'Onboarding favoritos'
    },

    processesTable: {
        title: 'Inbox Processos',
        id: 'Processo',
        name: 'Nome e sobrenome',
        prominent: 'Destacado',
        date: 'Data e hora',
        department: 'Departamento, área',
        category: 'Categoria',
        type: 'Tipo',
        contact: 'E-mail',
        tel: 'Telefone',
        status: 'Estado',
        markFavorite: 'Marcar favorito',
        removeFavorites: 'Eliminar favorito',
        print: 'Imprimir',
        markAsRead: 'Marcar como lido',
        unmarkAsRead: 'Marcar como não lido',
        filterProcesses: 'Filtrar processos',
        changeStatus: 'Modificar estado',
        changeTags: 'Modificar etiquetas',
        export: 'Exportar',
        all: 'Todos',
        talent_acquisition: 'candidatos selecionados',
        on_board: 'empleados seleccionados',
        welcome:'empleados seleccionados',
        employee_line:'empleados seleccionados',
        feedback:'empleados seleccionados',
        exit_interview:'empleados seleccionados',
        offer_sent: 'Ofertas enviadas',
        statusChanged: 'Status alterado com sucesso',
    },

    offerModal: {
        title: "Criar oferta push",
        name: "Nome da oferta",
        name_placeholder: "Entregador Aranjuez",
        description: "Descrição",
        description_placeholder: "Precisamos de uma pessoa com 2 anos de experiência e um veículo para a área de Aranjuez",
        date_range: "Data de início/fim da oferta",
        areUSure: {
            description: "Você vai notificar {num} pessoa(s) sobre esta oferta, tem certeza?",
            sendCompleted: "Oferta Enviada"
        }
    },

    assignJobOfferModal: {
        title: "Atribuir oferta",
        offer: "Oferta",
        offer_placeholder: "Selecione a oferta que deseja atribuir",
        button_title: "Atribuir oferta",
        assign: "Atribuir",
        successfully_assigned: "Os candidatos foram atribuídos com sucesso à oferta especificada",
        areUSure: {
            description: "Você vai atribuir {num} candidato/s a esta oferta, tem certeza?",
            sendCompleted: "Oferta atribuída"
        }
    },

    assignedModal: {
        title: "Atribuir ao processo",
        assign: "Atribuir",
        subModalTitle: "Confirma a atribuição ao processo?",
        subModalSubTitle: "Candidaturas:",
        subModalSubDescription: "Processo selecionado:",
        latest: "Recentes",
        placeholder: "Buscar por nome do processo"
    },

    process: {
        title: 'Processos',
        category: 'Categoria',
        department: 'Departamento',
        service: 'Serviço',
        process: 'Processo',
        comments: 'Comentários',
        date: 'Data',
        time: 'Hora',
        comment: 'Comentário',
        author: 'Autor',
        documentation: 'Documentos apresentados',
        previewCV: 'CV del candidato',
        downloadDocs: 'Download selecionados',
        deleteDocs: 'Excluir selecionado',
        uploadDocs: 'Upload documentos',
        createComment: 'Criar comentário',
        noPreview: 'Preview no disponible',
        text: 'Texto',
        commentName: 'Comentário',
        dayHourTransaction: 'Data e hora transação',
        area: 'Área',
        centerProvince: 'Centro Província',
        centerType: 'Tipo de centro',
        registerData: 'Data de cadastro',
        noDocuments: 'Não há documentos',
        modifiedStatus: 'Estado modificado',
        deleteSuccess: 'Processo eliminado com sucesso',
        deleteModalTitle: 'Atenção!',
        deleteModalText: 'Tem certeza de que quer eliminar este elemento?',
        remove: 'Eliminar',
        previewVideo: 'Vídeos'
    },

    modalAnnouncement: {
        quoteCall: 'Citar convocação',
        editQuoteCall: 'Convocação',
        date: 'Data de envio',
        citationStatus: 'Resposta',
        citationDate: 'Data de citação',
        citationHour: 'Hora de citação',
        interviewer: 'Entrevistador',
        location: 'Localização',
        select: 'Selecionar',
        announcement_details: 'Detalles',
        writeDetails: 'Especifique os detalhes da chamada',
        writeDetailsIntegration: "Especifique os detalhes da chamada (não pode ser modificado após a criação do evento)",
        body: 'Corpo',
        writeBody: 'Escrever corpo da mensagem',
        defaultMessages: 'Mensagens predeterminados',
        sendCitation: 'Enviar Citação',
        updateCitation: 'Actualizar',
        writeLocation: 'Escrever lugar',
        writeInterviewer: 'Escrever nome do entrevistador',
        new_event: "Assunto",
        invite_people: "Assistentes opcionais",
        invite_people_placeholder: "Inclui emails separados por ;",
        to_time: "até",
        online_meeting: "Reunião online",
        attendees: "Participantes",
        optionals: "Opcionais",
        accepted: "Aceite",
        declined: "Recusado",
        rejected: "Recusado",
        none: "Nenhum",
        pending: "Pendente",
        organizer: "Organizador",
        tentative: "Provisional",
        notes: "Notas",
        confirmation: {
            description_create_individual: 'Você vai criar uma chamada individual, tem certeza?',
            description_create_group: 'Vai criar uma chamada em grupo para {num} pessoas, tem a certeza?',
            description_update: 'Vai editar a convocação para {num} pessoa/s, as convocação originais serão sobrescritas, tem a certeza?',
            description_update_one: 'Já existe uma convocação existente, os dados serão sobre-escritos, tem a certeza?',
        },
        filterBlockCitation: "Citação",
        filterBlockGeneral: "Geral",
        responsible: "Responsável",
    },

    modalRequireDoc: {
        requireDoc: 'Solicitar documentación',
        limitDate: 'Fecha límite para aportar la documentación',
        select: 'Seleccionar',
        infoToUpload: 'Información a aportar',
        writeInfoToUpload: 'Especifica la información a aportar por el candidato',
        body: 'Cuerpo',
        writeBody: 'Escribe un cuerpo del mensaje',
        defaultMessages: 'Mensajes predeterminados',
        sendRequireDoc: 'Enviar',
    },

    modalMailCustom: {
        quoteCall: 'Enviar notificação',
        editQuoteCall: 'Editar convocatoria',
        subject: 'Assunto',
        writeSubject: 'Escrever assunto',
        message: 'Mensagem',
        writeMessage: 'Escrever mensagem',
        body: 'Corpo',
        writeBody: 'Escrever corpo da mensagem',
        defaultMessages: 'Mensagens predeterminados',
        send: 'Enviar Notificação',
        sendCompleted: 'Notificação enviada',
        sendsCompleted: 'Notificações enviadas'
    },

    modalFilter: {
        search: 'Pesquisar',
        notResults: 'Não há resultados',
        filterProfiles: 'Filtrar perfis',
        name: 'Nome',
        candidature: 'Posição',
        namePlaceholder: 'Nome do candidato',
        date: 'Data',
        fromDate: 'De',
        toDate: 'A',
        profile: 'Perfil',
        skills: 'Skills',
        recruitmentSource: 'Fonte de recrutamento',
        recruitmentPlaceholder: 'Infojobs, Linkedin...',
        from: 'De',
        to: 'A',
        inputTextHelper: 'procurar diversas coincidências. Ex.: "az-104;pl-900"',
        matching: 'Matching',
        workingDayLabel: 'Jornada',
        workingDayPlaceholder: '10 horas, 20 horas...',
        select: 'Selecionar',
        availability: 'Disponibilidade',
        availabilityPlaceholder: 'Total, parcial...',
        actualStatus: 'Estado atual',
        actualStatusPlaceholder: 'Apto, Não apto...',
        position: 'Cargo',
        positionPlaceholder: 'Vendedor, Garçom...',
        academicFormation: 'Estudos',
        academicFormationPlaceholder: 'Diplomado, Licenciado...',
        experience: 'Experiência',
        experiencePlaceholder: '1 ano, 2 anos...',
        lastJob: 'Último emprego',
        lastJobPlaceholder: 'Vendedor, Garçom...',
        cityOfResidence: 'Endereço',
        cityOfResidencePlaceholder: 'Madrid, Barcelona...',
        desiredLocation: 'Dados adicionais',
        desiredLocationPlaceholder: 'Madrid, Majadahonda...',
        desiredArea: 'Outros dados',
        desiredAreaPlaceholder: 'Centro, Usera...',
        saveSearch: 'Guardar procura filtrada',
        status: 'Estado',
        planningFilters: 'Filtros de Planejamento',
        candidatureFilters: 'Filtros do Candidato'
    },

    boxOtherInfo: {
        otherData: 'Outros dados',
        additionalInformation: 'Informações complementares',
        nearbyPlaces: 'Centros mais próximos'
    },

    "boxIAGen": {
        "title": "Avaliação IA",
        "matchTitle": "Pontuação",
        "scoresTitle": "Pontuação IA",
        "summaryTitle": "Resumo:"
    },

    boxEditableInfo: {
        title: 'Información editable',
        edit: 'Editar',
        cancel: 'Cancelar',
        accept: 'Aceptar',
        success: 'Campos actualizados correctamente',
        error: 'Error actualizando campos',
    },

    editFieldAction: {
        title: 'Editar campo',
        empty: '(Sin valor)',
    },

    citations: {
        citationCreated: 'Citação criada',
        citationUpdated: 'Citação actualizada',
        citationError: 'Error al crear la citação',
        citations_sent: 'Citações enviadas',
        candidatures: 'Candidatos',
    },

    citationStatus: {
        rejected: 'Rechazado',
        accepted: 'Aceito',
        replan: 'Replanificar',
        pending: 'Sem resposta',
        tentative: 'Provisório',
    },

    requireDoc: {
        docRequired: 'Solicitud de documentación enviada',
        docRequest: 'Documentación solicitada',
        errorDocRequire: 'Error al solicitar documentación',
    },
    interviewerNotes: {
        titleTab: 'Notas da Entrevista',
        edit: 'Editar notas',
        textareaPlaceholder: 'Notas',
        showOnPrint: 'Mostrar ao imprimir',
        updatedAt: 'Modificado por {author}, el {date} a las {hour}',
    },

    headerProfileButtons: {
        back: 'Voltar',
        actions: 'Ações',
        previous: 'Anterior',
        following: 'Seguinte',
        downloadCV: 'Download CV',
        downloadVideo: 'Vídeo anexo',
        markAsRead: 'Marcar como lido',
        quoteCall: 'Citar convocação',
        editQuoteCall: 'Convocatoria',
        changeState: 'Modificar estado',
        changeTags: 'Modificar etiquetas',
        markFavorite: 'Marcar favorito',
        removeFavorites: 'Eliminar favoritos',
        exportFavorites: 'Exportar excel',
        remove: 'Excluir',
        print: 'Imprimir',
        userDeleted: 'Usuário eliminado',
        files: 'Arquivos',
        mailCustom: 'Enviar notificação',
        docs: 'Anexos',
        backToApplications:'Voltar',
        add_to_blacklist: 'Adicionar à lista negra',
        remove_from_blacklist: 'Remover da lista negra',
        added_to_blacklist: 'Adicionado à lista negra',
        removed_from_blacklist: 'Colocado na lista negra',
        backToProcess: 'Voltar ao processo',
        backToEvaluativeTests: 'Voltar a entrevistas em vídeo',
        statusChanged: 'Status alterado com sucesso',
        personDeleted: "Pessoa eliminada com sucesso",
        deletePersonError: "Erro ao eliminar a pessoa"
    },

    modalDownloadFiles: {
        title: 'Arquivos'
    },

    modalFilesPreview: {
        no_preview: 'Preview no disponible'
    },

    modalCreateReport: {
        createReport: 'Criar Relatório',
        name: 'Nome',
        reportName: 'Nome do relatório',
        date: 'Data',
        fromDate: 'De',
        toDate: 'A',
        profile: 'Perfil',
        skills: 'Skills',
        recruitmentSource: 'Fonte de recrutamento',
        matching: 'Matching',
        workingDay: 'Jornada',
        availability: 'Disponibilidade',
        actualStatus: 'Estado atual',
        position: 'Cargo',
        positionPlaceholder: 'Vendedor, Garçom...',
        academicFormation: 'Estudos',
        academicFormationPlaceholder: 'Diplomado, Licenciado...',
        experience: 'Experiência',
        lastJob: 'Último emprego',
        lastJobPlaceholder: 'Vendedor, Garçom...',
        cityOfResidence: 'Endereço',
        cityOfResidencePlaceholder: 'Madrid, Barcelona...',
        desiredLocation: 'Dados adicionais',
        desiredLocationPlaceholder: 'Madrid, Majadahonda...',
        desiredArea: 'Outros dados',
        desiredAreaPlaceholder: 'Centro, Usera...',
        removeUser: 'Eliminar Usuário',
        enterCandidateName: 'Inserir nome do candidato para confirmar a ação',
        writeCandidateName: 'Escrever nome do candidato',
        filterProfiles: 'Filtrar perfis',
        from: 'De',
        to: 'A',
        include: 'Incluir',
        selectAll: 'Seleccionar todos',
    },

    modalChangeAvatar: {
        title: 'Modificar avatar',
        info: 'Arrastra una imagen o pulsa en la caja para buscarla',
        submit: 'Aceptar',
        dropFilesHere: 'Arrastar uma imagem aqui ou clicar para selecionar arquivos...',
        dropFilesActive: 'Imagem válida, soltar para continuar'
    },

    modalChangePass: {
        title: 'Modificar senha',
        forceTitle: 'Para continuar, altere a senha',
        info: 'Inserir nova senha e clicar Aceitar',
        submit: 'Aceitar',
        oldPass: 'Senha atual',
        newPass: 'Nova senha',
        repPass: 'Repetir senha',
        errors: {
            passNotMatch: 'Senhas não coincidem',
            passNotStrongEnough: 'A senha deve ter pelo menos 8 caracteres e deve incluir uma combinação de letras maiúsculas, minúsculas e números.'
        }
    },

    boxProfileCite: {
        currentState: 'Estado atual',
        citationDate: 'Data citação',
        hiringDate: 'Data de contratação',
        citationManager: 'Responsável citação',
        recruitmentSource: 'Fonte de recrutamento',
        tags: 'Etiquetas',
        disponibilidad: "Disponibilidade de horas:"
    },

    boxInfoHeader: {
        currentState: 'Estado atual',
        citationDate: 'Data citação',
        citationManager: 'Responsável citação'
    },

    modules: {
        title: 'Módulos',
        module1: 'Talent Acquisition',
        module2: 'On Boarding',
        module3: 'Welcome Pack',
        module4: 'Linha de funcionário',
        module5: 'Ausência',
        module6: 'Surveys',
        module7: 'Tutor visual',
        module8: 'interview interview'
    },

    module_names: {
        talent_acquisition: "Recrutamento de talentos",
        on_board: "Onboarding",
        employee_line: "Linha do empregado",
        welcome: "Acolhimento",
        feedback: "Feedback",
        abseentism: "Absentismo",
        survey: "Inquérito",
        visual: "Visual",
        exit_interview: "Entrevista de saída",
    },

    myProfile: {
        title: 'Meu perfil',
        logOut: 'Fechar sessão',
        name: 'Nome',
        surname: 'Sobrenome',
        email: 'E-mail',
        id: 'Id',
        client: 'ID do cliente',
        phone: 'Telefone',
        profileUpdated: 'Perfil atualizado',
        avatarUpdated: 'Avatar atualizado',
        updatePass: 'Atualizar senha',
        update: 'Atualizar',
        passUpdated: 'Senha atualizada',
        passUpdatedError: 'Não atualizada senha. Por favor, verificar se a senha é correta.',
        changeLanguage: 'Alterar idioma',
        "languages": [
            { value: "es", name: "Espanhol" },
            { value: "en", name: "Inglês" },
            { value: "ca", name: "Catalão" },
            { value: "pt", name: "Português" },
            { value: 'fr', name: 'Francês' }
        ],
    },

    simpleDialog: {
        defaultMessages: 'Mensagens predeterminados',
        close: 'Fechar'
    },

    saveSearchesTable: {
        actions: 'Ações',
        delete: 'Eliminar',
        title: 'Título',
        date: 'Data',
        fields: 'Campos filtro',
        backToSaveSearches: 'Volver a Búsquedas Guardadas'
    },

    pullConfig: {
        title: 'Pull dos candidatos',
        candidatures: "Candidatos notificados",
    },

    pullConfigTable: {
          actions: 'Ações',
          delete: 'Eliminar',
          title: 'Título',
          is_active: 'Activo',
          renotify_answered: 'Renotificar',
          interval_in_days: 'Periodicidade',
          days: 'días',
          last_time_execution: 'Último envío',
          fields: 'Campos filtro',
          from: 'Desde',
          to: 'hasta',
    },

    reportsTable: {
        actions: 'Ações',
        delete: 'Eliminar',
        title: 'Título',
        date: 'Data',
        fields: 'Campos filtro'
    },

    profileStats: {
        profile: 'Profile',
        skills: 'Skills'
    },

    pda: {
        match: 'Match'
    },

    pdaKeys: {
        r: 'Risco',
        e: 'Extroversão',
        p: 'Paciência',
        n: 'Padrões',
        a: 'Auto-controlo',
    },

    modalSaveSearch: {
        title: 'Nome para pesquisa salvado',
        back: 'Voltar filtro',
        enterName: 'Escrever um nome',
        saveSearch: 'Salvar pesquisa filtrada'
    },

    planningTable: {
        markAsRead: 'Marcar como lido',
        quoteCall: 'Citar convocação',
        editQuoteCall: 'Editar convocatoria',
        changeState: 'Modificar estado',
        markFavorite: 'Marcar favorito',
        removeFavorites: 'Eliminar favoritos',
        exportFavorites: 'Exportar CSV',
        remove: 'Eliminar',
        print: 'Imprimir',
        actions: 'Ações',
        personalData: 'Dados pessoais',
        fav: 'Favorito',
        export: 'Exportar',
        matching: 'Matching',
        citation: 'Citação',
        interviewer: 'Entrevistador',
        atendees: 'Assistentes',
        technical: 'Responsável',
        phase: 'Fase',
        statusPhase: 'Estado',
        citationStatus: 'Resposta',
        status: 'Estado',
        attendees: "Participantes",
        location: 'Localização',
        date: 'Data de envio',
        availability: 'Disponibilidade',
        reason: 'Motivo',
        groupal: 'Grupo',
        view: 'Ver',
    },

    peopleTable: {
        markAsRead: 'Marcar como lido',
        markAsNotRead: 'Marcar como não lido',
        quoteCall: 'Citar convocação',
        editQuoteCall: 'Editar convocatoria',
        changeState: 'Modificar estado',
        markFavorite: 'Marcar favorito',
        removeFavorites: 'Eliminar favoritos',
        exportFavorites: 'Exportar CSV',
        remove: 'Eliminar',
        print: 'Imprimir',
        actions: 'Ações',
        personalData: 'Dados pessoais',
        fav: 'Favorito',
        export: 'Exportar CV',
        exportCsv: 'Exportar Informe',
        requireDoc: 'Solicitar documentación',
    },

    landingOffer: {
        title: 'Hola',
        body: 'Tenemos una nueva <b>oferta de trabajo</b> que podría interesarte: ',
        offerInfo: 'Información de la oferta',
        interested: 'Me interesa',
        notInterested: 'No me interesa',
        notExist: 'No existe la oferta',
        expiredDescription: 'Lo sentimos, esta oferta ya ha finalizado. Le tendremos en cuenta para furturas oportunidades. <br>Muchas gracias por su interes.',
        endDescription: '¡Perfecto! Te confirmamos que hemos recibido tu respuesta. <br>Muchas gracias por participar en nuestro proceso de selección'
    },

    landingPull: {
        title: 'Hola',
        interested: 'Me interesa',
        notInterested: 'No me interesa',
        notExist: 'Página no encontrada',
        endDescription: '¡Perfecto! Te confirmamos que hemos recibido tu respuesta. <br>Muchas gracias'
    },

    landingDocRequest: {
        título: 'Anexe as informações solicitadas: ',
        limit_date: 'Data limite: ',
        notExist: 'Página não encontrada',
        expiredDescription: 'Desculpe, o prazo para fornecer informações já terminou. <br>Muito obrigado pelo seu interesse.',
        endDescription: 'Perfeito! Confirmamos que recebemos a informação. <br>Muito obrigado!',
    },

    dropzone: {
        dragOrBrowse: "Arrastra ficheros o haz click para seleccionarlos",
        file_max_size: "Tamaño máximo de archivo",
        file_too_large: "Arquivo muito grande",
        addFile: "Añadir fichero",
        send: "Enviar",
        error: "Error",
        done: "Enviado",
    },

    observationsTable: {
        today: 'Hoje',
        now: 'Agora',
        title: 'Histórico',
        date: 'Data',
        time: 'Hora',
        change: 'Mudança',
        author: 'Autor',
        addPlaceholder: 'Inserir aqui nova observação',
        add: 'Inserir',
        interviewDate:'Data da Entrevista',
        addObservation:'Adicionar observações',
        deleted_user_name: 'Utilizador',
        deleted_user_surname: 'apagado',
    },

    pushOfferTable: {
        title: 'Ofertas Push',
        name: 'Nome',
        description: 'Descrição',
        userName: 'Usuário',
        createdAt: 'Data de criação',
        state: 'Estado',
    },

    lastCitationsTable: {
        título: 'Últimas citações',
        data: 'Data de envio',
        entrevistaDate:'Data da entrevista',
        userName: 'Organizador',
        localização: 'Localização',
        statusResposta: 'Resposta',
        editCitation: 'Editar citação',
        attendees: 'Participantes',
    },

    offerCard: {
        title: 'Ofertas Push',
        interested_candidatures: ' candidatos interesados',
        updated_at: 'Fecha',
        empty: 'Actualmente no hay ninguna oferta push',
        showOffers: 'Mostrar apenas as minhas ofertas push',
        delete: {
            success: 'La oferta se ha borrado correctamente',
            error: 'Ha ocurrido un error al borrar la oferta'
        }
    },

    //Organism
    appBar: {
        searchPeople: 'Pesquisar perfis',
        filterPeople: 'Filtrar pessoas',
        filterPlanning: 'Filtrar planejamento',
        saveSearchs: 'Salvar pesquisas',
        saveSearch: 'Salvar pesquisa',
        cloneSearch: 'Clonar pesquisa',
        noResults: 'Sem Resultados',
        errorNoTitle: 'O título não pode estar vazio',
        filters: 'Filtros',
        successSave: 'Pesquisa salva'
    },

    profileInfo: {
        profile: 'Perfil',
        availableFrom: 'Disponível em:',
        createdAt: 'Data da entrevista',
        updatedAt: 'Última actualización',
        pullSentAt: 'Envío de pull',
        zone: 'Zona geográfica',
        formation: 'Estudos',
        exp: 'Experiência',
        lastJob: 'Último emprego',
        city: 'Endereço',
        desiredLocation: 'Dados adicionais',
        desiredZone: 'Outros dados',
        years: 'anos',
        cv: 'CV',
        video: 'Vídeo',
        attached: 'Anexo',
        evaluation: 'Avaliação',
        selectionStatus: 'Status de seleção',
    },

    drawer: {
        jobOffers: 'Jobs',
        inboxPeople: 'Inbox people',
        inbox: 'Inbox',
        savedSearches: 'Pesquisas salvas',
        planning: 'Planejamento',
        favorites: 'Favoritos',
        reports: 'Relatórios',
        statistics: 'Estatísticas',
        dashboard: 'Dashboard',
        modules: 'Módulos',
        clients: 'Clientes',
        employeeLine: 'Linha funcionário',
        talent: 'Captação de Talento',
        pushOffer: 'Ofertas Push',
        pullConfig: 'Configuración Pull',
        onboarding: 'Onboarding',
        feedback: 'Sugestões',
        exit_interview: 'Entrevista de salida',
        integrations: 'Integrações',
        my_profile: 'Meu perfil',
        userManual: 'Manual do utilizador',
        phases_states: 'Fases e estados',
        evaluativeTests: 'Entrevistas em vídeo',
        user_management: 'Gestão de usuários',
        tag_management: 'Gestão de etiquetas',
        keywords_rpa: 'Fontes de recrutamento',
        superadministratorManual: 'Manual do Administrador ATS',
        iaEvaluation: 'Avaliação IA',
        templateRequestManager: 'Gestor de petición de plantilla',
        validatorTemplateRequest: 'Validación de procesos'
    },

    statisticsItems: {
        checkAll: 'Marcar todos',
        year: 'Ano',
        month: 'Mês',
        january: 'Janeiro',
        february: 'Fevereiro',
        march: 'Março',
        april: 'Abril',
        may: 'Maio',
        june: 'Junho',
        july: 'Julho',
        august: 'Agosto',
        september: 'Setembro',
        october: 'Outubro',
        november: 'Novembro',
        december: 'Dezembro',
        interviewed: 'Candidatos entrevistados',
        suitables: 'Aptos x entrevistados',
        recruitmentSource: 'Candidatos por fonte de recrutamento',
        activity: 'Atividade de candidatos entrevistados',
        startDate: 'De',
        endDate: 'A',
        candidatures: 'Candidaturas',
        name: 'Nome',
        surname: 'Sobrenomes',
        dni: 'DNI',
        numberIdentity: 'Empregado nº',
        hiringDate: 'Data de recrutamento',
        leavingDate: 'Data de licença',
        employeesWithoutPhase1: 'Empregados que não completaram a fase 1',
        employeesHaveNotDoneExit: 'Empregados que não tenham completado a entrevista de saída',
        candidaturesSuitables: 'Candidaturas aptas',
        hiredVsSuitables: 'Contratados x aptos',
        notInterestedVsSuitables: 'Não interessados x aptos',
        tableTitle: 'Cargos',
        positions: 'Cargos',
        onboarding_phases: 'Personas activas en cada fase del onboarding',
        phases: 'Fases',
        zone: 'Zona',
        alta: "Atualmente registrado",
        baja: "Cancelado o registro",
        whatsappCounter: {
            title: "Contador de WhatsApp",
            section: "Secção",
            bot_starter: "Convites para entrevistas",
            citations: "Citação",
            offers: "Ofertas push",
            pulls: "Atracção de candidaturas",
            sent: "Enviados",
            voucher: "Voucher",
        },
    },

    //Page
    inboxPeople: {
        title: 'Inbox people',
        filtersSearch: 'Pesquisa para'
    },

    offerCandidates: {
        title: 'Oferta: ',
        offers_sent: 'Ofertas enviadas',
        unique_candidatures: 'Candidatos individuais',
        email: 'Email',
        sms: 'SMS',
        whatsapp: 'WhatsApp'
    },

    favorites: {
        title: 'Favoritos'
    },

    planning: {
        title: 'Planejamento'
    },

    reports: {
        title: 'Relatórios',
        reportCreated: 'Relatório criado',
        reportDeleted: 'Relatório eliminado',
        modalConfirmationTitle: 'Geração de relatórios',
        modalConfirmationButton: 'Aceitar',
        modalConfirmationButtonCancel: 'Cancelar',
        reportReceiversTitle: "Insira os destinatários do relatório. Este processo pode demorar alguns minutos. Enviaremos um e-mail quando estiver pronto. Você pode adicionar vários destinatários separando-os por <b>;</b>'"
    },

    savedSearches: {
        title: 'Pesquisas salvas'
    },

    exitInterview: {
        title: 'Entrevistas de Salida'
    },

    statistic: {
        title: 'Estatísticas',
        monday: 'Segunda-feira',
        tuesday: 'Terça-feira',
        wednesday: 'Quarta-feira',
        thursday: 'Quinta-feira',
        friday: 'Sexta-feira',
        saturday: 'Sábado',
        sunday: 'Domingo'
    },

    errorPage: {
        title: 'Ups! Página não encontrada'
    },

    login: {
        login: 'Login',
        sigin: 'Acessar',
        email: 'Correio eletrônico',
        password: 'Senha',
        recover: 'Recuperar',
        password_recover: 'Recuperar senha',
        password_recover_success: 'Foi enviada uma senha de recuperação para o endereço de correio electrónico indicado.',
        password_recover_error: 'Houve um erro no envio do e-mail de recuperação.',
        mandatoryField: 'Campo obrigatório',
        invalidEmail: 'E-mail inválido',
        noValidRole: 'Usuário inválido',
        noValidCredentials: 'Usuário ou senha incorretos'
    },

    tableLocalization: {
        pagination: {
            labelDisplayedRows: '{from}-{to} de {count}',
            labelRowsSelect: 'elementos',
            labelRowsPerPage: 'Elementos por página',
            firstAriaLabel: 'Princípio',
            firstTooltip: 'Ir a início',
            previousAriaLabel: 'Anterior',
            previousTooltip: 'Anterior',
            nextAriaLabel: 'Seguinte',
            nextTooltip: 'Seguinte',
            lastAriaLabel: 'Final',
            lastTooltip: 'Ir a final'
        },
        body: {
            emptyDataSourceMessage: 'Sem dados'
        },
        toolbar: {
            searchTooltip: 'Pesquisar',
            searchPlaceholder: 'Pesquisar',
        }
    },

    savedSearchesItemTableLocalization: {
        pagination: {
            labelDisplayedRows: '{from}-{to} de {count}',
            labelRowsSelect: 'elementos',
            labelRowsPerPage: 'Elementos por página',
            firstAriaLabel: 'Princípio',
            firstTooltip: 'Ir a início',
            previousAriaLabel: 'Anterior',
            previousTooltip: 'Anterior',
            nextAriaLabel: 'Seguinte',
            nextTooltip: 'Seguinte',
            lastAriaLabel: 'Final',
            lastTooltip: 'Ir a final'
        },
        body: {
            emptyDataSourceMessage: 'Não foram encontrados registos para esta pesquisa guardada'
        },
        toolbar: {
            searchTooltip: 'Pesquisar',
            searchPlaceholder: 'Pesquisar',
        }
    },

    errors: {
        internalServerError: 'Não foi possível completar a operação.'
    },

    landingEmail: {
        title: 'Erro',
        body: 'Operação não completa',
        loading: 'Carregando',
        hi: "Olá",
        citation_header: "Gostaríamos de informar que, após avaliar o seu perfil, gostaríamos de avançar com você para a próxima fase do processo seletivo para a posição de",
        citation_info: "Informações da convocatória",
        address: "Endereço:",
        contact_person: "Pessoa de contato:",
        interview_date: "Data da entrevista:",
        interview_hour: "Hora da entrevista:",
        give_answer: 'Por favor, envie-nos a sua resposta a esta convocatória, selecionando uma das seguintes opções:',
        confirmed: 'Confirmo minha presença',
        reschedule: 'Não posso ir, preciso de outra data',
        not_interested: 'Não me interessa',
        citation_not_exist: "Não existe citação",
    },

    landingReschedule: {
        title: 'Quando pode fazer uma entrevista?',
        noDates: 'Não há datas disponíveis',
        successSend: 'Seu pedido foi enviado corretamente',
        putAvailability: 'Inserir disponibilidade',
        send: 'Enviar',
        reasonPlaceholder: 'Inserir razão'
    },

    landingNotInterested: {
        title: 'Pode indicar a razão pela qual não interessa?',
        error: 'Erro',
        successSend: 'Seu pedido foi enviado corretamente',
        putReason: 'Inserir razão',
        send: 'Enviar'
    },

    peopleTableFilters: {
        toManage: 'Para gerenciamento',
        inProgress: 'Em processo',
        finalists: 'Finalistas',
        others: 'Outros'
    },

    noCvInfo: 'Os candidatos selecionados não têm CV associados',

    exitInterviewStatisticsItems: {
        satisfactionSurvey: {
            exitFactors: 'Factores de salida',
            superAgree: 'Muy de acuerdo',
            agree: 'De acuerdo',
            littleDisagree: 'Poco de acuerdo',
            disagree: 'Nada de acuerdo',
            accessibleManager: 'Tu responsable es accesible',
            successRecognized: 'Se reconoce el éxito',
            errorTolerance: 'Tolerancia a los errores',
            flexibility: 'Flexibilidad',
            conciliation: 'Conciliación',
            meritocracy: 'Meritocracia',
            clima1: "Buen clima en la empresa",
            clima2: "Buen clima con tu equipo",
            concilia1: "Permite conciliar con la familia",
            concilia2: "Concilia con los estudios",
            desarrollo1: "Remunerado acorde al trabajo",
            desarrollo2: "Ofrece desarrollo profesional",
        },
        exits: "Bajas",
        exit_completed: "Finalizadas",
        recommends: "Sí, recomienda",
        reason: "Motivos de salida",
    },

    processesStatisticsItems: {
        processesTransaccionalRecived: 'Processos a tratar recebidos',
        suitables: 'Tratados x total',
        numberOfPolls: 'Pesquisas respondidas',
        averagePolls: 'NPS',
        processesTransaccionalGroup: 'Processos a tratar por tipos',
        processesConsultivoGroup: 'Processos consultivos por tipos',
        processesCollectiveAgreementGroup: 'Processos de acordo coletivo',
        startDate: 'De',
        endDate: 'A',
        candidatures: 'Candidaturas',
        name: 'Nome',
        processes: 'Processos',
        recived: 'Recebidos',
        total: 'Total',
        suitablesVsRecibidos: 'Em processo x total',
        tableTitle: 'Processos a tratar'
    },
    tableFilters: {
        toManage: 'Por gerenciar',
        inProgress: 'Em processo',
        finalists: 'Finalistas',
        others: 'Outros'
    },
    onboardingInbox: {
        title: 'Inbox Onboarding'
    },
    onboarding: {
        deleteSuccess: 'Elemento eliminado com sucesso',
        notesTitle: 'Notes'
    },
    onboardingStatisticsItems: {
        leaving: 'Bajas',
        hiring: 'Incorporaciones',
        inprogress: 'Onboardings en progreso',
        ratio: 'Ratio',
        phase: 'Fase',
        hot_issues_title: "Hot issues",
        hot_issues: {
            formacion_online : "Formación online",
            uniforme: "Uniforme disponible",
            epi: "EPI disponible",
            contrato: "Contrato entregado",
            rm: "Reconocimiento médico",
            relacion_equipo: "Mala relación con el equipo",
            formacion_riesprof_hito3: "Formación prevención de riesgos",
            mutua_mc_mutual_hito3: "Protocolo de actuación en caso de accidentes",
            formacion_compliance_hito3: "Formación Compliance",
            reconocimiento_medico_hito3: "Reconocimiento médico",
            welcomepack_hito3: "Welcome Pack",
            employeeapp_hito3: "Employee App",
            protocol_bajamed_hito4: "Protocolo baja médica",
            reunion_manager_hito5: "Reunión Feedback",
        }
    },

    status: {
        received: 'Recebido',
        canceled: 'Cancelado',
        in_process: 'Em processo',
        pending_info: 'Pendente de info',
        processed: 'Tratado',
        accepted: 'Aceito',
        rejected: 'Recusado',
        declined: 'Recusado',
        none: 'Sem resposta',
        pending: 'Sem resposta',
        postponed: 'Adiado',
        tentative: 'Provisional',
        developing: 'Em desenvolvimento',
        implemented: 'Implantado',
        1: 'Best match',
        2: 'Disponível',
        3: 'Por confirmar',
        4: 'Confirmado',
        5: 'Não interessado',
        6: 'Reprogramar',
        7: 'Contratado',
        8: 'Reserva',
        9: 'Não participa',
        10: 'Citado fase 2',
        11: 'Descartado',
        12: 'Descartado fase 2',
        13: 'Não interessado fase 2',
        14: 'Não participa fase 2',
        15: 'En proceso',
        16: 'Doc solicitada',
        17: 'Doc recibida',
        18: 'Actualização pendente',
        19: 'Citado'
    },
    evaluativeConfig: {
        'pending': "Pendente",
        'in_review': "Realizado",
        'suitable_in_other_process': "Apto outros postos",
        'suitable': "Aprovado",
        'not_suitable': "Reprovado",
        'resignation': "Declinou",
        'no_show': "Não apresentado",
      },
    dialog: {
        success: 'Aceitar',
        decline: 'Cancelar',
        save: 'Guardar'
    },

    feedbackStatisticsItems: {
        Received: 'Sugestões recebidas',
        suitables: 'Sugestões em andamento x total',
        category: 'Sugestões por categorias',
        department: 'Sugestões por áreas',
        centerType: 'Sugestões por unidade',
        statesTypes: 'Sugestões por estados',
        endDate: 'Até',
        name: 'Nome',
        recived: 'Recebidos',
        total: 'Total',
    },

    talent_acquisition: {
        inbox: {
            title: 'Inbox Candidatos',
        },
        favorites: {
            title: 'Favoritos'
        },
        'saved-searches': {
            title: 'Búsquedas guardadas',
        },
        'saved-searches-item': {
            title: 'Búsquedas guardadas',
        },
        'push-offer': {
            title: 'Ofertas Push',
        },
        'offer-candidatures': {
            title: 'Ofertas Push',
        },
        'pull-config': {
            title: 'Pull de candidatos',
        },
        planning: {
            title: 'Planificación',
        },
        reports: {
            title: 'Informes',
        },
        statistics: {
            title: 'Estadísticas',
        },
    },

    on_board: {
        inbox: {
            title: '',
        }
    },

    employee_line: {
        inbox: {
            title: '',
        },
        'saved-searches': {
            title: 'Pesquisas salvas',
        },
        'saved-searches-item': {
            title: 'Pesquisas salvas',
        }
    },

    feedback: {
        inbox: {
            title: 'Inbox Items',
        },
        title: 'Inbox Items',
        filter: 'Filtrar Items',
        date: 'Data',
        questions: 'Perguntas',
        proposal: 'Item',
        category: 'Categoría',
        email: 'Email',
        dni: 'DNI',
        phone: 'Teléfono',
    },

    exit_interview: {
        inbox: {
            title: 'Exit interview',
        },
        exit_reasons: "Motivos de salida",
        exit_factors: "Factores de salida",
        other_data: "Otros datos",
        position: "Puesto",
        leaving_date: "Fecha de salida",
        male_or_female: "Género",
        male: "Hombre",
        female: "Mujer",
        category: "Categoría",
        email: "Email",
        dni: "DNI",
        phone: "Tel",
        Motivo1: 'Motivo de salida',
        Motivo2: 'Submotivo de salida',
        Motivo3: 'Observaciones motivo de salida',
        Clima1: "La relacion con el responsable ha sido",
        Clima2: "El clima de trabajo ha sido satisfactorio",
        Concilia1: "¿Me han dado facilidades de cambio de turnos?",
        Concilia2: "¿Cómo crees que podríamos mejorar?",
        Desarrollo1: "¿La empresa le ha dado opciones de desarrollo?",
        Desarrollo2: "¿La formación recibida ha cumplido sus expectativas?",
    },

    integrations: {
        outlook: "O meu calendário do Outlook",
        google: "O meu calendário do Google",
        connect: "Ligar",
        disconnect: "Desatar",
    },

    userManual: {
        title: 'Manual do utilizador',
    },

    superadministratorManual: {
        title: 'Manual do Administrador ATS',
    },

    phases_states: {
        descriptionPhasesStates: "A partir daqui, pode criar, editar ou eliminar fases e estados de aplicações.",
        titlePhase: "Fases",
        titleState: "Estados",
        descriptionPhase: "As fases são cada uma das etapas do processo de seleção por que passa o candidato.",
        descriptionState: "O estado de uma fase indica se foram tomadas medidas nessa fase do processo do candidato, facilitando a identificação do progresso nessa fase..",
        labelPhase: "Fase",
        labelState: "Estado",
        labelCurrentState: "Estado atual",
        labelNewState: "Novo estado",
        errorOnDeleteStatus: "Você não pode excluir um status que está em uso, ele possui {candidaturas} que você deve primeiro mover para outro status.",
        placeholderPhase: "Digite o nome da fase",
        placeholderState: "Digite o nome do estado",
        placeholderSelectPhase: "Selecione uma fase",
        titleButtonPhase: "Adicionar fase",
        titleButtonState: "Adicionar estado",
        atribuaStateToPhase: "Atribuir estado à fase",
        botãoSalvar: "Salvar",
        buttonAllSave: "Salvar alterações",
        buttonCancel: "Cancelar",
        buttonMove: "Mover",
        titleFunnel: "Ilustração de fluxo",
        subtitleFunnel: "Fases e estados do processo seletivo",
        titlePhaseFunnel: "Fase",
        buttonDelete: "Excluir",
        selectionFunnel: "Funil de seleção",
        currenteState: "Estado Atual",
        labelColor: "Atribuir uma cor",
        placeholderDescriptionStatus: "Digite uma mensagem",
        feedbackMessage: "Mensagem de feedback",
        feedbackMessageRegistered: "<p>Agradecemos sinceramente seu interesse em se juntar à nossa equipe e agradecemos o tempo e esforço que você investiu no envio de seu perfil. </p><p><br></p><p>Queremos você saiba que sua inscrição foi registrada em nosso sistema e que estamos analisando cuidadosamente todas as inscrições recebidas. </p><p><br></p><p>Neste momento, estamos avaliando todas as inscrições e procurar a pessoa ideal que se enquadre nos requisitos do cargo e na cultura da nossa empresa. Esse processo pode levar algum tempo, pois nos comprometemos a fazer uma seleção criteriosa. </p><p><br></p> <p>Garantimos que o manteremos atualizado sobre as próximas etapas do processo seletivo assim que tivermos novidades sobre uma oferta que se alinhe ao seu perfil e motivação. </p><p><br></p ><p> Nossa intenção é ser transparente e fornecer todas as informações necessárias para que você possa acompanhar o desenvolvimento da sua candidatura. Muito obrigado pela sua paciência e compreensão durante este processo. </p><p><br></p><p>Agradecemos seu interesse em fazer parte de nossa equipe e esperamos ter a oportunidade de conhecê-lo melhor nas próximas etapas do processo seletivo.< /p>",
        feedbackMessageHired: "<p>Parabéns! Temos o prazer de informar que você foi selecionado para fazer parte de nossa equipe. </p><p><br></p><p>Durante o processo seletivo, seu desempenho será avaliado e habilidades se destacaram e temos certeza de que sua experiência e contribuições serão muito valiosas para nossa empresa. </p><p><br></p><p>Nos próximos dias entraremos em contato para compartilhar os detalhes finais do contrato, a documentação necessária, bem como fornecer informações sobre a orientação e qualquer preparação necessária para o seu primeiro dia. </p><p><br></p><p>Nós parabenizamos você mais uma vez e esperamos o melhor de você nesta nova etapa de sua vida profissional. </p><p><br></p><p>Damos-lhe as boas-vindas à equipe!</p> ",        feedbackMessageDefaultCustom: "Estamos analisando sua inscrição, entraremos em contato em breve.",
        selectionStatus: "Selecione um estado",
        questionChangeStatus: "Deseja alterar o status dos candidatos mencionados?",
        changeStatusCheckApplication: 'Para alterar o estado do candidato, selecione a oferta e o estado a alterar',
        changeStatus: "Alterar status",
        modalEditPhaseTitle: "Editar fase",
        modalEditStateTitle: "Editar estado",
        modalDeletePhaseTitle: "Excluir fase",
        modalDeleteStateTitle: "Excluir estado",
        chipUnAssigned: "Não atribuído",
        errorOrderPhasesUpdate: "Erro ao atualizar a ordem das fases",
        errorOrderStatusUpdate: "Erro ao atualizar o pedido de status",
        changeSavedSuccessfully: "Alterações salvas com sucesso",
        phaseReceived: "Recebido",
        phaseHired: "Contratado",
        alertCannotAddMoreEightPhases: "Não é possível adicionar mais de 12 fases",
        alertCreatedPhase: "Fase criada com sucesso",
        alertPhaseExists: "Já existe uma fase com esse nome",
        alertErrorCreatePhase: "Erro ao criar fase",
        alertAddNamePhase: "Você deve adicionar um nome à fase",
        alertCreateStatus: "Status criado com sucesso",
        alertErrorGetPhases: "Erro ao obter fases",
        alertStatusExists: "Já existe um status com esse nome",
        alertErrorCreateStatus: "Erro ao criar status",
        alertErrorGetStatus: "Erro ao obter status",
        alertSelectPhase: "Selecione uma fase antes de adicionar um estado",
        alertTitleCannotDeletePhase: "Você não pode excluir esta fase",
        messageAlertCannotDeletePhase: "Você tem estados associados a esta fase, você deve movê-los para outra fase existente ou excluí-los para excluir esta fase.",
        alertThereAreNoStatusesInPhase: "Não há estados associados a esta fase",
        stateInscribed: "Inscrito",
        stateHired: "Contratado",
        updatePhase: "Fase atualizada com sucesso",
        errorUpdatedPhase: "Erro ao atualizar fase",
        atualizadoStatus: "Status atualizado com sucesso",
        errorUpdatedStatus: "Erro ao atualizar o status",
        alertMessageFeedback: "Deve adicionar uma mensagem de feedback",
        alertStateNameRequired: "Deve adicionar um nome ao estado",
        questionNotified: "Deseja que seja enviada uma mensagem alertando as pessoas que passam para esse status sobre a mudança de candidatura?",
        selectNotificationMethods: "Escolha o canal através do qual você deseja que as notificações sejam enviadas.",
        infoSelectNotificationMethods: "Lembre-se que você deve ter um modelo projetado para esta comunicação.",
        smsMethod: "SMS",
        emailMethod: "E-mail",
        whatsappMethod: "WhatsApp",
    },

    evaluativeTests: {
        title: "Entrevistas em vídeo",
        descriptionSection: "Aqui encontra todas as suas entrevistas em vídeo activas e inactivas",
        titleNewTest: "Nova entrevista em vídeo",
        titleEditTest: "Editar entrevista em vídeo",
        subtitleNewTest: "Configuração da entrevista em vídeo",
        descriptionNewTest: "Efetuar a configuração, de acordo com as necessidades do processo de seleção",
        labelPickerStartDate: "Data de início",
        labelPickerEndDate: "Data de fim",
        nameTest: "Nome da entrevista em vídeo",
        placeholderNameTest: "Introduzir o nome da entrevista em vídeo",
        urlBot: "URL do bot",
        placeholderUrl: "https://...",
        buttonGenerateUrl: "Gerar URL",
        selectionQuestions: "Perguntas de seleção",
        subtitleTest: "Efetuar um filtro mais completo dos candidatos",
        subtitleTest2: "Criar pelo menos uma pergunta, atribuir um tempo máximo de resposta e concentrar-se nos candidatos que responderam corretamente",
        placeholderInputQuestion: "Pergunta n.º 1",
        buttonAddQuestion: "Adicionar pergunta",
        buttonSave: "Guardar",
        buttonCancel: "Cancelar",
        buttonRegenerateUrl: "Regenerar URL",
        inactive: "(inativa))",
        noResults: "Não foram encontrados resultados para a data selecionada",
        errorFetchTests: "Ocorreu um erro ao obter as entrevistas em vídeo",
        buttonCreateTest: "Criar entrevista em vídeo",
        singleQuestion: "Pergunta",
        multipleQuestions: "Perguntas",
        successDeleteTest: "Entrevista em vídeo eliminada com êxito",
        successDuplicateTest: "Entrevista em vídeo duplicada com sucesso",
        successCreateTest: "Entrevista em vídeo criada com sucesso",
        successUpdateTest: "Entrevista em vídeo actualizada com sucesso",
        useSampleQuestion: "Usar pergunta de amostra",
        activesTest: "Ativas",
        inactivesTest: "Todos",
        titleBoxProfileInbox: "Entrevistas em vídeo",
        statusVideoInterview: "Estado da entrevista em vídeo",
        infoUploadVideo: "O candidato não carregou o vídeo",
        titleTemplate: "Modelos de bot",
        successChangeStatus: "Estado alterado com êxito",
        errorFieldRequired: "Este campo é obrigatório",
        errorFieldInvalid: "Este campo é inválido",
        errorFieldQuestion: "Tem de adicionar pelo menos uma pergunta",
        placeholderSelectTemplate: "Selecionar um modelo",
        placeholderTimeLimit: "Tempo máximo",
        successRegenerateUrl: "O url foi regenerado e o candidato pode aceder novamente",
        errorRegenerateUrl: "Ocorreu um erro ao regenerar o url",
        inactiveCard: "Inativo",
        menuEdit: "Editar",
        menuAddCsv: "Importar candidatos",
        menuDuplicate: "Duplicar",
        menuDelete: "Excluir",
        placeholderQuestionOne: "Conte-nos em 30 segundos a parte mais relevante da sua experiência profissional e como ela se relaciona com a posição para a qual você está se candidatando.",
        placeholderQuestionTwo: "Como você se comunica de forma eficaz com as diferentes partes interessadas (clientes, colegas de trabalho, fornecedores, etc.)?",
        placeholderQuestionThree: "Quais habilidades você considera mais importantes para este trabalho?",
        placeholderQuestionFour: "Como você contribui para o trabalho em equipe e a colaboração em um ambiente de trabalho?",
        placeholderQuestionFive: "Descreva uma situação em que você superou um desafio no trabalho",
        placeholderQuestionSix: "Quais são seus objetivos de médio/longo prazo e como essa posição se alinha a eles?",
        titleQuestion: "Questão",
        csvUpload: {
            editCta: "Importar candidatos",
            modalCheckCandidates: "Você está prestes a importar %ok% candidatos no arquivo.",
            modalImportCandidates: "Deseja continuar? Eles receberão uma notificação via Whatsapp/email.",
            candidatesImported: "Candidatos importados com sucesso (%ok% / %total%)",
            candidatesNotifiedError: "Erro ao notificar os candidatos"
        }
    },

    inboxColumns: {
        'Favoritos': 'Favoritos',
        'Nombre y apellidos': 'Nome e apelido',
        'País': 'País',
        'DNI':  'EU IA',
        'Última videoentrevista': 'Nome da entrevista em vídeo',
        'Estado videoentrevista': 'Estado da entrevista em vídeo',
        'Fecha de envío':  'Data de expedição',
    },

    filterFields: {
        'Nombre': 'Nome',
        'DNI': 'DNI',
        'Email':  'Email',
        'Fecha de entrevista': 'Data da entrevista',
        'Tel\u00e9fono': 'Telefone',
        'Video entrevista': 'Entrevista em vídeo',
        'Estado': 'Estado',
      },

    filterBlocks: {
        'Video entrevista': 'Entrevista em vídeo',
        'Datos personales': 'Dados pessoais',
        'Evaluaci\u00f3n': 'Avaliação',
    },

    user_management: {
        titleSection: "Administração de usuários",
        searchLabel: "Pesquisador",
        filterProfile: "Filtrar por perfil",
        placeholderFilter: "Selecione um perfil",
        descriptionSection: "Aqui você pode controlar e personalizar as permissões e o acesso dos administradores ATS. Adicione, modifique ou exclua contas de usuário, atribua funções específicas e garanta um gerenciamento eficiente do sistema.",
        activeUsers: "Ativo",
        inactiveUsers: "Inativo",
        createUser: "Criar usuário",
        username: "Nome de usuário",
        lastname: "Sobrenome",
        email: "Email",
        perfil: "Perfil",
        modalTitleCreate: "Criar usuário",
        modalTitleUpdate: "Modificar usuário",
        labelShowDefaultFiltersTalent: "Configurar filtros predefinidos do módulo Talent",
        labelName: "Nome",
        labelLastName: "Sobrenome",
        labelEmail: "E-mail",
        labelModules: "Módulos",
        labelTelefone: "Telefone",
        labelProfile: "Perfil",
        labelPassword: "Senha provisória",
        buttonCreateUser: "Criar usuário",
        buttonCancel: "Cancelar",
        buttonUpdateUser: "Atualizar usuário",
        placeholderName: "Digite o nome",
        placeholderLastName: "Digite o sobrenome",
        placeholderEmail: "Digite o e-mail",
        placeholderPassword: "Digite a senha",
        placeholderProfile: "Selecione um perfil",
        disableUsers: "Desativar usuários",
        disableUser: "Desativar usuário",
        inputEmailUser: "Digite o email do usuário",
        confirmDeactivation: "Você pode confirmar que deseja desativar o usuário {nome} {sobrenome}?",
        confirmActivation: "Você pode confirmar que deseja ativar o usuário {nome} {sobrenome}?",
        buttonConfirmDisable: "Sim, desabilitar usuário",
        buttonConfirmEnable: "Sim, ativar us    uário",
        buttonConfirmDisableUsers: "Sim, desabilitar usuários",
        buttonConfirmEnableUsers: "Sim, habilitar usuários",
        atsAdminUser: "Super Administrador",
        supervisorUser: "Supervisor",
        basicUser: "Básico",
        customUser: "Personalizado",
        readOnlyUser: "Somente leitura",
        criadoUser: "Usuário criado com sucesso",
        editedUser: "Usuário modificado com sucesso",
        errorCreateUser: "Erro ao criar usuário",
        errorEditUser: "Erro ao editar usuário",
        nameRequired: "Você deve adicionar um nome",
        sobrenomeObrigatório: "Você deve adicionar um sobrenome",
        emailRequired: "Você deve adicionar um email",
        emailInvalid: "E-mail inválido",
        passwordRequired: "Você deve adicionar uma senha",
        moduleRequired: "É necessário selecionar um módulo",
        profileRequired: "Você deve selecionar um perfil",
        confirmDisableUsers: "Você pode confirmar que deseja desabilitar os seguintes usuários?",
        confirmEnableUsers: "Você pode confirmar que deseja ativar os seguintes usuários?",
        userDisabledSuccessfully: "Usuário desabilitado com sucesso",
        usersDisabledSuccessfully: "Usuários desabilitados com sucesso",
        userEnabledSuccessfully: "Usuário habilitado com sucesso",
        usersEnabledSuccessfully: "Usuários habilitados com sucesso",
        userDisabledError: "Erro ao desabilitar usuário",
        usersDisabledError: "Erro ao desabilitar usuários",
        userEnabledError: "Erro ao ativar usuário",
        usersEnabledError: "Erro ao ativar usuários",
        passwordRequirements: "A senha deve ter pelo menos 8 caracteres, uma letra e um número.",
        passwordInvalid: "A senha é inválida",
        passwordValid: "A senha é válida",
        userAlreadyExists: "O usuário já existe!",
        placeholderModules: "Selecionar os módulos para este utilizador",
        labelAuthorizedSections: "Secções",
        placeholderAuthorizedSections: "Selecionar as secções para este perfil",
        authorizedSectionsRequired: "Tem de lhe dar acesso a alguma secção",
        inbox: "Inbox",
        push_offer: "Ofertas push",
        pull_config: "Configuração de tração",
        saved_searches: "Pesquisas guardadas",
        planning: "Planeamento",
        favorites: "Favoritos",
        reports: "Relatórios",
        statistics: "Estatísticas",
        statistics_dashboard: "Dashboard estatísticas",
        rpa_config: "Fontes de recrutamento",
        evaluative_tests: "Entrevistas em vídeo",
        template_requestor: "Gestor de solicitação de modelo",
        template_validator: "Validação de processos",
        module: 'Módulo',
      },

    ia_evaluation: {
        titleSection: "Seleção automatizada pelo IAG",
        descriptionSection: "Personalizar e configurar o sistema de seleção automatizado pelo IAG. Nesta primeira fase, crie e edite as suas avaliações de acordo com as posições configuradas.",
        createEvaluation: "Criar uma nova avaliação",
        searchLabel: "Pesquisar",
        placeholderInputTerm: "Nome",
        active: "Ativo",
        inactive: "Inativo",
        disable: "Desativar avaliação",
        enable: "Ativar avaliação",
        name: "Nome",
        job: "Trabalho",
        family: "Família",
        criteria: {
            experience: "Experiência",
            education_training: "Formação",
            salary_expectation: "Expectativas salariais",
            employment_status: "Situação profissional",
            location: "localização",
            mobility: "mobilidade",
            availability: "disponibilidade",
        },
        relevance_level: {
            relevant: "Relevante",
            desired: "Desejável",
            mandatory: "Descartar",
            ignore: "Não avaliar",
            others: 'Informativo'
        },
        buttonConfirmDisable: "Sim, desativar avaliação",
        buttonConfirmEnable: "Sim, ativar a avaliação",
        confirmDeactivation: "Pode confirmar que pretende desativar a avaliação {name}?",
        confirmActivation: "Pode confirmar que pretende ativar a avaliação {name}?",
        evaluationDisabledSuccessfully: "Avaliação desactivada corretamente",
        evaluationEnabledSuccessfully: "Avaliação accionada com sucesso",
        evaluationDisabledError: "Erro ao desativar a avaliação",
        evaluationEnabledError: "Erro ao ativar a avaliação",
        wizard: {
            title_create: "Criar nova avaliação",
            title_edit: "Editar avaliação",
            subtitle: "Critérios de avaliação",
            description: "Os critérios de avaliação podem incluir aspectos como a experiência, a formação, a localização, entre outros. Esta seleção estabelece as bases para a avaliação inicial dos candidatos e é um passo fundamental para garantir que o processo de seleção está alinhado com as especificidades da avaliação que está a ser moldada.",
            name: "Nome",
            name_placeholder: "Introduzir o nome do perfil",
            family: "Família de mensagens",
            family_placeholder: "Selecionar uma família de empregos",
            job: "Postos",
            job_placeholder: "Selecionar uma posição",
            rpa_credentials: "Credenciais da Fonte de Recrutamento",
            rpa_credentials_placeholder: "Selecione uma fonte de recrutamento",
            info_text: "Poderá ser necessário efetuar ajustamentos à avaliação para o posto de trabalho de:",
            info_text_values: "<strong>{job}</strong> da família <strong>{family}</strong>.",
            tableHeader: "Escolha os elementos a que pretende dar mais peso na avaliação:",
            save: "Guardar definições",
            cancel: "Cancelar",
            save_confirmation_title: "O {mode} da avaliação confirma-se?",
            save_confirmation_button: "Confirmo {mode}",
            create_mode: "criação",
            edit_mode: "edição",
            successCreateEvaluation: "Avaliação criada com êxito",
            errorCreateEvaluation: "Erro ao criar a avaliação",
            successUpdateEvaluation: "Avaliação corretamente actualizada",
            errorUpdateEvaluation: "Erro ao atualizar a avaliação",
        },
    },

    job_offers: {
        titleSection: "Jobs",
        descriptionSection: "O que você poderá ver aqui? Nesta visualização, você encontrará a lista de suas ofertas publicadas nas fontes de recrutamento configuradas, bem como as candidaturas espontâneas e também os processos que você criar manualmente. Acesse cada uma delas para visualizar os candidatos e gerenciar seus processos de seleção.",
        filterJobs: "Filtrar Ofertas",
        createJob: "Criar oferta",
        name: "Oferta",
        createdBy: "Criado por",
        keyword: "Keyword",
        not_available: 'Não disponível',
        profile: "Cargo",
        location: "Localização",
        createdAt: "Data de Criação",
        endDate: "Data de Término",
        recruitmentSource: "Fonte de Recrutamento",
        newCandidates: "Novos Candidatos",
        totalCandidates: "Total de Candidatos",
        candidatesNewVsTotal: "Novos Candidatos / Total de Candidatos",
        infojobs: "Infojobs",
        infojobsPriority: "Infojobs",
        epreselec: "ePreselec",
        epreselecPriority: "ePreselec",
        pandape: "Pandapé",
        pandapePriority: "Pandapé",
        turijobs: "Turijobs",
        turijobsPriority: "Turijobs",
        computrabajo: "Computrabajo",
        computrabajoPriority: "Computrabajo",
        talentclue: 'Talent Clue',
        bizneo: 'Bizneo',
        indeed: 'Indeed',
        manual: "Manual",
        spontaneous: 'Espontânea',
        active_offers: "Ofertas Ativas",
        inactive_offers: "Ofertas Inativas",
        filter_offers: "Filtrar ofertas",
        offer_status: "Estado da oferta",
        active_label: "Ativo",
        inactive_label: "Inativo",
        wizard: {
            title_create: "Criar novo job",
            title_edit: "Editar oferta",
            // subtitle: "Critérios de avaliação",
            description: "Nesta modal, você pode criar um novo job. Você deve dar um nome, atribuir um local e uma data de término, e, claro, os detalhes que considerar necessários. Depois de criá-lo, você poderá atribuir candidatos da Caixa de Entrada a este job, para tê-los agrupados no mesmo processo e gerenciar cada candidatura dentro deste job.",
            name: "Nome",
            name_placeholder: "Digite o nome da oferta",
            description_value: "Descrição",
            description_value_placeholder: "Digite uma descrição",
            evaluation_profile: "Fluxo de avaliação",
            evaluation_profile_placeholder: "Selecione um fluxo de avaliação",
            location: "Localização",
            location_placeholder: "Digite a localização",
            recruitment_source: "Fonte de recrutamento",
            recruitment_source_placeholder: "Selecione uma fonte de recrutamento",
            created_at: "Data de criação",
            created_at_placeholder: "Selecione a data de criação",
            end_at: "Data de término",
            end_at_placeholder: "Selecione a data de término",
            save: "Salvar",
            save_confirmation_title: "Confirma a {mode} da oferta?",
            save_confirmation_button: "Confirmo {mode}",
            create_mode: "criação",
            edit_mode: "edição",
            successCreateJobOffer: "Oferta criada com sucesso",
            errorCreateJobOffer: "Erro ao criar a oferta",
            successUpdateJobOffer: "Oferta atualizada com sucesso",
            errorUpdateJobOffer: "Erro ao atualizar a oferta"
        }
    },
    job_offer_applications: {
        backToJobOffers: "Voltar para jobs",
        filter: "Filtrar candidatos",
        is_favorite: "Favoritos",
        name_and_surname: "Nome e sobrenome",
        rating: "Classificação do candidato",
        level_one: "Pontuação Nível 1",
        level_two: "Pontuação Nível 2",
        level_three: "Pontuação Nível 3",
        phase: "Fase",
        selection_status: "Estado",
        location: "Localização",
        created_at: "Data",
        last_job: "Último cargo",
        ia_matching: "Pontuação IA",
        match: "Correspondência",
        bot_matching_pending: "Pendente",
        formation: "Formação",
        cv: "CV"
    },
    tag_management: {
        titleSection: "Gestão de etiquetas",
        searchLabel: "Pesquisador",
        filterModule: "Filtrar por módulo",
        placeholderFilter: "Selecionar um módulo",
        descriptionSection: "Aqui pode controlar e personalizar as etiquetas. Adicionar, modificar ou eliminar etiquetas para um fluxo de trabalho eficiente.",
        createTag: "Criar etiqueta",
        name: "Nome",
        description: "Descrição",
        color: "Cor",
        module: "Módulo",
        modalTitleCreate: "Criar etiqueta",
        modalTitleUpdate: "Modificar a etiqueta",
        labelName: "Nome",
        labelDescription: "Descrição",
        labelColor: "Cor",
        labelModule: "Módulo",
        labelMatchingMin: "Correspondência mínima",
        labelMatchingMax: "Correspondência máxima",
        buttonCreateTag: "Criar etiqueta",
        buttonCancel: "Cancelar",
        buttonUpdateTag: "Atualizar a etiqueta",
        placeholderName: "Introduzir o nome",
        placeholderDescription: "Introduzir descrição",
        placeholderColor: "Introduzir a cor",
        placeholderModule: "Selecionar um módulo",
        placeholderMatchingMin: "Selecione a pontuação mínima de correspondência para atribuição automática",
        placeholderMatchingMax: "Selecione a pontuação máxima de correspondência para atribuição automática",
        nameRequired: "É necessário acrescentar um nome",
        descriptionRequired: "É necessário acrescentar uma descrição",
        colorRequired: "É necessário acrescentar uma cor",
        moduleRequired: "É necessário selecionar um módulo",
        dateUpdated: "Data de atualização",
        errorCreateTag: "Erro ao criar a etiqueta",
        errorUpdateTag: "Erro ao atualizar a etiqueta",
        deleteTagError: "Erro ao eliminar a etiqueta",
        createdTag: "Etiqueta criada com sucesso",
        editedTag: "Etiqueta modificada com sucesso",
        removeTag: "Remover a etiqueta",
        remove_tag_confirmation: "Confirma que deseja remover a etiqueta {tag_name}?",
        remove_confirmed: "Sim, remover a etiqueta",
        deleteTagSuccess: "Etiqueta removida com sucesso",
        tag_in_use: "Esta etiqueta está atualmente atribuída a um ou mais candidatos. Tem duas opções: Eliminar diretamente a etiqueta, deixando os candidatos sem etiqueta, ou atribuí-los a outra etiqueta disponível. O que pretende fazer?",
        remove_anyways: "Eliminar de qualquer forma",
        labelCurrentTag: "Etiqueta atual",
        labelNewTag: "Nova etiqueta",
        moveToEmpty: "É necessário selecionar a etiqueta para a qual a mover.",
    },

      keywords_rpa: {
        titleSection: "Integrações de feed de recrutamento",
        descriptionSection: "Aqui você pode controlar e personalizar as permissões e o acesso dos administradores da plataforma. Adicione, modifique ou exclua contas de usuários, atribua funções específicas e garanta um gerenciamento eficiente do sistema.",
        newIntegration: "Nova integração",
        searchLabel: "Pesquisar",
        filterSource: "Filtrar por fonte",
        placeholderFilter: "Selecione uma fonte",
        sourceInfojobs: "Infojobs",
        sourceEpreselec: "ePreselec",
        sourceComputrabajo: "Computrabajo",
        sourcePandape: "Pandapé",
        sourceTurijobs: "Turijobs",
        activeIntegrations: "Ativo",
        inactiveIntegrations: "Inativo",
        inactiveIntegration: "Integração inativa",
        position: "Posição",
        fonte: "Fonte de recrutamento",
        user: "Usuário",
        keyword: "Palavra-chave",
        lastExecution: "Última execução",
        connectionStatus: "Status da conexão",
        connectionStatuses: {
          "pending": "Pendente de execução",
          "ok": "Conexão estabelecida",
          "error_login": "Credenciais inválidas",
          "error_generic": "Erro"
        },
        labelPosition: "Posição",
        labelSource: "Fonte de recrutamento",
        labelAdvancedConfig: "Configuração avançada",
        labelConcatUrl: "Parâmetros do url",
        labelUser: "Usuário",
        labelPassword: "Senha",
        labelUrl: "URL",
        placeholderPosition: "Selecione a posição",
        placeholderSource: "Selecione uma fonte",
        placeholderConcatUrl: "Introduzir os parâmetros do url",
        placeholderUser: "Insira o usuário",
        placeholderPassword: "Digite a senha",
        placeholderUrl: "Nome do cliente",
        placeholderKeyword: "Palavra-chave gerada",
        buttonGenerateKeyword: "Gerar palavra-chave",
        modalTitleNewIntegration: "Nova integração",
        modalTitleEditIntegration: "Editar integração",
        buttonCancel: "Cancelar",
        buttonSaveIntegration: "Salvar integração",
        buttonUpdateIntegration: "Atualizar integração",
        messageGenerateKeyword: "Agora para completar a integração necessitamos de atribuir uma palavra-chave que deverá incluir em todas as ofertas que publicar nesta fonte de recrutamento para esta posição. Por favor clique no botão para que esta palavra-chave lhe seja indicada",
        positionRequired: "Você deve selecionar uma posição",
        sourceRequired: "Você deve selecionar uma fonte",
        usertRequired: "Você deve adicionar um usuário",
        passwordRequired: "Você deve adicionar uma senha",
        urlRequired: "Você deve adicionar uma URL",
        generatedKeywordRequired: "É necessário gerar a palavra-chave para continuar",
        messageConfirmData: "Estes são os dados de integração que você acabou de registrar. Estão corretos?",
        messageConfirmDataEdit: "Estes são os dados de integração que você acabou de editar. Estão corretos?",
        buttonConfirmIntegration: "Sim, salvar integração",
        buttonCancelIntegration: "Não, cancelar",
        recoverPasswordEmail: "Recuperar senha",
        buttonConfirmDisabledIntegration: "Sim, desabilitar integração",
        buttonConfirmEnabledIntegration: "Sim, habilitar integração",
        confirmDisabledIntegration: "Você pode confirmar que deseja desabilitar a integração {position} {provider}?",
        confirmEnabledIntegration: "Você pode confirmar que deseja ativar a integração {position} {provider}?",
        successCreateIntegration: "Integração criada com sucesso",
        errorCreateIntegration: "Erro ao criar integração",
        errorUpdateIntegration: "Erro ao atualizar a integração",
        sucessoUpdateIntegration: "Integração atualizada com sucesso",
        integraçãoAlreadyExists: "A integração já existe!",
        successSendPasswordEmail: "Senha enviada com sucesso",
        errorSendPasswordEmail: "Erro ao enviar senha",
        titlePosition: "Posição:",
        titleSource: "Fonte de recrutamento:",
        titleConcatUrl: "Parâmetros do url:",
        titleUser: "Usuário:",
        titleKeyword: "Palavra-chave:",
        integrationDisabledSuccessfully: "Integração desabilitada com sucesso",
        integrationEnabledSuccessfully: "Integração habilitada com sucesso",
        urlRequirements: "A URL deve ter este formato: https://cliente.admin.epreselec.com/es/vacantes, substitua 'cliente' pelo nome do seu cliente",
        invalidUrl: "URL inválido",
        emailLabel: "E-mail",
        passwordLabel: "Senha",
        verifyPasswordUserPlatformToEditeIntegration: "Para editar a integração, verifique a senha do usuário da plataforma.",
        verifyButton: "Verificar",
        errorPasswordIncorrect: "Senha incorreta",
        sucessoPasswordVerified: "Senha verificada com sucesso",
        prefixUrl: "https://",
        sufixUrl: ".admin.epreselec.com/es/vacantes",
        sourceInfojobsPriority: "Infojobs",
        sourceEpreselecPriority: "ePreselec",
        sourceComputrabajoPriority: "Computrabajo",
        sourcePandapePriority: "Pandapé",
        sourceTurijobsPriority: "Turijobs",
      },

    dashboard: {
        filters: {
            division: 'Divisão',
            area: 'Área',
            subarea: 'Subárea',
            center: 'Centro',
            candidature: 'Posição',
        },
        no_data_available: "Não há dados disponíveis para o intervalo selecionado.",
        home: {
            title: "Painéis de estatísticas",
            create: "Criar novo painel",
            editDashboard: "Editar painel",
            saveDashboard: "Guardar painel",
            removeDashboard: "Eliminar painel",
            removeDashboardSuccess: "O painel foi retirado com êxito",
            removeDashboardError: "Erro ao eliminar o painel",
            changes_not_saved: "Tem alterações no painel. Guarde-as para não as perder",
            successfully_saved: "Alterações guardadas corretamente",
            error_saving: "Falha ao guardar as alterações",
            successfully_deleted: "Widget eliminado corretamente",
            error_deleting: "Erro ao eliminar o widget",
            deleteWidgetDialogTitle: "Tem a certeza de que pretende eliminar este widget?",
            deleteCancel: "Cancelar",
            deleteConfirm: "Confirmar",
            no_dashboards_created: "Não há painéis de estatísticas criados em sua conta. Uma vez que seu administrador crie os painéis, você poderá visualizá-los nesta seção."
        },
        weekdays: {
            "2": "Segunda-feira",
            "3": "Terça-feira",
            "4": "Quarta-feira",
            "5": "Quinta-feira",
            "6": "Sexta-feira",
            "7": "Sábado",
            "1": "Domingo"
        },
        pie: {
            "accepted": "Aceite",
            "not_accepted": "Não aceite",
            "rejected": "Recusado",
            "pending": "Pendente",
            "replan": "Replanejado",
            "tentative": "Provisório",
            "total": "Total",
            suitable: "Adequado",
            not_suitable: "Inadequado",

            gpp_manual: 'Manual',
            gpp_auto: 'Automático',
            gpp_gap: 'GAP',
            gpp_replacement: 'Substituição',
            gpp_pending: 'Pendentes',
            gpp_cancelled: 'Cancelados',
            gpp_rejected: 'Rejeitados',
            gpp_active: 'Em processo',
            gpp_empty: 'Pré-seleção manual pendente',
            gpp_inactive: 'Expirados',
            gpp_closed: 'Fechados',
            gender_male: 'Homens',
            gender_female: 'Mulheres',
            gender_undefined: 'Não determinado'
        },
        barchart: {
            "hour_of_day": ":00h",
            "interviews": " entrevistas",
        },
        funnel: {
            applied: "Registado",
            sent: "Enviado a",
            loaded: "Aberto",
            interviewed: "Entrevistados",
        },
        whatsapp: {
            bot_starter: "Convites de entrevista",
            citations: "Citações",
            offers: "Ofertas push",
            pulls: "Pull de candidatos"
        },
        features: {
            push_offer: "Ofertas push",
            doc_request: "Pedido de documentação",
            video_analysis: "Análise de vídeo",
            pull_config: "Seleção de candidatos",
            edit_fields: "Edição de campo",
            phases_status: "Fases e estados",
            evaluative_tests: "Testes de avaliação",
            vacancy_request: 'Gestão de pedidos de pessoal (GPP)',
            job_offer: 'Jobs'
        },
        tooltip: {
            ats_access_count: 'A sessão do usuário tem duração de um dia',
            candidate_experience_cnps: "O CNPS é calculado com base nos resultados do inquérito de satisfação dos candidatos.",
            average_time_to_hire: "Define o tempo médio em dias (:value:) em que um candidato é recrutado, indicando também o valor mínimo e máximo (:max:) de dias",
            gpp_average_time_to_hire: 'Estabelece a média de tempo em dias (:value:) em que um processo é fechado, indicando também o valor mínimo e máximo (:max:) de dias'
        },
        edit: {
            title: "Edição de painel:",
            save: "Salvar painel",
            saving: "Salvando...",
            saved: "Painel salvo com sucesso",
            renamed: "Painel atualizado com sucesso",
            removed: "Painel removido com sucesso",
            disable: "Excluir painel",
            viewDashboard: "Visualizar painel",
            renameDashboard: "Mudar o nome do painel",
            renameConfirm: "Guardar",
            removeDialogTitle: "Tem a certeza de que pretende eliminar este painel?",
            removeConfirm: "Confirmar",
            cancel: "Cancelar",
            errorRemoving: "Erro ao eliminar o painel",
            errorRenaming: "Falha ao mudar o nome do painel",
            placeholderRename: "Nome do painel",
            findWidgets: "Buscar widgets",
            addWidgets: "Selecione os widgets para o seu painel",
            alertHasUnsavedChanges: "Painel modificado: lembre-se de salvar para não perder suas alterações"
        },
        wizard: {
            title: "Criador de painéis",
            new_from_scratch: "Começar do zero",
            placeholder_custom_dashboard: "Introduza o nome do seu painel",
            create: "Criar",
            viewDashboards: "Voltar a",
            error_name: "Insira um nome para o novo painel",
            error_creating: "Ocorreu um erro ao criar o painel",
            copy_select_preconfigured_dashboard: "Escolha um dos painéis de estatísticas concebidos pela fábrica de bots hr:",
            copy_select_custom_dashboard: "Ou, se preferir, crie um painel personalizado com os widgets que mais lhe interessam.:",
            name: {
                virtual_evaluation_asistant: "Avaliação de assistentes virtuais",
                selection_pipeline: "Pipeline de seleção",
                recruitment_source: "Fontes de recrutamento",
                candidates_comunication: "Comunicação aos candidatos",
                hired_services: "Serviços contratados",
                conversion: "Conversão",
                citation: "Citação",
                user_activities: "Atividade do utilizador",
            },
            description: {
                virtual_evaluation_asistant: "Visão geral pormenorizada de várias métricas e estatísticas importantes relacionadas com a interação e a eficácia",
                selection_pipeline: "Representação visual de todas as fases do processo de contratação",
                recruitment_source: "Painel de controlo que fornece uma visão detalhada das várias fontes de recrutamento utilizadas, desde portais de emprego a redes sociais e referências internas",
                candidates_comunication: "Proporciona uma visão completa de todas as interacções e comunicações com os candidatos",
                hired_services: "Informações sobre os serviços contratados e as funcionalidades activas",
                conversion: "Detalhes das conversões e registos no ATS",
                citation: "Ver as citações e as taxas de resposta e de participação dos candidatos",
                user_activities: "Métricas internas sobre estatísticas e atividade dos utilizadores no ATS",
            }
        },
        widget_groups: {
            status_phases: 'Pipeline de seleção',
            virtual_assistant_evaluation: 'Avaliação de assistentes virtuais',
            recruitment_sources: 'Fontes de recrutamento',
            candidates_communication: 'Comunicação aos candidatos',
            features_enabled: 'Serviços contratados',
            conversion: 'Conversão',
            citation: 'Citação',
            user_activity: 'Atividade do utilizador',
            ai_generative_evaluation: 'Avaliação generativa da ia',
            publish_offers: 'Publicação de ofertas',
            workflow: 'Workflow',
            vacant_management: 'Gestão de vagas',
            all: 'Ver tudo',
        },
        widgets: {
            interviewed_candidates: 'Nº de candidaturas recebidas',
            candidates_by_recruitment_source: 'Candidatos avaliados por fonte de recrutamento',
            candidates_per_state: 'Nº de candidatos por estado',
            citations_accepted_percentage_per_position: 'Citações aceitas por posição',
            candidates_per_region: 'Distribuição geográfica dos candidatos',
            candidates_by_recruitment_source_absolute: 'Candidatos avaliados por fonte de recrutamento',
            average_time_to_hire: 'Tempo médio para contratação',
            suitable_vs_interviewed: "Aptos vs Entrevistados",
            interested_offer_candidates: "Interessados em ofertas",
            updated_candidates: "Candidatos atualizados",
            candidates_by_week_activity: "Atividade de candidatos entrevistados",
            candidatures: "Candidaturas",
            whatsapp_consume_by_service: "Consumo de WhatsApp por serviço",
            whatsapp_consumption: "Uso de WhatsApp",
            candidates_per_phase: "Candidatos por fase",
            applied_vs_hired_per_position: "Candidatos registados vs. recrutados por função",
            interviewed_candidates_per_position: "Candidatos entrevistados por cargo",
            push_offers_total: "Ofertas push lançadas",
            push_offers_candidates_notified: "Ofertas push notificadas aos candidatos",
            pull_candidates_notified: "Candidatos pull notificados",
            hired_vs_applied_percentage: "Candidatos recrutados vs. registados",
            total_sms_and_whatsapp_sent: "N.º de SMS/Whatsapp enviados",
            total_email_sent: "N.º de mensagens de correio eletrónico enviadas",
            candidates_with_citation_sent: "N.º de candidatos convocados para entrevista",
            average_time_between_citation_and_interview: "Tempo médio entre o envio da convocatória e a entrevista",
            hired_per_recruitment_source: "Candidatos recrutados por fonte de recrutamento",
            features_enabled_check: "Funcionalidades recrutadas",
            suitable_per_recruitment_source: "Candidatos aptos para a entrevista pela fonte de recrutamento",
            communication_methods_enabled: "Canais de comunicação com os candidatos",
            candidates_per_region_spain: "Candidatos por cidade (Espanha)",
            registered_vs_suitable_by_recruitment_source: "Conversão elegíveis vs. entrevistados por F. Recrutamento",
            suitable_vs_no_suitable: "Candidatos elegíveis vs. não elegíveis",
            suitable_vs_registered: "Candidatos elegíveis vs. registados",
            citations_response_pct: "Percentagem de resposta a citações",
            citations_accepted_pct: "Percentagem de citações aceites",
            pull_interested_percentage: "Ofertas pull interessadas por oferta",
            push_interested_percentage: "Ofertas push interessadas por oferta",
            candidate_experience_cnps: "Satisfação com a experiência do candidato (CNPS)",
            interviews_by_hour_of_day: "Entrevistas por hora do dia",
            interviews_by_hour_of_day_pie: "Entrevistas por hora do dia (circular)",
            average_candidate_experience: "Satisfação com a experiência do candidato (CNPS)",
            average_phase_time: "Tempo médio por fase",
            ats_access_count: "Número de acessos ao ATS",
            ats_unique_access_count: "Usuários com atividade no ATS em relação ao total",
            ats_access_count_ranking: "Ranking dos utilizadores com mais acessos",
            ats_most_browsed_sections: "Secções mais visitadas",
            ats_status_changed_count: "Alterações de estado por utilizador",
            whatsapp_voucher_consumed: "Vouchers Whatsapp consumidos",
            funnel_applied_sent_opened_interviewed_by_sms_what: "Funil (subscrito, enviado, aberto, entrevistado) por Whatsapp/SMS",
            applied_vs_interviewed_conversion_sms_whatsapp: "Número de Registados vs. entrevistados por Whatsapp/SMS",
            funnel_applied_sent_opened_interviewed_by_email: "Funil (inscritos, enviados, abertos, entrevistados) por e-mail",
            applied_vs_interviewed_conversion_email: "Número de Inscritos vs. entrevistados por e-mail",
            funnel_applied_sent_opened_interviewed_by_unique: "Funil (subscritores, enviados, abertos, entrevistados) único",
            applied_vs_interviewed_conversion_unique: "Número de Inscritos vs. entrevistados únicos",
            conversion_by_candidature: "Taxa de conversão por candidatura",
            suitable_vs_not_suitable_pie: "Adequado vs. inadequado",
            conversion_by_recruitment_source: "Taxa de conversão por fonte de recrutamento",
            people_by_client: 'Candidatos únicos',
            top_churns_by_chatbot: 'Principais pontos de churn por chatbot',
            gpp_created_process: 'Processos criados',
            gpp_processes_summary: 'Situação dos processos',
            gpp_processes_by_type: 'Processos cobertos automaticamente e manualmente',
            gpp_processes_by_reason: 'Processos solicitados por motivo',
            gpp_average_candidates_per_process: 'Média de candidatos sugeridos por vaga',
            gpp_uncovered_processes_automatically: 'Processos cobertos manualmente',
            gpp_requests_by_user: 'Solicitações por solicitante',
            gpp_requests_by_validator: 'Solicitações por validador',
            gpp_requests_by_area: 'Solicitações por área',
            gpp_candidates_by_gender: 'Candidatos sugeridos por gênero',
            gpp_time_to_hire_global: 'Tempo de contratação global',
            gpp_time_to_hire_by_process: 'Tempo de contratação por processo',
            gpp_lifetime_processes: 'Tempo médio de gestão por candidato',
            gpp_average_management_time_by_user: 'Tiempo medio de gestión por solicitante',
            hired_candidates_by_gender: "Candidatos contratados por gênero",
            female_candidates_per_phase: "Candidatas por fase",
            female_candidates_per_selection_status: "Candidatas por status",
            male_candidates_per_phase: "Candidatos por fase",
            male_candidates_per_selection_status: "Candidatos por status",
            average_positions_per_candidate: "Média de candidaturas por pessoa",
            tableColumns: {
                phase: 'Fase',
                state: 'Estado',
                offer: 'Oferta',
                notified: 'Notificados',
                interested: 'Interessados',
                position: "Posição",
                hired: "Contratado",
                applied: "Registado",
                feature: "Funcionalidade",
                enabled: "Ativo",
                candidates: "Candidatos",
                recruitment_source: "Origem",
                activity: "Atividade",
                interviews: "Entrevistas",
                hours: "Hora do dia",
                method: "Método",
                accepted: "Aceite",
                percentage: "%",
                email: "Utilizador",
                total: "Acessos",
                chatbot: "Chatbot",
                chatbot_node: "Nó",
                chatbot_node_text: "Pergunta",
                sessions: "Sessões totais",
                churn: "Número de abandono",
                churn_pct: "Percentual de abandono",

                uncovered_position: 'Posição',
                uncovered_center: 'Centro',
                num_uncovered: 'Processos não preenchidos',
                num_covered_manually: 'Número de processos',

                gpp_solicitor: 'Requerente',
                gpp_solicitor_name: 'Requerente',
                gpp_cp_name: 'Centro de trabalho',
                gpp_cs_position: 'Posição',
                gpp_vr_name: 'Nome do processo',
                gpp_vr_closed_time: 'Dias de gestão',
                gpp_vr_avg_closed_time: 'Média de gestão (dias)',
                gpp_num_requests: 'Processos criados',
                gpp_pending_pct: 'Pendentes aprovação',
                gpp_cancelled_pct: 'Cancelados',
                gpp_rejected_pct: 'Rejeitados',
                gpp_active_pct: 'Em processo',
                gpp_empty_pct: 'Pré-seleção manual pendente',
                gpp_inactive_pct: 'Expirados',
                gpp_closed_pct: 'Fechados',

                gpp_pending: 'Pendentes aprovação',
                gpp_cancelled: 'Cancelados',
                gpp_rejected: 'Rejeitados',
                gpp_active: 'Em processo',
                gpp_empty: 'Pré-seleção manual pendente',
                gpp_inactive: 'Expirados',
                gpp_closed: 'Fechados',

                gpp_validator: 'Validador',
                gpp_num_approved: 'Aprovados',
                gpp_num_rejected: 'Rejeitados',

                gpp_division: 'Divisão',
                gpp_area: 'Área',
                gpp_subarea: 'Subárea',
                gpp_unit: 'Centro',
                gpp_position: 'Posição',
                time_to_hire: 'Número de dias para fechar o processo'
            },
        },
    },
    templateRequestManager: {
        titleSection: "Gerenciador de solicitação de modelo",
        descriptionSection: "Criar, centralizar e organizar solicitações de centros de trabalho de maneira ágil, rápida e ordenada para gerenciar necessidades de seleção.",
        createProcess: "Criar processo",
        filterProcess: "Processo de filtro",
        unit: "Unidade",
        dateCreated: "Data de criação",
        dateIncorporation: "Data de constituição",
        position: "Posição",
        WorkingDay: "Dia",
        shift: "Mudança",
        typeContract: "Tipo de contrato",
        vacancies: "Vagas",
        reason: 'Razão',
        email: 'E-mail',
        address: 'Endereço',
        lastDisplayDateAt: 'Visto pela última vez',
        lastCandidatureChangeStatusDateAt: 'Última mudança de status',
        proposedCandidates: "Candidatos propostos",
        interviewedCandidates: "Candidatos entrevistados",
        statusProcess: "Status do processo",
        processOpened: "Processos",
        buttonOpenedProcess: "Abrir",
        buttonClosedProcess: "Fechado",
        newProcess: "Novo processo",
        descriptionNewProcess: "Iniciar um novo processo. Personalize aspectos cruciais como horários, requisitos e tipo de contrato, entre outros. Defina critérios que ajudarão a identificar candidatos adequados para este processo seletivo.",
        backToTemplateRequestManager: "Voltar ao gerenciador de solicitações de modelo",
        placeholderPosition: "Selecione a posição",
        placeholderTypeContract: "Selecione o tipo de contrato",
        placeholderWorkplace: "Selecione o centro de trabalho",
        placeholderShift: "Selecione o turno",
        placeholderRequirementsRequired: "Indica os requisitos necessários",
        placeholderRequirementsDesirable: 'Indica os requisitos desejáveis',
        placeholderObservations: 'Indique suas observações',
        workplace: "Centro de trabalho",
        requeriments: "Requisitos",
        desirableRequirements: 'Requisitos deseáveis',
        mandatoryRequirements: 'Requisitos imprescindíveis',
        observations: "Observações",
        saveProcess: "Salvar",
        numberVacancies: "Número de vagas",
        titleSectionValidator: "Validação do processo",
        descriptionSectionValidator: "Valida os processos de seleção que foram criados pelos manipuladores de solicitação de modelo. Revisa os detalhes de cada processo e aprova ou rejeita a solicitação.",
        statusProcessValidator: "Status do processo",
        typeContractIndefinite: "Indefinido",
        typeContractTemporary: "Temporário",
        typeContractInterim: "Provisório",
        WorkingDayFullTime: "Completo",
        WorkingDayPartTime: "Parte",
        WorkingDayHourly: "Por hora",
        shiftMorning: "Amanhã",
        shiftAfternoon: "Tarde",
        shiftNight: "Noite",
        shiftWeekends: "Fim de semana",
        vacancyStates: {
          cancelled: 'Processo cancelado',
          rejected: 'Processo rejeitado',
          active: 'Processo aprovado',
          closed: 'Processo fechado',
          pending: 'Aprovação pendente',
          empty: 'Pré-seleção manual pendente',
          inactive: 'Processo expirou'
        },
        vacancyReasons: {
            gap: 'GAP',
            replacement: 'Substituição',
        },
        vacancyContract: {
          1: 'Contrato permanente',
          2: 'Contrato temporário'
        },
        workingDayLabel: "Jornada",
        placeholderWorkingDay: "Selecione a jornada",
        workingDay: {
            full_time: "Integral",
            part_time: "Parcial",
            weekend: "Fins de semana"
        },
        workShiftLabel: "Turno",
        placeholderWorkShift: "Selecione o turno",
        workShift: {
            morning_shift: "Manhã",
            swing_shift: "Tarde",
            night_shift: "Noite",
            rotating_shift: "Rotativo",
            split_shift: "Revezado",
            full_shift: "Completo"
        },
        contractHoursLabel: "Horas/semana",
        placeholderContractHours: "Selecione as horas por semana",
        contractHours: {
            40: "40 horas semanais",
            37: "37 horas semanais",
            35: "35 horas semanais",
            30: "30 horas semanais",
            20: "20 horas semanais",
            16: "16 horas semanais",
            15: "15 horas semanais",
            12: "12 horas semanais",
            10: "10 horas semanais",
            8: "8 horas semanais"
        },
        searchMode: 'União',
        searchModes: {
            direct: 'Atribuição direta',
            algorithm: 'Algoritmo',
            repush: 'Declínio'
        },
        actions: 'Ações',
        acceptProcess: 'Aceitar processo',
        rejectProcess: 'Processo de rejeição',
        cancelProcess: 'Cancelar processo',
        cancelProcessTooltip: 'Caso não queira continuar com o processo, o cancelamento do mesmo provocará a libertação dos candidatos e o encerramento do processo.',
        closeProcess: 'Finalizar processo',
        closeProcessTooltip: 'Caso o processo tenha chegado ao fim com a obtenção das contratações desejadas, o encerramento do processo fará com que o mesmo seja concluído e não poderá ser reaberto.',
        repushCandidatesTooltip: 'Preencha o processo com novos candidatos encontrados até que o processo esteja concluído.',
        repushCandidates: 'Preencher candidatos',
        repushCandidatesSuccess: 'A solicitação de procura de novos candidatos foi executada com sucesso',
        repushCandidatesFullfilled: 'O modelo de candidatura já tem o número máximo de candidatos permitidos.'
    },
    "IAGen": {
        "criterias": {
            "experience": "Experiência",
            "EXPERIENCIA": "Experiência",

            "education_training": "Educação / Formação",
            "ESTUDIOS": "Educação / Formação",

            "salary_expectation": "Expectativas salariais",
            "SALARIO": "Expectativas salariais",

            "employment_status": "Situação laboral",
            "SITUACION_LABORAL": "Situação laboral",

            "location": "Localização",
            "UBICACION": "Localização",

            "mobility": "Mobilidade",
            "MOVILIDAD": "Mobilidade",

            "availability": "Disponibilidade",
            "DISPONIBILIDAD": "Disponibilidade",

            "others": "Outros",

            "not_evaluated": "Não avaliado"
        }
    }
}
