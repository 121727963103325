import React, { useEffect, useState, useMemo } from 'react'
import { InputField, SpinnerIcon } from '../../atoms'

import { useDashboardInfo, useAvailableWidgets, useDashboardWidgets } from '../../../api/queries/useDashboard'
import { useAppContext } from '../../../context/appContext'
import { useParams } from 'react-router'

import { DashboardEditStyled } from './styled'
import Grid from '@material-ui/core/Grid'
import { PageTitle } from '../../atoms'
import { DashboardPresetItem } from '../../molecules'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  Snackbar
} from '@material-ui/core'
import { AuthUtils } from '../../../redux/auth'
import { MODULES, QUERY_ACTIONS, QUERY_KEYS, ROLES } from '../../../config/constants'
import { useMutation } from '@tanstack/react-query'
import { removePanel, renamePanel, updateDashboardWidgets } from '../../../api'
import Divider from '@material-ui/core/Divider'
import i18n from '../../../assets/i18n'
import routes from '../../../config/routes'
import {useHistory} from "react-router-dom";
import {manageQueriesWithPrefix} from "../../../api/mutations/mutationCallbacks";
import { DataUsage, DeleteForever, Edit, Save } from '@material-ui/icons'
import Button from '@material-ui/core/Button'
import { removeQueriesByKeys } from '../../../api/mutations/mutationCallbacks'
const DashboardEdit = () => {

  const [dashboardTitle, setDashboardTitle] = useState(i18n.dashboard.edit.title);
  const [hasDashboardChanged, setHasDashboardChanged] = useState(false);
  const [originalWidgets, setOriginalWidgets] = useState([]);
  const [selectedWidgets, setSelectedWidgets] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [inputFilterValue, setInputFilterValue] = useState('');
  const [filteredWidgets, setFilteredWidgets] = useState([]);

  const [backendInProgress, setBackendInProgress] = useState(false);
  const [savingInProgress, setSavingInProgress] = useState(false);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [renameDialogOpen, setRenameDialogOpen] = useState(false);
  const [newDashboardName, setNewDashboardName] = useState('');

  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState({ message: '', severity: '' });

  const {id} = useParams()
  let {module} = useAppContext()
  module = module || MODULES.ID_TALENT_ACQUISITION;
  const history = useHistory();

  const redirectToHome = () => {
    history.push(routes.dashboardHome.replace(':module_id', module).replace(':id', ''));
  }

  const isAdminUser = AuthUtils.hasRole(ROLES.ROLE_ATS_ADMIN_talent_acquisition) || AuthUtils.hasRole(ROLES.ROLE_ATS_ADMIN_ROOT);
  if(!isAdminUser) {
    redirectToHome();
  }

  const {data: dashboardInfo, isLoading: isLoadingInfo } = useDashboardInfo(module, id)
  const {data: availableWidgets } = useAvailableWidgets(module)
  const {data: dashboardWidgets } = useDashboardWidgets(module, id)

  useEffect(() => {
    if(!isLoadingInfo) {
      if(!dashboardInfo || !dashboardInfo.is_custom) {
        redirectToHome();
      } else {
        setDashboardTitle(`${i18n.dashboard.edit.title} ${dashboardInfo.name}`)
        setNewDashboardName(dashboardInfo.name);
      }
    }
  }, [isLoadingInfo]);

  useEffect(() => {
    if(availableWidgets) {
      availableWidgets.map(widget => {
        widget.localized_name = i18n.dashboard.widgets[widget.name] || widget.name

        return widget;
      });

      setFilteredWidgets(availableWidgets || []);
    }
  }, [availableWidgets])

  useEffect(() => {
    if(dashboardWidgets) {
      setOriginalWidgets(() =>
        dashboardWidgets.map(data => {
          return {
            id: data.widget.id,
            params: data.params || {}
          }
        })
      );

      setSelectedWidgets(() =>
        dashboardWidgets.map(data => {
          return {
            id: data.widget.id,
            params: data.params || {}
          }
        })
      );
    }
  }, [dashboardWidgets])

  useEffect(() => {
    if(availableWidgets) {
      setFilteredWidgets(availableWidgets.filter(widget => {
        if(!selectedGroupId) {
          return true;
        }

        return widget.widget_group?.id === selectedGroupId;
      }));
    }
  }, [selectedGroupId])

  useEffect(() => {
    setHasDashboardChanged(!(JSON.stringify(originalWidgets) === JSON.stringify(selectedWidgets) || !selectedWidgets.length));
  }, [selectedWidgets, originalWidgets])


  const handleWidgetClick = (widgetId) => {
    setSelectedWidgets((prevWidgets) =>
      prevWidgets.find(item => item.id === widgetId)
        ? prevWidgets.filter((item) => item.id !== widgetId)
        : [...prevWidgets, { id: widgetId, params: {} }]
    );
  }

  const onFilterInputChange = (value) => {
    setInputFilterValue(value);

    if(!value || value.trim === '') {
      setFilteredWidgets(availableWidgets);
      return;
    }

    setSelectedGroupId(null);
    value = value.toLowerCase();

    setFilteredWidgets(availableWidgets.filter(widget => {
      return widget.localized_name.toLowerCase().indexOf(value) > -1 || widget.description.toLowerCase().indexOf(value) > -1
    }));
  }
  const onRenameInputChange = (value) => {
    setNewDashboardName(value)
    if(!value || value.trim === '') {
      return;
    }
  }

  const WidgetSidebar = ({ widgets = [], onGroupClick }) => {
    const groups = {};
    const totals = {};
    widgets.forEach(widget => {
      if(widget.widget_group) {
        if(!totals[widget.widget_group.keyword]) {
          totals[widget.widget_group.keyword] = 0;
        }

        totals[widget.widget_group.keyword]++;

        groups[widget.widget_group.keyword] = {
          total: totals[widget.widget_group.keyword],
          ...widget.widget_group
        };
      }
    });

    return (
      <List component="nav">
        {Object.values(groups).map(group => {
          return <ListItem key={group.id} onClick={() => onGroupClick(group.id)} selected={selectedGroupId === group.id} className="widgets-sidebar-item">
            <ListItemText primary={i18n.dashboard.widget_groups[group.keyword] + ` (${group.total})`} />
          </ListItem>
        })}
        <Divider className="widgets-list-divider" />

        <ListItem key='all' onClick={() => onGroupClick(null)} selected={selectedGroupId === null} className="widgets-sidebar-item">
          <ListItemText primary={i18n.dashboard.widget_groups.all} />
        </ListItem>
      </List>
    );
  };

  const handleGroupClick = (groupId) => {
    setSelectedGroupId((prevGroupId) => prevGroupId === groupId ? null : groupId);
    setInputFilterValue('');
  }

  const displayWidgetList = useMemo(() => {
    if(!filteredWidgets || filteredWidgets.length === 0) {
      return <></>;
    }


    return filteredWidgets.map((widget) => {
      if(!widget.widget_group) {
        return <></>
      }
      return (
        <Grid key={widget.id} item xs={6} md={4}>
          <DashboardPresetItem
            className={selectedWidgets.find(item => item.id === widget.id) ? ['selected'] : ['default']}
            id={widget.id}
            preview={widget.preview_url}
            name={widget.localized_name}
            description={widget.description}
            handleOnClick={handleWidgetClick}
          />
        </Grid>
      )
    })
  }, [filteredWidgets, selectedWidgets]);

  const updateDashboardMutation = useMutation(async ({ widgets, module, id }) => {
      return await updateDashboardWidgets(widgets, module, id)
        .then(() => {
          displayAlert({ message: i18n.dashboard.edit.saved, severity: 'success' });
          setOriginalWidgets([...selectedWidgets]);

          setSavingInProgress(false);
          setBackendInProgress(false);

          manageQueriesWithPrefix([
            `${module}:${QUERY_KEYS.DASHBOARDS}:data:${id}`,
            `${module}:${QUERY_KEYS.DASHBOARDS}:widgets:${id}`
          ], QUERY_ACTIONS.REMOVE);
        });
    }
  );

  const displayAlert = (data, callback = null) => {
    setAlert(data);
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false)

      callback && callback();
    }, 3500);
  }

  const handleSaveDashboard = () => {
    if(savingInProgress) {
      return;
    }

    setSavingInProgress(true);
    setBackendInProgress(true);

    updateDashboardMutation.mutate({
      widgets: selectedWidgets,
      module: module,
      id: id
    });
  }

  const handleRemoveDashboard = () => {
    if(backendInProgress) {
      return;
    }

    setBackendInProgress(true);
    removePanel(module, id).then((res) => {
      if (res && res.success) {
        removeQueriesByKeys( [`${module}:${QUERY_KEYS.DASHBOARDS}`]);
        setRemoveDialogOpen(false);

        displayAlert({message: i18n.dashboard.edit.removed, severity: "success"}, () => {
          history.push(routes.dashboardHome.replace(':module_id', module).replace(':id', ''))
        });
      }
    }).catch((e) => {
      console.error(e)
      displayAlert({message: i18n.dashboard.edit.errorRemoving, severity: "error"});
      setBackendInProgress(false);
      setRemoveDialogOpen(false);
    });
  }

  const handleRenameDashboard = () => {
    if(backendInProgress) {
      return;
    }

    setBackendInProgress(true);
    renamePanel(module, id, newDashboardName).then((res) => {
      if (res && res.data && res.data.success) {
        removeQueriesByKeys( [`${module}:${QUERY_KEYS.DASHBOARDS}`]);
        dashboardInfo.name = newDashboardName;
        setDashboardTitle(`${i18n.dashboard.edit.title} ${newDashboardName}`)

        displayAlert({ message: i18n.dashboard.edit.renamed, severity: 'success' });
        setRenameDialogOpen(false);
        setBackendInProgress(false);
      }
    }).catch((e) => {
      console.error(e)
      setBackendInProgress(false);
      setRenameDialogOpen(false);
      displayAlert({message: i18n.dashboard.edit.errorRenaming, severity: "error"});
    });
  }

  const handleClickRemove = () => {
    setRemoveDialogOpen(true);
  }
  const handleCloseRemoveDialog = () => {
    setRemoveDialogOpen(false);
  }

  const handleClickRename = () => {
    setRenameDialogOpen(true);
  }
  const handleCloseRenameDialog = () => {
    setRenameDialogOpen(false);
  }

  return (
    <DashboardEditStyled>
      <div>
        <Snackbar
          className="alert-unsaved-changes"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          message={i18n.dashboard.edit.alertHasUnsavedChanges}
          open={hasDashboardChanged}
          key="unsavedChanges"
        />

        <Snackbar
          open={showAlert}
          key="custom-alert"
          className={alert.severity}
          message={alert.message}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
        </Snackbar>
      </div>

      <Grid container className="title-container" justifyContent='space-between' alignItems='center'>
        <Grid item xs={12} sm={6} className="title">
          <PageTitle title={dashboardTitle} />
        </Grid>
        <div style={{display: "flex", gap: 16}}>
          <Button
            onClick={() => { history.push(routes.dashboardHome.replace(':module_id', module).replace(':id?', id)) }}
            color={'secondary'}
            startIcon={<DataUsage />}
          >
            {i18n.dashboard.edit.viewDashboard}
          </Button>

          <Button
            onClick={handleClickRename}
            variant="outlined"
            startIcon={<Edit/>}
          >
            {i18n.dashboard.edit.renameDashboard}
          </Button>
          <Dialog
            open={renameDialogOpen}
            onClose={handleCloseRenameDialog}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle>{i18n.dashboard.edit.renameDashboard}</DialogTitle>
            <DialogContent>
              <InputField
                placeholder={i18n.dashboard.edit.placeholderRename}
                value={newDashboardName}
                handleOnChange={onRenameInputChange}
                key="rename-dashboard"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseRenameDialog} color="primary">
                {i18n.dashboard.edit.cancel}
              </Button>
              <Button onClick={handleRenameDashboard} color="primary" autoFocus disabled={backendInProgress || (dashboardInfo && dashboardInfo.name === newDashboardName)}>
                {i18n.dashboard.edit.renameConfirm}
              </Button>
            </DialogActions>
          </Dialog>


          <Button
            onClick={handleClickRemove}
            variant="outlined"
            disabled={backendInProgress}
            startIcon={<DeleteForever/>}
            color={'secondary'}
          >
            {i18n.dashboard.edit.disable}
          </Button>
          <Dialog
            open={removeDialogOpen}
            onClose={handleCloseRemoveDialog}
          >
            <DialogTitle>{i18n.dashboard.edit.removeDialogTitle}</DialogTitle>
            <DialogActions>
              <Button onClick={handleCloseRemoveDialog} color="primary">
                {i18n.dashboard.edit.cancel}
              </Button>
              <Button onClick={handleRemoveDashboard} color="primary" autoFocus disabled={backendInProgress}>
                {i18n.dashboard.edit.removeConfirm}
              </Button>
            </DialogActions>
          </Dialog>

          <Button
            onClick={handleSaveDashboard}
            variant="contained"
            disabled={!hasDashboardChanged || backendInProgress}
            startIcon={<Save/>}
            color={'primary'}
          >
            {savingInProgress ? i18n.dashboard.edit.saving : i18n.dashboard.edit.save}
          </Button>
        </div>
      </Grid>

      <Grid container spacing={6}>
        <Grid item xs={12} style={{marginBottom: '30px'}}>
          <Divider />
        </Grid>
      </Grid>

      <Grid container spacing={6} className="main-wrapper">
        <Grid item xs={12} sm={3} className="widgets-sidebar-wrapper">
          <WidgetSidebar widgets={availableWidgets} onGroupClick={handleGroupClick} />
        </Grid>

        <Grid item container xs={12} sm={9} className="widgets-list-wrapper" justifyContent='space-between' alignItems='flex-start'>
          <Grid item container spacing={4}>
            <Grid item container style={{borderBottom: '1px solid #ddd'}}>
              <Grid container item xs={12}>
                <Grid item xs={9}>
                  <h3>{i18n.dashboard.edit.addWidgets}</h3>
                </Grid>

                <Grid item xs={3}>
                  <InputField
                    style={{maxWidth: 420}}
                    placeholder={i18n.dashboard.edit.findWidgets}
                    defaultValue={inputFilterValue}
                    handleOnChange={onFilterInputChange}
                    key="filter-widgets"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item container className="widgets-list">
              <Grid item container spacing={4}>
                {isLoadingInfo?
                  <Grid item xs={12}><SpinnerIcon/></Grid>
                  :
                  displayWidgetList
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </DashboardEditStyled>
  )
};


export default DashboardEdit
