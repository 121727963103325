export default {
  generalError: 'An error has occurred',
  update: 'Update',
  showMore: 'Show more +',
  showLess: 'Show less -',
  markAs: 'Mark as',

  genericText: {},

  modalGeneric: {
    cancel: 'Cancel',
    send: 'Send'
  },

  //Atoms
  avatarMatch: {
    match: 'Match'
  },

  regularButton: {
    apply: 'Apply'
  },

  regularSelect: {
    select: 'Select'
  },

  modalHeader: {
    planning: 'Planning'
  },

  //Molecules
  modalDeleteUser: {
    removeUser: 'Remove',
    enterCandidateName: 'To confirm enter the candidate name',
    writeCandidateName: 'Enter the candidate name',
    filterProfiles: 'Filter candidates',
    remove: 'Remove'
  },

  modalUpdateBlacklist: {
    addToBlacklist: 'Add to blacklist: ',
    removeFromBlacklist: 'Remove from blacklist: ',
    enterCandidateName: 'To confirm enter the candidate name',
    writeCandidateName: 'Enter the candidate name',
    filterProfiles: 'Filter candidates',
    add: 'Add',
    remove: 'Remove'
  },

  modalChangeStatus: {
    changeStatus: 'Change status',
    selectStatus: 'Select a status',
    selectOffer: 'Select an offer',
    incomplete_form: 'You need to select the state and the offer to be able to perform this action',
    select: 'Select',
    suitable: 'Eligible',
    not_suitable: 'Ineligible',
    not_evaluated: 'Not evaluated',
    to_be_confirmed: 'To be confirmed',
    confirmed: 'Confirmed',
    not_interested: 'Not interested',
    reschedule: 'Reschedule',
    hired: 'Hired',
    booking: 'On hold',
    not_attend: 'Not attend',
    cited_phase2: 'Scheduled stage 2',
    discarded: 'Rejected',
    descarded_phase2: 'Rejected stage 2',
    not_interested_phase2: 'Not interested stage 2',
    not_attend_phase2: 'Not attend stage 2',
    pending_update: 'Pending update',
    in_process: 'In process',
    hiredDate: 'Start date',
    in_progress: 'In progress',
    doc_required: 'Doc required',
    doc_received: 'Doc received'
  },

  modalChangeTags: {
    title: 'Change labels',
    select: 'Update'
  },

  favoritesTable: {
    date: 'Date',
    markAsRead: 'Mark as read',
    changeState: 'Change status',
    markFavorite: 'Marck favorite',
    removeFavorites: 'Remove favorites',
    fav: 'Favorite',
    export: 'Export',
    profile: 'Scoring profile',
    skills: 'Scoring skills',
    matching: 'Matching',
    status: 'Status',
    evaluation: 'Evaluation',
    personalData: 'Personal data',
    candidature: 'Candidature'
  },

  favoritesProcessTable: {
    title: 'Process favourites'
  },

  favoritesOnboardingTable: {
    title: 'Onboarding favourites'
  },

  processesTable: {
    title: 'Inbox Processes',
    id: 'Process',
    offer: 'Send offer',
    assignProcess: 'Assign to process',
    name: 'Name and surname',
    prominent: 'Starred',
    date: 'Date and time',
    department: 'Department',
    category: 'Category',
    type: 'Type',
    contact: 'Email',
    tel: 'Phone',
    status: 'Status',
    markFavorite: 'Mark as favorite',
    removeFavorites: 'Remove as favorite',
    print: 'Print',
    markAsRead: 'Mark as read',
    unmarkAsRead: 'Unmark as read',
    filterProcesses: 'Filter processes',
    changeStatus: 'Change status',
    changeTags: 'Change tags',
    export: 'Export',
    all: 'All',
    offerCandidatureState: 'Interest',
    talent_acquisition: 'Selected candidatures',
    on_board: 'Selected employees',
    welcome: 'Selected employees',
    employee_line: 'Selected employees',
    feedback: 'Selected employees',
    exit_interview: 'Selected employeess',
    offer_sent: 'Offers sent',
    statusChanged: 'Status changed successfully'
  },

  offerModal: {
    title: 'Create push offer',
    name: 'Offer Name',
    name_placeholder: 'Delivery man London',
    description: 'Description',
    description_placeholder: 'We need a person who has 2 years of experience and with a vehicle for the London area',
    date_range: 'Offer start/end date',
    areUSure: {
      description: 'You are going to notify {num} person(s) about this offer, are you sure?',
      sendCompleted: 'Offer Sent'
    }
  },

  assignJobOfferModal: {
    title: "Assign offer",
    offer: "Offer",
    offer_placeholder: "Select the offer you want to assign",
    button_title: "Assign offer",
    assign: "Assign",
    successfully_assigned: "Candidates have been successfully assigned to the specified offer",
    areUSure: {
      description: "You are going to assign {num} candidate/s to this offer, are you sure?",
      sendCompleted: "Offer assigned"
    }
  },

  assignedModal: {
    title: 'Assign to process',
    assign: 'Assign',
    subModalTitle: 'Do you confirm the assignment to process?',
    subModalSubTitle: 'Candidacies:',
    subModalSubDescription: 'Selected process:',
    latest: 'Latest',
    placeholder: 'Search by process name'
  },

  process: {
    title: 'Processes',
    category: 'Category',
    department: 'Department',
    service: 'Service',
    process: 'Process',
    comments: 'Comments',
    date: 'Date',
    time: 'Time',
    comment: 'Comments',
    author: 'Author',
    documentation: 'Files attached',
    previewCV: 'Candidate CV',
    downloadDocs: 'Download docs',
    deleteDocs: 'Delete selection',
    uploadDocs: 'Upload docs',
    createComment: 'Create comment',
    noPreview: 'Preview not disable',
    text: 'Text',
    commentName: 'Comment',
    dayHourTransaction: 'Transaction date and hour',
    area: 'Area',
    centerProvince: 'Province Center',
    centerType: 'Center Type',
    statesTypes: 'Suggestion by states',
    registerData: 'Registration date',
    noDocuments: 'No documents',
    modifiedStatus: 'Modified status',
    deleteSuccess: 'Process removed',
    deleteModalTitle: '¡Caution!',
    deleteModalText: 'Are you sure you want to delete this item?',
    remove: 'Delete',
    previewVideo: 'Videos'
  },

  modalAnnouncement: {
    quoteCall: 'Schedule a meeting',
    editQuoteCall: 'Meeting',
    date: 'Send date',
    rejected: 'Rejected',
    citationStatus: 'Response',
    citationDate: 'Citation date',
    citationHour: 'Citation hour',
    interviewer: 'Contact',
    location: 'Location',
    select: 'Select',
    announcement_details: 'Details',
    writeDetails: 'Specify the details of the citation',
    writeDetailsIntegration: "Specify the details of the citation (Won't be possible to edit it later)",
    body: 'Body',
    writeBody: 'Type on the body message',
    defaultMessages: 'Default message',
    sendCitation: 'Send',
    updateCitation: 'Update',
    writeLocation: 'Enter location',
    writeInterviewer: 'Enter the contact name',
    new_event: 'Subject',
    invite_people: 'Optional attendees',
    invite_people_placeholder: 'Include the mails separated by ;',
    to_time: 'to',
    online_meeting: 'Online meeting',
    attendees: 'Attendees',
    optionals: 'Optionals',
    accepted: 'Accepted',
    declined: 'Declined',
    none: 'None',
    pending: 'Pending',
    tentative: 'Tentative',
    organizer: 'Organizer',
    notes: 'Notes',
    confirmation: {
      description_create_individual: 'You are going to create an individual meeting, are you sure?',
      description_create_group: 'You are going to create a group meeting for {num} people, are you sure?',
      description_update: 'You are going to edit the meeting for {num} person/s, the original meetings will be overwritten, are you sure?',
      description_update_one: 'There is already an existing meeting, the data will be overwritten, are you sure?'
    },
    filterBlockCitation: 'Citation',
    filterBlockGeneral: 'General',
    responsible: 'Responsible'
  },

  modalRequireDoc: {
    requireDoc: 'Documentation request',
    limitDate: 'Documentation submission deadline',
    select: 'Select',
    infoToUpload: 'Information to upload',
    writeInfoToUpload: 'Specify the information to upload by the candidature',
    body: 'Body',
    writeBody: 'Write the message body',
    defaultMessages: 'Default messages',
    sendRequireDoc: 'Send'
  },

  modalMailCustom: {
    quoteCall: 'send notification',
    editQuoteCall: 'Edit meeting',
    subject: 'Subject',
    writeSubject: 'Enter the subject',
    message: 'Message',
    writeMessage: 'Enter a message',
    body: 'Body',
    writeBody: 'Type on the body message',
    defaultMessages: 'Default messages',
    send: 'Send a notification',
    sendCompleted: 'Notification was sent',
    sendsCompleted: 'Notifications were sent'
  },

  modalFilter: {
    search: 'Search',
    notResults: 'No results',
    filterProfiles: 'Filter',
    name: 'Name',
    candidature: 'Position',
    namePlaceholder: 'Name',
    date: 'Date',
    fromDate: 'From',
    toDate: 'To',
    profile: 'Profile',
    skills: 'Skills',
    recruitmentSource: 'Source',
    recruitmentPlaceholder: 'Infojobs, Linkedin...',
    from: 'From',
    to: 'To',
    inputTextHelper: 'search multiple coincidences. E.g.: "London;Spain"',
    matching: 'Matching',
    workingDayLabel: 'Working hours',
    workingDayPlaceholder: '10 hours, 20 hours...',
    select: 'Select',
    availability: 'Availability',
    availabilityPlaceholder: 'Total, limited...',
    actualStatus: 'Current status',
    actualStatusPlaceholder: 'Eligible, Ineligible...',
    position: 'Position',
    positionPlaceholder: 'Sales associate, waiter...',
    academicFormation: 'Education',
    academicFormationPlaceholder: 'Bachelors, Master...',
    experience: 'Experience',
    experiencePlaceholder: '1 year, 2 years...',
    lastJob: 'Last job',
    lastJobPlaceholder: 'Sales associate, waiter...',
    cityOfResidence: 'Address',
    cityOfResidencePlaceholder: 'Madrid, Barcelona...',
    desiredLocation: 'Additional info',
    desiredLocationPlaceholder: 'Madrid, Majadahonda...',
    desiredArea: 'Others',
    desiredAreaPlaceholder: 'Centro, Usera...',
    saveSearch: 'Save search',
    status: 'Status',
    planningFilters: 'Meeting filters',
    candidatureFilters: 'Candidature filters'
  },

  boxOtherInfo: {
    additionalInformation: 'Supplementary information',
    otherData: 'Others',
    nearbyPlaces: 'Nearby places'
  },

  boxIAGen: {
    "title": "AI Evaluation",
    "matchTitle": "Score",
    "scoresTitle": "AI Score",
    "summaryTitle": "Summary:"
  },

  boxEditableInfo: {
    title: 'Editable information',
    edit: 'Edit',
    cancel: 'Cancel',
    accept: 'Accept',
    success: 'Info updated successfully',
    error: 'Error updating info'
  },

  editFieldAction: {
    title: 'Edit field',
    empty: '(No value)'
  },

  citations: {
    citationCreated: 'Meeting send',
    citationUpdated: 'Meeting updated',
    citationError: 'Error creating the meeting',
    citations_sent: 'Meeting sent',
    candidatures: 'Candidates'
  },

  citationStatus: {
    rejected: 'Rejected',
    accepted: 'Accepted',
    replan: 'Reschedule',
    pending: 'Unanswered',
    tentative: 'Tentative'
  },

  requireDoc: {
    docRequired: 'Documentation request sent',
    docRequest: 'Requested documentation',
    errorDocRequire: 'Error requesting documentation'
  },

  interviewerNotes: {
    titleTab: 'Interviewer notes',
    edit: 'Edit notes',
    textareaPlaceholder: 'Notes',
    showOnPrint: 'Show when printing',
    updatedAt: 'Modified by {author}, on {date} at {hour}'
  },

  headerProfileButtons: {
    export: 'Export',
    back: 'Back',
    actions: 'Actions',
    previous: 'Previous',
    following: 'Following',
    downloadCV: 'Download resume',
    downloadVideo: 'Download video',
    markAsRead: 'Mark as read',
    quoteCall: 'Schedule a meeting',
    editQuoteCall: 'Meeting',
    changeState: 'Change status',
    changeTags: 'Change tags',
    markFavorite: 'Mark favorite',
    removeFavorites: 'Remove favorites',
    exportFavorites: 'Download resume',
    remove: 'Remove',
    print: 'Print',
    userDeleted: 'User deleted',
    files: 'Attachments',
    mailCustom: 'Send notification',
    docs: 'Attachments',
    backToApplications: 'Back to candidature',
    add_to_blacklist: 'Add to blacklist',
    remove_from_blacklist: 'Remove from blacklist',
    added_to_blacklist: 'Added to blacklist',
    removed_from_blacklist: 'Pulled from blacklist',
    backToProcess: 'Back to process',
    backToEvaluativeTests: 'Back to evaluative tests',
    statusChanged: 'Status changed successfully',
    personDeleted: 'Person successfully deleted',
    deletePersonError: 'Error deleting person'
  },

  modalDownloadFiles: {
    title: 'Attachments'
  },

  modalFilesPreview: {
    no_preview: 'Preview not available'
  },

  modalCreateReport: {
    createReport: 'Create a report',
    name: 'Name',
    reportName: 'Report name',
    date: 'Date',
    fromDate: 'From',
    toDate: 'To',
    profile: 'Profile',
    skills: 'Skills',
    recruitmentSource: 'Source',
    matching: 'Matching',
    workingDay: 'Working hours',
    availability: 'Availability',
    actualStatus: 'Current status',
    position: 'Position',
    positionPlaceholder: 'Sales associate, waiter...',
    academicFormation: 'Education',
    academicFormationPlaceholder: 'Bachelors, Master...',
    experience: 'Experience',
    lastJob: 'Last job',
    lastJobPlaceholder: 'Sales associate, waiter...',
    cityOfResidence: 'Address',
    cityOfResidencePlaceholder: 'Madrid, Barcelona...',
    desiredLocation: 'Additional info',
    desiredLocationPlaceholder: 'Madrid, Majadahonda...',
    desiredArea: 'Others',
    desiredAreaPlaceholder: 'Centro, Usera...',
    removeUser: 'Remove user',
    enterCandidateName: 'Enter the name to comfirm',
    writeCandidateName: 'Type the name',
    filterProfiles: 'Filter',
    from: 'From',
    to: 'To',
    include: 'Include',
    selectAll: 'Select all'
  },

  modalChangeAvatar: {
    title: 'Change avatar',
    info: 'Drag your photo or click to search',
    submit: 'Accept',
    dropFilesHere: 'Drag your photo here or select a file...',
    dropFilesActive: 'Image correct, drop to continue'
  },

  modalChangePass: {
    title: 'Change password',
    forceTitle: 'Please, change the password to continue',
    info: 'Enter your new password and click accept',
    submit: 'Accept',
    oldPass: 'Old password',
    newPass: 'New password',
    repPass: 'Repeat password',
    errors: {
      passNotMatch: "Passwords don't match",
      passNotStrongEnough: 'The password must be at least 8 characters long and must include a combination of uppercase, lowercase, and numbers.'
    }
  },

  boxProfileCite: {
    currentState: 'Current status',
    citationDate: 'Date meeting',
    hiringDate: 'Start date',
    citationManager: 'Meeting sender',
    recruitmentSource: 'Source',
    tags: 'Tags',
    offers: 'Offer sent',
    disponibilidad: 'Hour availability:'
  },

  boxInfoHeader: {
    currentState: 'Current status',
    citationDate: 'Meeting date',
    citationManager: 'Meeting sender'
  },

  modules: {
    title: 'Solutions',
    module1: 'Talent Acquisition',
    module2: 'On Boarding',
    module3: 'Welcome Pack',
    module4: 'Employee hotline',
    module5: 'Absenteeism',
    module6: 'Surveys',
    module7: 'Virtual tutor',
    module8: 'Exit interview'
  },

  module_names: {
    talent_acquisition: 'Talent acquisition',
    on_board: 'On boarding',
    welcome: 'Welcome',
    employee_line: 'Employee line',
    feedback: 'Feedback',
    abseentism: 'Abseentism',
    survey: 'Survey',
    visual: 'Visual',
    exit_interview: 'Exit Interview'
  },

  myProfile: {
    title: 'My profile',
    logOut: 'Logout',
    name: 'Name',
    surname: 'Surname',
    email: 'Email',
    id: 'Id',
    client: 'Customer ID',
    phone: 'Phone',
    profileUpdated: 'Profile updated',
    avatarUpdated: 'Avatar updated',
    updatePass: 'Update password',
    update: 'Update',
    passUpdated: 'Password updated',
    passUpdatedError: 'Your password is not updated. Please, check your password.',
    changeLanguage: 'Change language',
    languages: [
      { value: 'es', name: 'Spanish' },
      { value: 'en', name: 'English' },
      { value: 'ca', name: 'Catalan' },
      { value: 'pt', name: 'Portuguese' },
      { value: 'fr', name: 'French' }
    ]
  },

  simpleDialog: {
    defaultMessages: 'Default messages',
    close: 'Close'
  },

  saveSearchesTable: {
    actions: 'Actions',
    delete: 'Delete',
    title: 'Tittle',
    date: 'Date',
    fields: 'Fields',
    backToSaveSearches: 'Back to Save Searches'
  },

  pullConfig: {
    title: "Candidates' pull",
    candidatures: 'Candidates notified'
  },

  pullConfigTable: {
    actions: 'Actions',
    delete: 'Delete',
    title: 'Title',
    is_active: 'Active',
    renotify_answered: 'Renotify',
    interval_in_days: 'Periodicity',
    days: 'days',
    last_time_execution: 'Last sent',
    filters: 'Filters',
    from: 'From',
    to: 'to'
  },

  reportsTable: {
    actions: 'Actions',
    delete: 'Delete',
    title: 'Title',
    date: 'Date',
    fields: 'Fields'
  },

  profileStats: {
    profile: 'Profile',
    skills: 'Skills'
  },

  pda: {
    match: 'Match'
  },

  pdaKeys: {
    r: 'Risk',
    e: 'Extroversion',
    p: 'Patience',
    n: 'Rules',
    a: 'Self-control'
  },

  modalSaveSearch: {
    title: 'Name for your saved search',
    back: 'Back to filter',
    enterName: 'Enter a name',
    saveSearch: 'Save search'
  },

  modalSearch: {
    filters: 'Filters applied:',
    title: 'Filter processes',
    chipGroupLocation: 'Location',
    chipGroupProcess: 'Process',
    chipGroupPreferences: 'Preferences',
    workCenter: 'Work center',
    processStatus: 'Process status',
    applicant: 'Applicant',
    validator: 'Validator',
    creationDate: 'Creation date',
    expectedDateOfIncorporation: 'Expected date of incorporation',
    position: 'Position',
    typeOfContract: 'Type of contract',
    workingDay: 'Working day',
    reason: 'Reason',
    brand: 'Brand',
    shift: 'Shift',
    placeholderSelect: 'Select',
    from: 'From',
    to: 'To',
    find: 'Find',
    clearFilter: 'Clear filter'
  },

  planningTable: {
    markAsRead: 'Mark as read',
    quoteCall: 'Schedule a meeting',
    editQuoteCall: 'Edit meeting',
    changeState: 'Change state',
    markFavorite: 'Mark favorite',
    removeFavorites: 'Remove favorites',
    exportFavorites: 'Export',
    candidature: 'Candidature',
    remove: 'Remove',
    print: 'Print',
    actions: 'Actions',
    personalData: 'Personal data',
    fav: 'Favorite',
    export: 'Export',
    matching: 'Matching',
    citation: 'Scheduled',
    interviewer: 'Interviewer',
    atendees: 'Atendees',
    technical: 'Contact',
    phase: 'Stage',
    statusPhase: 'Status',
    citationStatus: 'Response',
    status: 'Status',
    attendees: 'Attendees',
    location: 'Location',
    date: 'Sent date',
    availability: 'Availability',
    reason: 'Reason',
    groupal: 'Groupal',
    view: 'View'
  },

  peopleTable: {
    markAsRead: 'Mark as read',
    markAsNotRead: 'Mark as unread',
    quoteCall: 'Schedule a meeting',
    editQuoteCall: 'Edit meeting',
    changeState: 'Change state',
    markFavorite: 'Mark favorite',
    removeFavorites: 'Remove favorites',
    exportFavorites: 'Resume',
    remove: 'Remove',
    print: 'Print',
    actions: 'Actions',
    personalData: 'Personal data',
    fav: 'Favorite',
    export: 'Download resume',
    exportCsv: 'Export Report',
    requireDoc: 'Request documentation'
  },

  landingOffer: {
    title: 'Hello',
    body: 'We want to inform you that we have a new offer and we hope you are interested ',
    offerInfo: 'Offer Information',
    interested: "I'm interested",
    notInterested: "I'm not interested",
    notExist: 'The offer does not exist',
    expiredDescription: 'Sorry, this offer has already ended. We will keep you in mind for future opportunities. <br>Thank you very much for your interest.',
    endDescription: 'Perfect! We confirm that we have received your answer. <br> Thank you very much for participating in our selection process'
  },

  landingPull: {
    title: 'Hello',
    interested: "I'm interested",
    notInterested: "I'm not interested",
    notExist: 'Page not found',
    endDescription: 'Perfect! We confirm that we have received your answer. <br> Thank you very much'
  },

  landingDocRequest: {
    title: 'Attach the requested information: ',
    limit_date: 'Limit date: ',
    notExist: 'Page not found',
    expiredDescription: 'We are sorry, the time limit for submitting the documentation has already expired.  <br>Thank you for your interest.',
    endDescription: 'Perfect! We confirm that we have received your documents. Thank you!'
  },

  dropzone: {
    dragOrBrowse: 'Drag files or click to select',
    file_max_size: 'File max size',
    file_too_large: 'File too large',
    addFile: 'Add file',
    send: 'Send',
    error: 'Error',
    done: 'Sent'
  },

  observationsTable: {
    today: 'Today',
    now: 'Now',
    title: 'Changes log',
    date: 'Date',
    time: 'Time',
    change: 'Change',
    author: 'Author',
    addPlaceholder: 'Enter a comment here',
    add: 'Add',
    interviewDate: 'Invertiew date',
    addObservation: 'Add observations',
    deleted_user_name: 'Deleted',
    deleted_user_surname: 'user',
  },

  pushOfferTable: {
    title: 'Push Offers',
    name: 'Name',
    description: 'Description',
    userName: 'User',
    createdAt: 'Created at',
    state: 'Status'
  },

  lastCitationsTable: {
    title: 'Last meetings',
    date: 'Shipment date',
    interviewDate: 'Interview date',
    userName: 'User',
    location: 'Location',
    statusAnswer: 'Answer',
    editCitation: 'Edit meeting',
    attendees: 'Attendees'
  },

  offerCard: {
    title: 'Push Offers',
    update_at: 'Date',
    interested_candidatures: ' interested candidatures',
    updated_at: 'Date',
    empty: 'There is no push offer available',
    showOffers: 'Show only my push offers',
    delete: {
      success: 'Push offer deleted successfully',
      error: 'There is an error deleting the push offer'
    }
  },

  //Organism
  appBar: {
    searchPeople: 'Search',
    filterPeople: 'Filter',
    filterPlanning: 'Filter',
    saveSearchs: 'Save search',
    saveSearch: 'Save search',
    noResults: 'No results',
    errorNoTitle: 'Tittle is compulsory',
    filters: 'Filters',
    successSave: 'Save search',
    cloneSearch: 'Clone search'
  },

  profileInfo: {
    profile: 'Profile',
    availableFrom: 'Available from:',
    createdAt: 'Interviewed at',
    updatedAt: 'Updated at',
    pullSentAt: 'Pull sent at',
    zone: 'Zone',
    formation: 'Education',
    exp: 'Experience',
    lastJob: 'Last job',
    city: 'Address',
    desiredLocation: 'Additional info',
    desiredZone: 'Zone',
    years: 'Years',
    cv: 'Resume',
    video: 'Video',
    attached: 'Attached',
    evaluation: 'Evaluation',
    selectionStatus: 'Selection status'
  },

  drawer: {
    jobOffers: 'Jobs',
    inboxPeople: 'Inbox people',
    feedback: 'Feedback',
    partners: 'Partners',
    inbox: 'Inbox',
    savedSearches: 'Saved searches',
    planning: 'Planning',
    favorites: 'Favorites',
    reports: 'Reports',
    statistics: 'Statistics',
    dashboard: 'Dashboard',
    modules: 'Modules',
    clients: 'Clients',
    employeeLine: 'Employee hotline',
    talent: 'Talent Acquisition',
    pushOffer: 'Push Offers',
    pullConfig: 'Pull configuration',
    onboarding: 'Onboarding',
    exit_interview: 'Exit interview',
    integrations: 'Integrations',
    my_profile: 'My profile',
    userManual: 'User manual',
    phases_states: 'Stages and states',
    evaluativeTests: 'Video interviews',
    user_management: 'User management',
    tag_management: 'Tag management',
    keywords_rpa: 'Recruitment sources',
    superadministratorManual: 'ATS Administrator Manual',
    iaEvaluation: 'IA Evaluation',
    templateRequestManager: 'Template request manager',
    validatorTemplateRequest: 'Validation process'
  },

  statisticsItems: {
    checkAll: 'Mark all',
    year: 'Year',
    month: 'Month',
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'Octuber',
    november: 'November',
    december: 'December',
    interviewed: 'Interviewed candidates',
    suitables: 'Eligible Vs interviewed',
    recruitmentSource: 'Candidates per source',
    activity: 'Activity interviewed candidates',
    startDate: 'From',
    endDate: 'To',
    candidatures: 'Candidatures',
    name: 'Name',
    surname: 'Surname',
    dni: 'DNI',
    numberIdentity: 'Employee Nº',
    hiringDate: 'Hiring date',
    leavingDate: 'Leaving date',
    offer_interested: 'Offer Interested',
    recovered: 'Recovered Candidates',
    employeesWithoutPhase1: 'Employees who have not completed phase 1',
    employeesHaveNotDoneExit: 'Employees who has not done the exit interview',
    candidaturesSuitables: 'Eligible candidates',
    hiredVsSuitables: 'Hired Vs Eligible',
    notInterestedVsSuitables: 'Not interested Vs Eligible',
    tableTitle: 'Roles',
    positions: 'Roles',
    onboarding_phases: 'Onboarding Stages',
    phases: 'Stages',
    zone: 'Zone',
    alta: 'Currently registered',
    baja: 'Deregistered',
    whatsappCounter: {
      title: 'WhatsApp counter',
      bot_starter: 'Interview invitations',
      citations: 'Citations',
      offers: 'Push offers',
      pulls: 'Candidate pull',
      section: 'Section',
      sent: 'Sent',
      voucher: 'Voucher'
    }
  },

  //Page
  inboxPeople: {
    title: 'Inbox people',
    filtersSearch: 'Searches for'
  },

  offerCandidates: {
    title: 'Offer: ',
    offers_sent: 'Offers sent',
    unique_candidatures: 'Unique candidates',
    email: 'Email',
    sms: 'SMS',
    whatsapp: 'Whatsapp'
  },

  favorites: {
    title: 'Favourites'
  },

  planning: {
    title: 'Planning'
  },

  reports: {
    title: 'Reports',
    reportCreated: 'Report created',
    reportDeleted: 'Report deleted',
    modalConfirmationTitle: 'Generate reports',
    modalConfirmationButton: 'Accept',
    modalConfirmationButtonCancel: 'Cancel',
    reportReceiversTitle:
      "Introduce the report's receiver. This process may take a few minutes. You will receive an email as soon as it's ready. You can add many receivers separated by <b>;</b>'",
    reportExportedSuccess: 'Your report is being generated. We will send it to you by email as soon as possible',
    reportExportedFailed: 'There was a problem creating your report. The incident has been reported to the support team'
  },

  savedSearches: {
    title: 'Saved searches'
  },

  statistic: {
    title: 'Statistics',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday'
  },

  errorPage: {
    title: 'Uuuups! site not found'
  },

  login: {
    login: 'Login',
    sigin: 'Access',
    email: 'Email address',
    password: 'Password',
    recover: 'Recover',
    password_recover: 'Password recover',
    password_recover_success: 'A recovery password has been sent to the indicated email address.',
    password_recover_error: 'There has been an error sending the recovery email.',
    mandatoryField: 'Mandatory field',
    invalidEmail: 'Invalid email',
    noValidRole: 'Invalid user',
    noValidCredentials: 'User or password incorrect'
  },

  tableLocalization: {
    pagination: {
      labelDisplayedRows: '{from}-{to} de {count}',
      labelRowsSelect: 'elements',
      labelRowsPerPage: 'Elements per site',
      firstAriaLabel: 'Begin',
      firstTooltip: 'Go home',
      previousAriaLabel: 'Back',
      previousTooltip: 'Back',
      nextAriaLabel: 'Next',
      nextTooltip: 'Next',
      lastAriaLabel: 'End',
      lastTooltip: 'Go to end'
    },
    body: {
      emptyDataSourceMessage: 'No data'
    },
    toolbar: {
      searchTooltip: 'Search',
      searchPlaceholder: 'Search'
    }
  },

  savedSearchesItemTableLocalization: {
    pagination: {
      labelDisplayedRows: '{from}-{to} de {count}',
      labelRowsSelect: 'elements',
      labelRowsPerPage: 'Elements per site',
      firstAriaLabel: 'Begin',
      firstTooltip: 'Go home',
      previousAriaLabel: 'Back',
      previousTooltip: 'Back',
      nextAriaLabel: 'Next',
      nextTooltip: 'Next',
      lastAriaLabel: 'End',
      lastTooltip: 'Go to end'
    },
    body: {
      emptyDataSourceMessage: 'No record found for this saved search'
    },
    toolbar: {
      searchTooltip: 'Search',
      searchPlaceholder: 'Search'
    }
  },

  errors: {
    internalServerError: 'Operation could not be completed'
  },

  exitInterview: {
    title: 'Exit Interviews'
  },

  landingEmail: {
    title: 'An error has ocurred',
    body: 'Operation could no be completed',
    loading: 'Loading',
    hi: 'Hello',
    citation_header: "We would like to inform you that after evaluating your profile, we would like to move forward with you to the next phase of the selection process for the position of",
    citation_info: "Call information",
    address: "Address:",
    contact_person: "Contact person:",
    interview_date: "Interview date:",
    interview_hour: "Interview hour:",
    give_answer: 'Please send us your response to this call by selecting one of the following options:',
    confirmed: 'I will attend',
    reschedule: 'I will not attend, new date',
    not_interested: 'Not interested',
    citation_not_exist: 'No citation exists',
  },

  landingReschedule: {
    title: 'What slot time is suitable to attend to an interview?',
    noDates: 'There is no available dates',
    successSend: 'Your request has sent correctly',
    putAvailability: 'Enter your availability',
    send: 'Send',
    reasonPlaceholder: 'Enter a reason'
  },

  landingNotInterested: {
    title: 'Could you let us know why you are not interested in? ',
    error: 'An error has occurred',
    successSend: 'Your request has sent correctly',
    putReason: 'Enter a reason',
    send: 'Send'
  },

  peopleTableFilters: {
    toManage: 'To manage',
    inProgress: 'In progress',
    finalists: 'Finalists',
    others: 'Others'
  },

  noCvInfo: 'Candidates selected do not have a resume attached',

  exitInterviewStatisticsItems: {
    satisfactionSurvey: {
      exitFactors: 'Exit factors',
      superAgree: 'Super agree',
      agree: 'Agree',
      littleDisagree: 'Little disagree',
      disagree: 'Disagree',
      accessibleManager: 'Accessible manager',
      successRecognized: 'Success recognition',
      errorTolerance: 'Error tolerance',
      flexibility: 'Flexibility',
      conciliation: 'Conciliation',
      meritocracy: 'Meritocracy',
      clima1: 'Good climate in the company',
      clima2: 'Good weather with your team',
      concilia1: 'Allows conciliation with the family',
      concilia2: 'Reconcile with studies',
      desarrollo1: 'Paid according to work',
      desarrollo2: 'Offers professional development'
    },
    exits: 'Exits',
    exit_completed: 'Completed',
    recommends: 'Yes, recommends',
    reason: 'Exit reason'
  },

  processesStatisticsItems: {
    processesTransaccionalRecived: 'Transactional processes received',
    suitables: 'Processed Vs total',
    numberOfPolls: 'Polls answered',
    averagePolls: 'NPS',
    processesTransaccionalGroup: 'Transactional processes per category',
    processesConsultivoGroup: 'Consultation processes per category',
    processesCollectiveAgreementGroup: 'Collective agreement processes',
    startDate: 'From',
    endDate: 'To',
    candidatures: 'Candidatures',
    name: 'Name',
    processes: 'Processes',
    recived: 'Received',
    total: 'Total',
    suitablesVsRecibidos: 'In progress Vs Total',
    tableTitle: 'Transactional processes'
  },
  tableFilters: {
    toManage: 'To manage',
    inProgress: 'In progress',
    finalists: 'Finalist',
    others: 'Others'
  },
  onboardingInbox: {
    title: 'Inbox Onboarding'
  },
  onboarding: {
    deleteSuccess: 'Element removed',
    notesTitle: 'Notes'
  },

  onboardingStatisticsItems: {
    leaving: 'Leaves',
    hiring: 'Incorporaciones',
    inprogress: 'Onboardings en progreso',
    ratio: 'Ratio',
    phase: 'Stage',
    hot_issues_title: 'Hot issues',
    hot_issues: {
      formacion_online: 'Formación online',
      uniforme: 'Uniforme disponible',
      epi: 'EPI disponible',
      contrato: 'Contrato entregado',
      rm: 'Reconocimiento médico',
      relacion_equipo: 'Mala relación con el equipo',
      formacion_riesprof_hito3: 'Formación prevención de riesgos',
      mutua_mc_mutual_hito3: 'Protocolo de actuación en caso de accidentes',
      formacion_compliance_hito3: 'Formación Compliance',
      reconocimiento_medico_hito3: 'Reconocimiento médico',
      welcomepack_hito3: 'Welcome Pack',
      employeeapp_hito3: 'Employee App',
      protocol_bajamed_hito4: 'Protocolo baja médica',
      reunion_manager_hito5: 'Reunión Feedback',
      tarjeta: 'Card',
      hardskill1_p4: 'High employee portal'
    }
  },
  status: {
    received: 'Received',
    canceled: 'Canceled',
    in_process: 'In Process',
    pending_info: 'Pending info',
    processed: 'Processed',
    accepted: 'Accepted',
    rejected: 'Rejected',
    declined: 'Rejected',
    none: 'Not answered',
    pending: 'Not answered',
    tentative: 'Tentative',
    postponed: 'Postponed',
    developing: 'Developing',
    implemented: 'Implemented',
    1: 'Suitable',
    2: 'Not suitable',
    3: 'To be confirmed',
    4: 'Confirmed',
    5: 'Not interested',
    6: 'Reprogram',
    7: 'Hired',
    8: 'Reserve',
    9: 'Does not attend',
    10: 'Cited phase 2',
    11: 'Rejected',
    12: 'Rejected stage 2',
    13: 'Not interested stage 2',
    14: 'Not attend stage 2',
    15: 'In process',
    16: 'Doc requested',
    17: 'Doc received',
    18: 'Pending update',
    19: 'Cited'
  },
  evaluativeConfig: {
    pending: 'Pending',
    in_review: 'In review',
    suitable_in_other_process: 'Suitable in other process',
    suitable: 'Suitable',
    not_suitable: 'Not suitable',
    resignation: 'Rejected',
    no_show: 'No show'
  },
  dialog: {
    success: 'Aceptar',
    decline: 'Cancelar',
    save: 'Guardar'
  },
  feedbackStatisticsItems: {
    Received: 'Suggestions Received',
    suitables: 'Suggestions suitable Vs total',
    category: 'Suggestions by category',
    department: 'Suggestions by department',
    centerType: 'Suggestions by center',
    statesTypes: 'Suggestion by state',
    endDate: 'Until',
    name: 'Name',
    recived: 'Received',
    total: 'Total'
  },

  offerStates: {
    unanswered: 'Unanswered',
    interested: 'Interested',
    not_interested: 'Not interested'
  },

  talent_acquisition: {
    inbox: {
      title: 'Inbox people'
    },
    favorites: {
      title: 'Favourites'
    },
    'saved-searches': {
      title: 'Saved searches'
    },
    'saved-searches-item': {
      title: 'Saved searches'
    },
    'push-offer': {
      title: 'Push Offers'
    },
    'offer-candidatures': {
      title: 'Push Offers'
    },
    'pull-config': {
      title: 'Pull of candidates'
    },
    'user-manual': {
      title: 'User Manual'
    },
    'evaluative-tests': {
      title: 'Evaluative Tests'
    },
    planning: {
      title: 'Planning'
    },
    reports: {
      title: 'Reports'
    },
    statistics: {
      title: 'Statistics'
    }
  },

  on_board: {
    inbox: {
      title: ''
    }
  },

  employee_line: {
    inbox: {
      title: ''
    },
    'saved-searches': {
      title: 'Saved searches'
    },
    'saved-searches-item': {
      title: 'Saved searches'
    }
  },

  feedback: {
    inbox: {
      title: 'Inbox Items'
    },
    title: 'Inbox Items',
    filter: 'Filter Items',
    date: 'Date',
    questions: 'Questions',
    proposal: 'Item',
    category: 'Category',
    email: 'Email',
    dni: 'DNI',
    phone: 'Phone'
  },

  exit_interview: {
    inbox: {
      title: 'Exit interview'
    },
    exit_reasons: 'Motivos de salida',
    exit_factors: 'Factores de salida',
    other_data: 'Otros datos',
    position: 'Puesto',
    leaving_date: 'Fecha de salida',
    male_or_female: 'Género',
    male: 'Hombre',
    female: 'Mujer',
    category: 'Categoría',
    email: 'Email',
    dni: 'DNI',
    phone: 'Tel',
    Motivo1: 'Motivo de salida',
    Motivo2: 'Submotivo de salida',
    Motivo3: 'Observaciones motivo de salida',
    Clima1: 'La relacion con el responsable ha sido',
    Clima2: 'El clima de trabajo ha sido satisfactorio',
    Concilia1: '¿Me han dado facilidades de cambio de turnos?',
    Concilia2: '¿Cómo crees que podríamos mejorar?',
    Desarrollo1: '¿La empresa le ha dado opciones de desarrollo?',
    Desarrollo2: '¿La formación recibida ha cumplido sus expectativas?'
  },

  integrations: {
    outlook: 'My Outlook calendar',
    google: 'My Google calendar',
    connect: 'Connect',
    disconnect: 'Disconnect'
  },

  userManual: {
    title: 'User manual'
  },

  superadministratorManual: {
    title: 'ATS Administrator Manual'
  },

  phases_states: {
    descriptionPhasesStates: 'From here you can create, edit or delete the stages and statuses of the applications.',
    titlePhase: 'Stages',
    titleState: 'States',
    descriptionPhase:
      'The stages represent the different steps a candidate goes through during the selection process. The predefined stages are: Received and Hired.',
    descriptionTwoPhase:
      "In addition to these predefined stages, the application gives you the flexibility to create custom stages based on your organization's specific needs.",
    descriptionState:
      "The states in a stage represent a candidate's level of progress within a specific stage of the selection process, which in turn simplifies the identification and tracking of the candidate's progress in that stage. The predefined statuses are: Enrolled and Hired.",
    descriptionTwoState:
      'In addition to the already established states, the application allows you to customize states to perfectly fit the particularities of your organization.',
    labelPhase: 'Stage',
    labelState: 'State',
    labelCurrentState: 'Current status',
    labelNewState: 'New status',
    errorOnDeleteStatus: 'You cannot delete a status that is in use, it has {candidatures} that you must first move to another status.',
    placeholderPhase: 'Enter the name of the stage',
    placeholderState: 'Enter the name of the state',
    placeholderSelectPhase: 'Select a stage',
    titleButtonPhase: 'Add stage',
    titleButtonState: 'Add state',
    assignStateToPhase: 'Assign state to stage',
    buttonSave: 'Save',
    buttonAllSave: 'Save changes',
    buttonCancel: 'Cancel',
    buttonMove: 'Move',
    titleFunnel: 'Flow Illustration',
    subtitleFunnel: 'Stages and States of the selection process',
    titlePhaseFunnel: 'Stage',
    buttonDelete: 'Delete',
    selectionFunnel: 'Selection Funnel',
    currenteState: 'Current State',
    labelColor: 'Assign a color',
    placeholderDescriptionStatus: 'Enter a message',
    feedbackMessage: 'Feedback message',
    feedbackMessageRegistered:
      '<p>We sincerely appreciate your interest in joining our team, and we appreciate the time and effort you have invested in submitting your profile. </p><p><br></p><p>We want you to know that your application has been registered in our system and that we are carefully reviewing all the applications received. </p><p><br></p><p>At this time, we are evaluating all the applications and looking for the ideal person that fits the requirements of the position and our company culture. This process may take some time, as we are committed to carrying out a thorough selection. </p><p><br></p><p>We guarantee you that we will keep you updated on the next steps in the selection process as soon as we have news regarding an offer that aligns with your profile and motivation. </p><p><br></p><p> Our intention is to be transparent and provide you with all the necessary information so that you can follow the development of your candidacy. Thank you very much for your patience and understanding during this process. </p><p><br></p><p>We appreciate your interest in being part of our team and we hope to have the opportunity to get to know you better in the next stages of the selection process.</p>',
    feedbackMessageHired:
      '<p>Congratulations! We are pleased to inform you that you have been selected to be part of our team. </p><p><br></p><p>During the selection process, your performance and skills stood out, and we are convinced that your experience and contributions will be very valuable to our company. </p><p><br></p><p>In the next few days, we will contact you to share the final details of the contract, the necessary documentation, as well as to provide you with information about the orientation and any necessary preparation for your first day. </p><p><br></p><p>We congratulate you once again and we look forward to the best of you in this new stage in your professional life. </p><p><br></p><p>We welcome you to the team!</p>',
    feedbackMessageDefaultCustom: 'We are reviewing your application, we will contact you soon.',
    selectionStatus: 'Select a state',
    questionChangeStatus: 'Do you want to change the status of the mentioned candidates?',
    changeStatusCheckApplication: "To change the candidate's status, select the offer and the status to change",
    changeStatus: 'Change status',
    updatedVacancies: 'Updated vacancies',
    modalEditPhaseTitle: 'Edit stage',
    modalEditStateTitle: 'Edit state',
    modalDeletePhaseTitle: 'Delete Stage',
    modalDeleteStateTitle: 'Delete State',
    chipUnAssigned: 'Unassigned',
    errorOrderPhasesUpdate: 'Error updating stage order',
    errorOrderStatusUpdate: 'Error updating status order',
    changesSavedSuccessfully: 'Changes saved successfully',
    phaseReceived: 'Received',
    phaseHired: 'Hired',
    alertCannotAddMoreEightPhases: 'Cannot add more than 12 stages',
    alertCreatedPhase: 'Stage created successfully',
    alertPhaseExists: 'A stage with that name already exists',
    alertErrorCreatePhase: 'Error creating stage',
    alertAddNamePhase: 'You must add a name to the stage',
    alertCreateStatus: 'Status created successfully',
    alertErrorGetPhases: 'Error getting stages',
    alertStatusExists: 'A status with that name already exists',
    alertErrorCreateStatus: 'Error creating status',
    alertErrorGetStatus: 'Error getting statuses',
    alertSelectPhase: 'Select a stage before adding a state',
    alertTitleCannotDeletePhase: 'You cannot delete this stage',
    messageAlertCannotDeletePhase:
      'You have states associated with this stage, you must move them to another existing stage or delete them to delete this stage.',
    alertThereAreNoStatusesInPhase: 'There are no states associated with this stage',
    stateInscribed: 'Registered',
    stateHired: 'Hired',
    updatedPhase: 'Stage updated successfully',
    errorUpdatedPhase: 'Error updating stage',
    updatedStatus: 'Status updated successfully',
    assignedSuccessfully: 'Successfully assigned candidate',
    errorUpdatedStatus: 'Error updating status',
    alertMessageFeedback: 'You must add a feedback message',
    alertStateNameRequired: 'You must add a name to the state',
    questionNotified: 'Do you want a message to be sent alerting people who move to this status about the change in candidacy?',
    selectNotificationMethods: 'Choose the channel through which you want notifications to be sent.',
    infoSelectNotificationMethods: 'Remember that you must have a template designed for this communication.',
    smsMethod: 'SMS',
    emailMethod: 'Email',
    whatsapMethod: 'WhatsApp'
  },

  evaluativeTests: {
    title: 'Video Interviews',
    descriptionSection: 'Here you will find all your active and inactive campaigns',
    placeholderInputTerm: 'Filter by name',
    titleNewTest: 'New video interview',
    titleEditTest: 'Edit video interview',
    subtitleNewTest: 'Video interview settings',
    descriptionNewTest: 'Configure the video interview, according to the needs of the selection process',
    labelPickerStartDate: 'Start date',
    labelPickerEndDate: 'End date',
    nameTest: 'Video interview name',
    placeholderNameTest: 'Enter the name of the video interview',
    urlBot: 'Bot URL',
    placeholderUrl: 'https://...',
    buttonGenerateUrl: 'Generate URL',
    selectionQuestions: 'Selection Questions',
    subtitleTest: 'Perform a more complete filter of the candidates',
    subtitleTest2: 'Create at least one question, assign a maximum answer time and focus on the candidates who answered correctly',
    placeholderInputQuestion: 'Question #1',
    buttonAddQuestion: 'Add question',
    buttonSave: 'Save',
    buttonCancel: 'Cancel',
    buttonRegenerateUrl: 'Regenerate URL',
    inactive: '(disabled)',
    noResults: 'No results were found for the selected date',
    errorFetchTests: 'An error occurred while fetching the evaluative tests',
    buttonCreateTest: 'Create video interview',
    singleQuestion: 'Question',
    multipleQuestions: 'Questions',
    successDeleteTest: 'Video interview deleted successfully',
    successDuplicateTest: 'Video interview duplicated successfully',
    successCreateTest: 'Video interview created successfully',
    successUpdateTest: 'Video interview updated successfully',
    useSampleQuestion: 'Use sample question',
    activesTest: 'Active',
    inactivesTest: 'All',
    titleBoxProfileInbox: 'Video Interviews',
    statusVideoInterview: 'Status of the video interview',
    infoUploadVideo: 'Candidate has not uploaded the video yet',
    titleTemplate: 'Bot template',
    successChangeStatus: 'Status changed successfully',
    errorFieldRequired: 'This field is required',
    errorFieldInvalid: 'This field is invalid',
    errorFieldQuestion: 'You must add at least one question',
    placeholderSelectTemplate: 'Select a template',
    placeholderTimeLimit: 'Enter the time limit',
    successRegenerateUrl: 'URL regenerated successfully and sent to the candidate',
    errorRegenerateUrl: 'An error occurred while regenerating the URL',
    inactiveCard: 'Inactive',
    menuEdit: 'Edit',
    menuAddCsv: 'Import candidates',
    menuDuplicate: 'Duplicate',
    menuDelete: 'Delete',
    placeholderQuestionOne: 'Tell us in 30 seconds the most relevant part of your work experience and how it relates to the position you are applying for.',
    placeholderQuestionTwo: 'How do you communicate effectively with different stakeholders (customers, co-workers, suppliers, etc.)?',
    placeholderQuestionThree: 'What skills do you consider most important for this job?',
    placeholderQuestionFour: 'How do you contribute to teamwork and collaboration in a work environment?',
    placeholderQuestionFive: 'Describe a situation in which you overcame a challenge at work',
    placeholderQuestionSix: 'What are your medium/long-term goals and how does this position align with them?',
    titleQuestion: 'Question',
    csvUpload: {
      editCta: 'Import candidates',
      modalCheckCandidates: 'You are about to import %ok% candidates in the file.',
      modalImportCandidates: 'Do you want to continue? They will receive a notification via Whatsapp/email.',
      candidatesImported: 'Candidates successfully imported (%ok% / %total%)',
      candidatesNotifiedError: 'Error notifying the candidates'
    }
  },

  inboxColumns: {
    Favoritos: 'Favorites',
    'Nombre y apellidos': 'Name and surname',
    País: 'Country',
    DNI: 'ID',
    'Última videoentrevista': 'Last video interview',
    'Estado videoentrevista': 'Status of the video interview',
    'Fecha de envío': 'Shipping Date'
  },

  filterFields: {
    Nombre: 'Name',
    DNI: 'ID',
    Email: 'Email',
    'Fecha de entrevista': 'Interview date',
    Teléfono: 'Phone',
    'Video entrevista': 'Video interview',
    Estado: 'Status'
  },

  filterBlocks: {
    'Video entrevista': 'Video interview',
    'Datos personales': 'Personal details',
    Evaluación: 'Evaluation'
  },

  user_management: {
    titleSection: 'User Management',
    searchLabel: 'Search',
    filterProfile: 'Filter by profile',
    placeholderFilter: 'Select a profile',
    descriptionSection:
      'Here you can control and customize the permissions and access of ATS administrators. Add, modify or delete user accounts, assign specific roles and ensure efficient system management.',
    activeUsers: 'Active',
    inactiveUsers: 'Inactive',
    createUser: 'Create user',
    username: 'Name',
    lastname: 'Last name',
    email: 'Email',
    profile: 'Profile',
    phone: 'Phone',
    modalTitleCreate: 'Create user',
    modalTitleUpdate: 'Modify user',
    labelShowDefaultFiltersTalent: 'Configure default filters of the Talent module',
    labelName: 'Name',
    labelLastName: 'Last Name',
    labelEmail: 'Email',
    labelModules: 'Modules',
    labelProfile: 'Profile',
    labelPassword: 'Provisional password',
    buttonCreateUser: 'Create user',
    buttonCancel: 'Cancel',
    buttonUpdateUser: 'Update user',
    placeholderName: 'Enter the name',
    placeholderLastName: 'Enter last name',
    placeholderEmail: 'Enter email',
    placeholderPassword: 'Enter password',
    placeholderProfile: 'Select a profile',
    disableUsers: 'Disable users',
    disableUser: 'Disable user',
    inputEmailUser: "Enter the user's email",
    confirmDeactivation: 'Can you confirm that you want to deactivate the user {name} {surname}?',
    confirmActivation: 'Can you confirm that you want to activate the user {name} {surname}?',
    buttonConfirmDisable: 'Yes, disable user',
    buttonConfirmEnable: 'Yes, activate user',
    buttonConfirmDisableUsers: 'Yes, disable users',
    buttonConfirmEnableUsers: 'Yes, enable users',
    atsAdminUser: 'Super Administrator',
    supervisorUser: 'Supervisor',
    basicUser: 'Basic',
    customUser: 'Custom',
    readOnlyUser: 'Read only',
    createdUser: 'Successfully created user',
    editedUser: 'User successfully modified',
    errorCreateUser: 'Error creating user',
    errorEditUser: 'Error modifying user',
    nameRequired: 'You must add a name',
    surnameRequired: 'You must add a surname',
    emailRequired: 'You must add an email',
    emailInvalid: 'Invalid email',
    passwordRequired: 'You must add a password',
    moduleRequired: 'You must select a module',
    profileRequired: 'You must select a profile',
    confirmDisableUsers: 'Can you confirm that you want to disable the following users?',
    confirmEnableUsers: 'Can you confirm that you want to activate the following users?',
    userDisabledSuccessfully: 'User disabled successfully',
    usersDisabledSuccessfully: 'Users disabled successfully',
    userEnabledSuccessfully: 'User enabled successfully',
    usersEnabledSuccessfully: 'Users enabled successfully',
    userDisabledError: 'Error disabling user',
    usersDisabledError: 'Error disabling users',
    userEnabledError: 'Error activating user',
    usersEnabledError: 'Error activating users',
    passwordRequirements: 'The password must be at least 8 characters, one letter and one number.',
    passwordInvalid: 'The password is invalid',
    passwordValid: 'The password is valid',
    userAlreadyExists: 'The user already exists!',
    labelAuthorizedSections: 'Sections',
    placeholderModules: 'Select the modules for this user',
    placeholderAuthorizedSections: 'Select the sections for this profile',
    authorizedSectionsRequired: 'You must give access to some section',
    inbox: 'Inbox',
    push_offer: 'Push offer',
    pull_config: 'Pull config',
    saved_searches: 'Saved searches',
    planning: 'Planning',
    favorites: 'Favorites',
    reports: 'Reports',
    statistics: 'Statistics',
    statistics_dashboard: 'Statistics dashboard',
    rpa_config: 'Recruitment sources',
    evaluative_tests: 'Evaluative tests',
    template_requestor: 'Template Requestor',
    template_validator: 'Process Validation',
    module: 'Module',
    enableUsers: 'Enable users',
    enableUser: 'Enable user',
    dateCreated: 'Creation date',
    errors: {
      max_users_reached: 'You have reached the maximum number of active users. If you want to increase your limit, please contact our Customer Success team.'
    }
  },

  ia_evaluation: {
    titleSection: 'Automated evaluation by AIG',
    descriptionSection:
      'Customise and configure the automated selection system by AIG. In this first stage create and edit your evaluation according to the configured positions.',
    createEvaluation: 'Create new evaluation',
    searchLabel: 'Search',
    placeholderInputTerm: 'Name',
    active: 'Active',
    inactive: 'Inactive',
    disable: 'Deactivate evaluation',
    enable: 'Activate evaluation',
    name: 'Name',
    job: 'Job',
    family: 'Family',
    criteria: {
      experience: 'Experience',
      education_training: 'Education',
      salary_expectation: 'Salary expectation',
      employment_status: 'Employment status',
      location: 'Location',
      mobility: 'Mobility',
      availability: 'Availability'
    },
    relevance_level: {
      relevant: 'Relevant',
      desired: 'Desired',
      mandatory: 'Mandatory',
      ignore: 'Ignore',
      others: 'Informative'
    },
    buttonConfirmDisable: 'Yes, deactivate evaluation',
    buttonConfirmEnable: 'Yes, activate evaluation',
    confirmDeactivation: 'Can you confirm that you want to deactivate the {name} evaluation?',
    confirmActivation: 'Can you confirm that you want to activate the {name} evaluation?',
    evaluationDisabledSuccessfully: 'Evaluation successfully deactivated',
    evaluationEnabledSuccessfully: 'Evaluation successfully activated',
    evaluationDisabledError: 'Error deactivating the evaluation',
    evaluationEnabledError: 'Error activating the evaluation',
    wizard: {
      title_create: 'Create new evaluation',
      title_edit: 'Edit evaluation',
      subtitle: 'Evaluation criteria',
      description:
        'The evaluation criteria may include aspects such as experience, training, location, among others. This selection lays the groundwork for the initial assessment of candidates and is a critical step in ensuring that the selection process is aligned with the specifics of the assessment being shaped.',
      name: 'Name',
      name_placeholder: 'Enter the name of the profile',
      family: 'Job family',
      family_placeholder: 'Select a job family',
      job: 'Jobs',
      job_placeholder: 'Select a job',
      rpa_credentials: 'Recruitment Source Credentials',
      rpa_credentials_placeholder: 'Select a recruitment source',
      info_text: 'You may wish to make adjustments to the evaluation for the post of:',
      info_text_values: '<strong>{job}</strong> of the <strong>{family}</strong> family.',
      tableHeader: 'Choose the items to which you want to give more weight in the evaluation:',
      save: 'Save settings',
      cancel: 'Cancel',
      save_confirmation_title: 'Do you confirm the {mode} of the evaluation?',
      save_confirmation_button: 'Confirm {mode}',
      create_mode: 'creation',
      edit_mode: 'edition',
      successCreateEvaluation: 'Successfully created evaluation',
      errorCreateEvaluation: 'Error creating the evaluation',
      successUpdateEvaluation: 'Successfully updated assessment',
      errorUpdateEvaluation: 'Error updating the evaluation'
    }
  },

  job_offers: {
    titleSection: 'Jobs',
    descriptionSection:
      'What will you be able to see here? In this view, you will find the list of your published offers in the configured recruitment sources, as well as spontaneous applications and also the processes you create manually. Access each of them to view the candidates and manage your selection processes.',
    filterJobs: 'Filter Offers',
    createJob: 'Create Job',
    name: 'Offer',
    createdBy: 'Created By',
    keyword: 'Keyword',
    not_available: 'Not available',
    profile: 'Position',
    location: 'Location',
    createdAt: 'Creation Date',
    endDate: 'End Date',
    recruitmentSource: 'Recruitment Source',
    newCandidates: 'New Candidates',
    totalCandidates: 'Total Candidates',
    candidatesNewVsTotal: 'New Candidates / Total Candidates',
    infojobs: 'Infojobs',
    infojobsPriority: 'Infojobs',
    epreselec: 'ePreselec',
    epreselecPriority: 'ePreselec',
    pandape: 'Pandapé',
    pandapePriority: 'Pandapé',
    turijobs: 'Turijobs',
    turijobsPriority: 'Turijobs',
    computrabajo: 'Computrabajo',
    computrabajoPriority: 'Computrabajo',
    talentclue: 'Talent Clue',
    bizneo: 'Bizneo',
    indeed: 'Indeed',
    manual: 'Manual',
    spontaneous: 'Spontaneous',
    active_offers: 'Active Offers',
    inactive_offers: 'Inactive Offers',
    filter_offers: 'Filter offers',
    offer_status: 'Offer status',
    active_label: 'Active',
    inactive_label: 'Inactive',
    wizard: {
      title_create: 'Create new job',
      title_edit: 'Edit offer',
      subtitle: 'Evaluation criteria',
      description:
        'In this modal, you can create a new job. You must give it a name, assign it a location and an end date, and, of course, any details you consider necessary. Once you have created it, you will be able to assign candidates from Inbox to this job, to have them grouped in the same process and manage each application within this job.',
      name: 'Name',
      name_placeholder: 'Enter the offer name',
      description_value: 'Description',
      description_value_placeholder: 'Enter a description',
      evaluation_profile: 'Evaluation workflow',
      evaluation_profile_placeholder: 'Select an evaluation workflow',
      location: 'Location',
      location_placeholder: 'Enter the location',
      recruitment_source: 'Recruitment source',
      recruitment_source_placeholder: 'Select a recruitment source',
      created_at: 'Creation date',
      created_at_placeholder: 'Select the creation date',
      end_at: 'End date',
      end_at_placeholder: 'Select the end date',
      save: 'Save',
      save_confirmation_title: 'Confirm the {mode} of the offer?',
      save_confirmation_button: 'Confirm {mode}',
      create_mode: 'creation',
      edit_mode: 'edition',
      successCreateJobOffer: 'Offer created successfully',
      errorCreateJobOffer: 'Error creating the offer',
      successUpdateJobOffer: 'Offer updated successfully',
      errorUpdateJobOffer: 'Error updating the offer'
    }
  },
  job_offer_applications: {
    backToJobOffers: 'Back to jobs',
    filter: 'Filter candidates',
    is_favorite: 'Favorites',
    name_and_surname: 'Name and surname',
    rating: 'Candidate rating',
    level_one: 'Level 1 score',
    level_two: 'Level 2 score',
    level_three: 'Level 3 score',
    phase: 'Phase',
    selection_status: 'Status',
    location: 'Location',
    created_at: 'Date',
    last_job: 'Last position',
    ia_matching: "AI Score",
    match: 'Match',
    bot_matching_pending: 'Pending',
    formation: 'Education',
    cv: 'CV'
  },
  videoSentiments: {
    title: 'Video Analysis',
    titleValores: 'Detected Sentiments',
    anger: 'Anger',
    contempt: 'Contempt',
    disgust: 'Disgust',
    fear: 'Fear',
    happiness: 'Happiness',
    neutral: 'Neutral',
    sadness: 'Sadness',
    surprise: 'Surprise'
  },


  iaFake: {
    title: 'AI generated summary',
    description: 'After analysing the candidate\'s video, we observed an outstanding communication ability. His presentation was clear and structured, demonstrating confidence and command of the language.\n He accurately explained his experience in roles similar to the one applied for, detailing relevant tasks and achievements. The fluency of his speech and positive body language reflect great professionalism and empathy. His deep knowledge of the sector and his proactive approach reinforce his suitability for the position. In short, the candidate shows not only solid experience, but also effective communication and enthusiasm that make him an excellent fit for our company.'
  },


  tag_management: {
    titleSection: 'Tag Management',
    searchLabel: 'Search',
    filterModule: 'Filter by module',
    placeholderFilter: 'Select a module',
    descriptionSection: 'Here you can control and customize tags. Add, modify or delete tags for an efficient workflow.',
    createTag: 'Create tag',
    name: 'Name',
    description: 'Description',
    color: 'Color',
    module: 'Module',
    talent_acquisition: 'Talent acquisition',
    on_board: 'Onboarding',
    employee_line: 'Employee line',
    feedback: 'Feedback',
    exit_interview: 'Exit interview',
    modalTitleCreate: 'Create Tag',
    modalTitleUpdate: 'Edit Tag',
    labelName: 'Name',
    labelDescription: 'Description',
    labelColor: 'Color',
    labelModule: 'Module',
    labelMatchingMin: 'Minimum match',
    labelMatchingMax: 'Maximum match',
    buttonCreateTag: 'Create Tag',
    buttonCancel: 'Cancel',
    buttonUpdateTag: 'Update tag',
    placeholderName: 'Enter the name',
    placeholderDescription: 'Enter the description',
    placeholderColor: 'Enter the color',
    placeholderModule: 'Select a module',
    placeholderMatchingMin: 'Selects the minimum matching score for auto assignment',
    placeholderMatchingMax: 'Selects the maximum match score for the auto assignment',
    nameRequired: 'You must add a name',
    descriptionRequired: 'You must add a description',
    colorRequired: 'You must add a color',
    moduleRequired: 'You must select a module',
    dateUpdated: 'Update date',
    errorCreateTag: 'Error creating tag',
    errorUpdateTag: 'Error updating tag',
    deleteTagError: 'Error deleting tag',
    createdTag: 'Tag created successfully',
    editedTag: 'Tag updated successfully',
    removeTag: 'Delete Tag',
    remove_tag_confirmation: 'Do you confirm that you want to remove the tag {tag_name}?',
    remove_confirmed: 'Yes, delete it',
    deleteTagSuccess: 'Tag deleted successfully',
    tag_in_use:
      'This tag is currently assigned to one or more candidates. You have two options: Delete the tag directly, leaving these candidates untagged, or assign them another available tag. What do you want to do?',
    remove_anyways: 'Delete anyway',
    labelCurrentTag: 'Current tag',
    labelNewTag: 'New tag',
    moveToEmpty: 'You must select which tag to move it to.'
  },

  keywords_rpa: {
    titleSection: 'Recruitment Source Integrations',
    descriptionSection:
      'Here you can control and customize the permissions and access of platform administrators. Add, modify or delete user accounts, assign specific roles and ensure efficient system management.',
    newIntegration: 'New integration',
    searchLabel: 'Search',
    filterSource: 'Filter by source',
    placeholderFilter: 'Select a source',
    sourceInfojobs: 'Infojobs',
    sourceEpreselec: 'ePreselec',
    sourceComputrabajo: 'Computrabajo',
    sourcePandape: 'Pandape',
    sourceTurijobs: 'Turijobs',
    activeIntegrations: 'Active',
    inactiveIntegrations: 'Inactive',
    inactiveIntegration: 'Inactive Integration',
    position: 'Position',
    source: 'Recruitment source',
    user: 'User',
    keyword: 'Keyword',
    lastExecution: 'Last Execution',
    connectionStatus: 'Connection status',
    connectionStatuses: {
      pending: 'Pending Execution',
      ok: 'Connection Established',
      error_login: 'Invalid Credentials',
      error_generic: 'Error'
    },
    labelPosition: 'Position',
    labelSource: 'Recruitment source',
    labelAdvancedConfig: 'Advanced configuration',
    labelConcatUrl: 'Url parameters',
    labelUser: 'User',
    labelPassword: 'Password',
    labelUrl: 'URL',
    placeholderPosition: 'Select the position',
    placeholderSource: 'Select a source',
    placeholderConcatUrl: 'Enter the url parameters',
    placeholderUser: 'Enter the user',
    placeholderPassword: 'Enter password',
    placeholderUrl: 'Customer name',
    placeholderKeyword: 'Generated Keyword',
    buttonGenerateKeyword: 'Generate keyword',
    modalTitleNewIntegration: 'New integration',
    modalTitleEditIntegration: 'Edit integration',
    buttonCancel: 'Cancel',
    buttonSaveIntegration: 'Save integration',
    buttonUpdateIntegration: 'Update integration',
    messageGenerateKeyword:
      'Now to complete the integration we need to assign a keyword that you must include in all offers that you publish in this recruitment source for this position. Please click the button so that this keyword is indicated to you',
    positionRequired: 'You must select a position',
    sourceRequired: 'You must select a source',
    usertRequired: 'You must add a user',
    passwordRequired: 'You must add a password',
    urlRequired: 'You must add a URL',
    generatedKeywordRequired: 'You must generate the keyword in order to continue',
    messageConfirmData: 'This is the integration data you just registered. Is it correct?',
    messageConfirmDataEdit: 'This is the integration data you just modified. Is it correct?',
    buttonConfirmIntegration: 'Yes, save integration',
    buttonCancelIntegration: 'No, cancel',
    recoverPasswordEmail: 'Recover password',
    buttonConfirmDisabledIntegration: 'Yes, disable integration',
    buttonConfirmEnabledIntegration: 'Yes, enable integration',
    confirmDisabledIntegration: 'Can you confirm that you want to disable the integration {position} {provider}?',
    confirmEnabledIntegration: 'Can you confirm that you want to activate the integration {position} {provider}?',
    successCreateIntegration: 'Integration created successfully',
    errorCreateIntegration: 'Error creating integration',
    errorUpdateIntegration: 'Error updating integration',
    successUpdateIntegration: 'Integration updated successfully',
    integrationAlreadyExists: 'The integration already exists!',
    successSendPasswordEmail: 'Password sent successfully',
    errorSendPasswordEmail: 'Error sending password',
    titlePosition: 'Position:',
    titleSource: 'Recruitment source:',
    titleConcatUrl: 'Url parameters:',
    titleUser: 'User:',
    titleKeyword: 'Keyword:',
    integrationDisabledSuccessfully: 'Integration disabled successfully',
    integrationEnabledSuccessfully: 'Integration enabled successfully',
    urlRequirements: "The URL must have this format: https://client.admin.epreselec.com/es/vacantes, replace 'client' with the name of your client",
    invalidUrl: 'Invalid URL',
    emailLabel: 'Email',
    passwordLabel: 'Password',
    verifyPasswordUserPlatformToEditeIntegration: "To edit the integration, please verify the platform user's password.",
    verifyButton: 'Verify',
    errorPasswordIncorrect: 'Incorrect password',
    successPasswordVerified: 'Password verified successfully',
    prefixUrl: 'https://',
    sufixUrl: '.admin.epreselec.com/es/vacantes',
    sourceInfojobsPriority: 'Infojobs',
    sourceEpreselecPriority: 'ePreselec',
    sourceComputrabajoPriority: 'Computrabajo',
    sourcePandapePriority: 'Pandapé',
    sourceTurijobsPriority: 'Turijobs'
  },

  dashboard: {
    filters: {
      division: 'Division',
      area: 'Area',
      subarea: 'Subarea',
      center: 'Center',
      candidature: 'Position'
    },
    no_data_available: 'No data available for the selected range.',
    home: {
      title: 'Statistics dashboards',
      create: 'Create new dashboard',
      editDashboard: 'Edit dashboard',
      saveDashboard: 'Save dashboard',
      removeDashboard: 'Remove dashboard',
      removeDashboardSuccess: 'Dashboard removed successfully',
      removeDashboardError: 'Error removing dashboard',
      changes_not_saved: 'You have changes in the panel. Save them so as not to lose them',
      successfully_saved: 'Changes saved successfully',
      error_saving: 'Error saving changes',
      successfully_deleted: 'Widget deleted successfully',
      error_deleting: 'Error deleting widget',
      deleteWidgetDialogTitle: 'Are you sure you want to remove this widget?',
      deleteCancel: 'Cancel',
      deleteConfirm: 'Confirm',
      no_dashboards_created:
        'There are no statistics dashboards created in your account. Once your administrator creates the dashboards, you will be able to view them from this section.'
    },
    weekdays: {
      '2': 'Monday',
      '3': 'Tuesday',
      '4': 'Wednesday',
      '5': 'Thursday',
      '6': 'Friday',
      '7': 'Saturday',
      '1': 'Sunday'
    },
    pie: {
      accepted: 'Accepted',
      not_accepted: 'Not accepted',
      rejected: 'Rejected',
      pending: 'Pending',
      replan: 'Replanned',
      tentative: 'Tentative',
      total: 'Total',
      suitable: 'Suitable',
      not_suitable: 'Not suitable',

      gpp_manual: 'Manual',
      gpp_auto: 'Automatic',
      gpp_gap: 'GAP',
      gpp_replacement: 'Replacement',
      gpp_pending: 'Pending',
      gpp_cancelled: 'Cancelled',
      gpp_rejected: 'Rejected',
      gpp_active: 'In process',
      gpp_empty: 'Manual preselection pending',
      gpp_inactive: 'Expired',
      gpp_closed: 'Closed',
      gender_male: 'Men',
      gender_female: 'Women',
      gender_undefined: 'Undefined'
    },
    barchart: {
      hour_of_day: ':00h',
      interviews: ' interviews'
    },
    funnel: {
      applied: 'Applied',
      sent: 'Sent',
      loaded: 'Opened',
      interviewed: 'Interviewed'
    },
    whatsapp: {
      bot_starter: 'Interview invitations',
      citations: 'Citations',
      offers: 'Push offers',
      pulls: 'Candidate pull'
    },
    features: {
      push_offer: 'Push offers',
      doc_request: 'Documentation request',
      video_analysis: 'Video analysis',
      pull_config: 'Candidate pull',
      edit_fields: 'Field edit',
      phases_status: 'Stages and status',
      evaluative_tests: 'Evaluative tests',
      vacancy_request: 'Staff request management (GPP)',
      job_offer: 'Jobs'
    },
    tooltip: {
      ats_access_count: 'The user session lasts one day',
      candidate_experience_cnps: 'The CNPS is calculated on the basis of the results of the candidate satisfaction survey.',
      average_time_to_hire:
        'Sets the average time in days (:value:) in which a candidate is recruited, indicating also the minimum and maximum (:max:) value of days',
      gpp_average_time_to_hire:
        'Sets the average time in days (:value:) in which a process is closed, also indicating the minimum and maximum value (:max:) of days'
    },
    edit: {
      title: 'Panel Editing:',
      save: 'Save Panel',
      saving: 'Saving...',
      saved: 'Panel saved successfully',
      renamed: 'Dashboard updated successfully',
      removed: 'Dashboard deleted successfully',
      disable: 'Delete Panel',
      viewDashboard: 'View Dashboard',
      renameDashboard: 'Rename dashboard',
      renameConfirm: 'Save',
      removeDialogTitle: 'Are you sure you want to remove this dashboard?',
      removeConfirm: 'Confirm',
      cancel: 'Cancel',
      errorRemoving: 'Error deleting dashboard',
      errorRenaming: 'Error when renaming dashboard',
      placeholderRename: 'Dashboard name',
      findWidgets: 'Find Widgets',
      addWidgets: 'Select widgets for your panel',
      alertHasUnsavedChanges: 'Dashboard modified: remember to save to avoid losing your changes'
    },
    wizard: {
      title: 'Dashboard Creator',
      new_from_scratch: 'Start from scratch',
      placeholder_custom_dashboard: 'Enter the name of your dashboard',
      create: 'Create',
      viewDashboards: 'Back',
      error_name: 'Enter a name for the new dashboard',
      error_creating: 'An error occurred while creating the dashboard',
      copy_select_preconfigured_dashboard: 'Choose one of the dashboards designed by hr bot factory:',
      copy_select_custom_dashboard: 'Or if you prefer, create a customized one with the widgets you are most interested in:',
      name: {
        virtual_evaluation_asistant: 'Virtual Assistant Evaluation',
        selection_pipeline: 'Selection Pipeline',
        recruitment_source: 'Recruitment platforms',
        candidates_comunication: 'Communication with candidates',
        hired_services: 'Contracted services',
        conversion: 'Conversion',
        citation: 'Citation',
        user_activities: 'User activity'
      },
      description: {
        virtual_evaluation_asistant: 'Detailed overview of various key metrics and statistics related to interaction and effectiveness',
        selection_pipeline: 'Visual representation of all stages of the procurement process',
        recruitment_source:
          'Dashboard providing a detailed overview of the various recruitment sources used, from job portals to social media and internal referrals',
        candidates_comunication: 'Overview of all the interactions with the applicants',
        hired_services: 'Information on contracted services and active functionalities',
        conversion: 'Have a look at the conversion of applicants in the ATS',
        citation: 'View on citations and candidate response and attendance rates',
        user_activities: 'Metrics and activity of users on the ATS'
      }
    },
    widget_groups: {
      status_phases: 'Selection Pipeline',
      virtual_assistant_evaluation: 'Virtual Assistant Evaluation',
      recruitment_sources: 'Recruitment platforms',
      candidates_communication: 'Communication with candidates',
      features_enabled: 'Contracted services',
      conversion: 'Conversion',
      citation: 'Citation',
      user_activity: 'User activity',
      ai_generative_evaluation: 'Generative IA evaluation',
      publish_offers: 'Publication of offers',
      workflow: 'Workflow',
      vacant_management: 'Vacancy management',
      all: 'See all'
    },
    widgets: {
      interviewed_candidates: 'Applications received',
      candidates_by_recruitment_source: 'Candidates evaluated by recruitment source',
      candidates_per_state: 'Number of candidates by state',
      citations_accepted_percentage_per_position: 'Accepted citations per position',
      candidates_per_region: 'Geographical distribution of candidates',
      candidates_by_recruitment_source_absolute: 'Candidates evaluated by recruitment source',
      top_churns_by_chatbot: 'Top Churns by Chatbot',
      suitable_vs_interviewed: 'Suitable vs Interviewed',
      interested_offer_candidates: 'Interested in offers',
      updated_candidates: 'Updated candidates',
      candidates_by_week_activity: 'Activity of interviewed candidates',
      candidatures: 'Candidatures',
      whatsapp_consume_by_service: 'WhatsApp consumption by service',
      whatsapp_consumption: 'WhatsApp usage',
      candidates_per_phase: 'Candidates per stage',
      applied_vs_hired_per_position: 'Candidates enrolled vs. hired by position',
      interviewed_candidates_per_position: 'Candidates interviewed by position',
      push_offers_total: 'Push offers launched',
      push_offers_candidates_notified: 'Offers push candidates notified',
      pull_candidates_notified: 'Pull notified candidates',
      hired_vs_applied_percentage: 'Candidates recruited vs. registered',
      total_sms_and_whatsapp_sent: 'No. of SMS/Whatsapp sent',
      total_email_sent: 'No. of emails sent',
      candidates_with_citation_sent: 'No. of candidates summoned for interview',
      average_time_between_citation_and_interview: 'Average time between sending summons and interview',
      hired_per_recruitment_source: 'Candidates recruited by recruitment source',
      features_enabled_check: 'Functionalities hired',
      suitable_per_recruitment_source: 'Apt to interview by recruitment source',
      communication_methods_enabled: 'Communication channels with candidates',
      candidates_per_region_spain: 'Candidates by city (Spain)',
      registered_vs_suitable_by_recruitment_source: 'Conversion suitable vs. interviewed by recruiting source',
      suitable_vs_no_suitable: 'Suitable vs. non-suitable candidates',
      suitable_vs_registered: 'Suitable vs. registered candidates',
      citations_response_pct: 'Percentage of answers to citations',
      citations_accepted_pct: 'Percentage of citations accepted',
      pull_interested_percentage: 'Pull offers interested per offer',
      push_interested_percentage: 'Push offers interested per offer',
      candidate_experience_cnps: 'Candidate experience satisfaction (CNPS)',
      interviews_by_hour_of_day: 'Interviews per hour of the day',
      interviews_by_hour_of_day_pie: 'Interviews per hour of the day (circular)',
      average_candidate_experience: 'Candidate experience satisfaction (CNPS)',
      average_time_to_hire: 'Time to hire',
      average_phase_time: 'Average time per stage',
      ats_access_count: 'Number of accesses to the ATS',
      ats_unique_access_count: 'Users activity in the ATS compared to the total',
      ats_access_count_ranking: 'Ranking of users with more accesses',
      ats_most_browsed_sections: 'Most visited sections',
      ats_status_changed_count: 'Status changes per user',
      whatsapp_voucher_consumed: 'Whatsapp vouchers consumed',
      funnel_applied_sent_opened_interviewed_by_sms_what: 'Funnel ( Registered, sent, opened, interviewed) by Whatsapp/SMS',
      applied_vs_interviewed_conversion_sms_whatsapp: 'Number of Registered vs. interviewed by Whatsapp/SMS',
      funnel_applied_sent_opened_interviewed_by_email: 'Funnel ( Registered, sent, opened, interviewed) by email',
      applied_vs_interviewed_conversion_email: 'Number of Registered vs. interviewed by email',
      funnel_applied_sent_opened_interviewed_by_unique: 'Funnel ( Registered, sent, opened, interviewed) unique',
      applied_vs_interviewed_conversion_unique: 'Number of Registered vs. unique interviewees',
      conversion_by_candidature: 'Conversion rate per candidature',
      suitable_vs_not_suitable_pie: 'Suitables vs not suitables',
      conversion_by_recruitment_source: 'Conversion rate by recruitment source',
      people_by_client: 'Unique candidates',
      gpp_created_process: "Processes created",
      gpp_processes_summary: "Processes summary",
      gpp_processes_by_type: "Processes covered automatically and manually",
      gpp_processes_by_reason: 'Processes requested by reason',
      gpp_average_candidates_per_process: 'Average suggested candidates per vacancy',
      gpp_uncovered_processes_automatically: "Processes covered manually",
      gpp_requests_by_user: "Requests by requester",
      gpp_requests_by_validator: "Requests by validator",
      gpp_requests_by_area: "Requests by area",
      gpp_candidates_by_gender: "Candidates suggested by gender",
      gpp_time_to_hire_global: "Global time to hire",
      gpp_time_to_hire_by_process: "Time to hire by process",
      gpp_lifetime_processes: 'Process management time',
      gpp_average_management_time_by_user: 'Average management time per manager',
      hired_candidates_by_gender: "Hired candidates by gender",
      female_candidates_per_phase: "Female candidates per phase",
      female_candidates_per_selection_status: "Female candidates by status",
      male_candidates_per_phase: "Male candidates per phase",
      male_candidates_per_selection_status: "Male candidates by status",
      average_positions_per_candidate: "Media of applications per person",
      tableColumns: {
        phase: 'Phase',
        state: 'State',
        offer: 'Offer',
        notified: 'Notified',
        interested: 'Interested',
        position: 'Position',
        hired: 'Hired',
        applied: 'Registered',
        feature: 'Functionality',
        enabled: 'Active',
        candidates: 'Candidates',
        recruitment_source: 'Source',
        activity: 'Activity',
        interviews: 'Interviews',
        hours: 'Time of day',
        method: 'Method',
        accepted: 'Accepted',
        percentage: '%',
        email: 'User',
        total: 'Access',
        chatbot: 'Chatbot',
        chatbot_node: 'Node',
        chatbot_node_text: 'Question',
        sessions: 'Total Sessions',
        churn: 'Churn Count',
        churn_pct: 'Churn Percentage',

        uncovered_position: 'Position',
        uncovered_center: 'Center',
        num_uncovered: 'Unfilled processes',
        num_covered_manually: 'Number of processes',

        gpp_solicitor: 'Requester',
        gpp_solicitor_name: 'Requester',
        gpp_cp_name: 'Work center',
        gpp_cs_position: 'Position',
        gpp_vr_name: 'Process name',
        gpp_vr_closed_time: 'Management days',
        gpp_vr_avg_closed_time: 'Average management (days)',
        gpp_num_requests: 'Created processes',
        gpp_pending_pct: 'Pending approval',
        gpp_cancelled_pct: 'Cancelled',
        gpp_rejected_pct: 'Rejected',
        gpp_active_pct: 'In process',
        gpp_empty_pct: 'Manual preselection pending',
        gpp_inactive_pct: 'Expired',
        gpp_closed_pct: 'Closed',

        gpp_pending: 'Pending approval',
        gpp_cancelled: 'Cancelled',
        gpp_rejected: 'Rejected',
        gpp_active: 'In process',
        gpp_empty: 'Manual preselection pending',
        gpp_inactive: 'Expired',
        gpp_closed: 'Closed',

        gpp_validator: 'Validator',
        gpp_num_approved: 'Approved',
        gpp_num_rejected: 'Rejected',

        gpp_center: 'Center',
        gpp_category: 'Area',

        gpp_division: 'Division',
        gpp_area: 'Area',
        gpp_subarea: 'Subarea',
        gpp_unit: 'Center',
        gpp_position: 'Position',
        time_to_hire: 'Number of days to close process'
      }
    }
  },
  templateRequestManager: {
    cloneProcess: 'Clone process',
    pendingValidation: 'Pending validation',
    reviewed: 'Reviewed',
    requestor: 'Requestor',
    emptyDataSource: 'There are no candidates available for this process yet. The Recruitment Department will manage it as soon as possible',
    emptyData: 'No content for now',
    of: 'of',
    rowsPerPage: 'Rows per page',
    emptyForm: 'There are mandatory fields that are not completed',
    titleSection: 'Template Request Manager',
    descriptionSection: 'Create, centralize, and organize work center requests in an agile, fast and orderly manner to manage selection needs.',
    createProcess: 'Create process',
    filterProcess: 'Filter process',
    unit: 'Unit',
    dateCreated: 'Date created',
    fullname: 'Name and surname',
    favorites: 'Favorites',
    yearExperience: 'Years of experience',
    levelEducation: 'Education level',
    cv: 'CV',
    phone: 'Phone',
    email: 'E-mail',
    address: 'Address',
    profile: 'Profile',
    skills: 'Skills',
    match: 'Desirable matches',
    phase: 'Phase',
    status: 'Status',
    reason: 'Reason',
    lastDisplayDateAt: 'Last viewed',
    lastCandidatureChangeStatusDateAt: 'Last status change',
    lastAppointment: 'Last appointment',
    dateIncorporation: 'Incorporation date',
    dateValidation: 'Validation date',
    position: 'Position',
    shift: 'Shift',
    typeContract: 'Type of contract',
    vacancies: 'Vacancies',
    proposedCandidates: 'Proposed candidates',
    interviewedCandidates: 'Interviewed candidates',
    statusProcess: 'Process status',
    newStatusProcess: 'Current status',
    processOpened: 'Processes',
    buttonOpenedProcess: 'Open',
    buttonClosedProcess: 'Closed',
    newProcess: 'New process',
    showProcess: 'Details of process',
    candidates: 'Candidates',
    dependent: 'Dependent',
    numberVacancies: 'Number of vacancies',
    numberVacanciesRequest: 'Number of vacancies requested',
    descriptionNewProcess:
      'Start a new process. Customize crucial aspects such as hours, requirements and type of contract, among others. Define criteria that will help identify suitable candidates for this selection process.',
    backToTemplateRequestManager: 'Back to template request manager',
    placeholderPosition: 'Select the position',
    placeholderTypeContract: 'Select the type of contract',
    placeholderWorkplace: 'Select the work center',
    placeholderShift: 'Select the shift',
    placeholderRequirementsRequired: 'Indicates the required requirements',
    placeholderRequirementsDesirable: 'Indicates the desirable requirements',
    placeholderObservations: 'Indicate your observations',
    workplace: 'Work center',
    requeriments: 'Requirements',
    desirableRequirements: 'Desirable requirements',
    mandatoryRequirements: 'Mandatory requirements',
    observations: 'Observations',
    saveProcess: 'Save',
    titleSectionValidator: 'Process validation',
    descriptionSectionValidator:
      'Validates the selection processes that have been created by the template request handlers. Reviews the details of each process and approves or rejects the request.',
    statusProcessValidator: 'Process status',
    typeContractIndefinite: 'Indefinite',
    typeContractTemporary: 'Temporary',
    typeContractInterim: 'Interim',
    workingDayFullTime: 'Full',
    workingDayPartTime: 'Part',
    workingDayHourly: 'Hourly',
    shiftMorning: 'Tomorrow',
    shiftAfternoon: 'Afternoon',
    shiftFullTime: 'Full time',
    shiftNight: 'Night',
    shiftWeekends: 'Weekends',
    vacancyStates: {
      cancelled: 'Process canceled',
      rejected: 'Process rejected',
      active: 'Process active',
      closed: 'Process closed',
      pending: 'Pending approval',
      empty: 'Pending manual preselection',
      inactive: 'Process expired'
    },
    vacancyReasons: {
      gap: 'GAP',
      replacement: 'Replacement',
    },
    vacancyContract: {
      1: 'Indefinite contract',
      2: 'Temporary contract'
    },
    workingDayLabel: "Workday",
    placeholderWorkingDay: "Select the workday",
    workingDay: {
      full_time: "Full-time",
      part_time: "Part-time",
      weekend: "Weekends"
    },
    workShiftLabel: "Work shift",
    placeholderWorkShift: "Select the work shift",
    workShift: {
      morning_shift: "Morning",
      swing_shift: "Afternoon",
      night_shift: "Night",
      rotating_shift: "Rotating",
      split_shift: "Split",
      full_shift: "Full"
    },
    contractHoursLabel: "Hours/week",
    placeholderContractHours: "Select hours per week",
    contractHours: {
      40: "40 hours per week",
      37: "37 hours per week",
      35: "35 hours per week",
      30: "30 hours per week",
      20: "20 hours per week",
      16: "16 hours per week",
      15: "15 hours per week",
      12: "12 hours per week",
      10: "10 hours per week",
      8: "8 hours per week"
    },
    searchMode: 'Search mode',
    searchModes: {
      direct: 'Direct assignment',
      algorithm: 'Algorithm',
      repush: 'Repush'
    },
    actions: 'Actions',
    acceptProcess: 'Accept process',
    rejectProcess: 'Reject process',
    cancelProcess: 'Cancel process',
    cancelProcessTooltip: 'If you do not wish to continue with the process, cancelling it will release the candidates and close the process.',
    closeProcess: 'End process',
    closeProcessTooltip: 'If the process has come to an end with the desired hires, ending the process will end the process and it cannot be reopened.',
    repushCandidatesTooltip: 'Fill the process with new candidates found until the process is complete.',
    repushCandidates: 'Fill candidates',
    repushCandidatesSuccess: 'The request to search for new candidates has been executed correctly',
    repushCandidatesFullfilled: 'The template request already has the maximum number of candidates allowed.'
  },
  toast_msg: {
    observation: {
      success: 'Observation added successfully',
      error: 'An error occurred'
    },
    requireDoc: {
      success: 'Documentation request sent',
      error: 'Error requesting documentation'
    },
    changeStatus: {
      success: 'Status changed successfully',
      error: 'An error occurred'
    }
  },

  "IAGen": {
    "criterias": {
      "experience": "Experience",
      "EXPERIENCIA": "Experience",

      "education_training": "Education / Training",
      "ESTUDIOS": "Education / Training",

      "salary_expectation": "Salary Expectations",
      "SALARIO": "Salary Expectations",

      "employment_status": "Employment Status",
      "SITUACION_LABORAL": "Employment Status",

      "location": "Location",
      "UBICACION": "Location",

      "mobility": "Mobility",
      "MOVILIDAD": "Mobility",

      "availability": "Availability",
      "DISPONIBILIDAD": "Availability",

      "others": "Others",

      "not_evaluated": "Not evaluated"
    }
  }
}
